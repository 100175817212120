import React from "react"
import styles from "./Card.module.scss"
import Windows from "../../../icons/Windows"
import Linux from "../../../icons/Linux"
import Mac from "../../../icons/Mac"
import Donwload from "../../../icons/Donwload"

export default function Card(props) {
  const catalogue = props.catalogue

  return (
    <div className={styles.main_card}>
      {/* <div className={styles.main_image}>
        <img
          className={styles.image}
          src={process.env.REACT_APP_BASE_URL + catalogue.imageUrl}
        />
      </div> */}
      <div className={styles.content}>
        <div>
          <h1 className={styles.catalogue_name}>{catalogue.name}</h1>
          <p className={styles.catalogue_description}>
            {catalogue.description}
          </p>
        </div>
        <div className={styles.catalogue_lower}>
          <hr className={styles.separator} />
          <div className={styles.catalogue_os}>
            <div>
              <em className={styles.brand}>{catalogue.brand}</em>

              {catalogue.version !== undefined && (
                <p className={styles.version}>
                  V. {catalogue.version}
                </p>
              )}
            </div>
            <div className={styles.os_icons}>
              <a
                className={styles.os}
                href={catalogue.url}
                target="_bank">
                <Donwload
                  style={{ cursor: "pointer" }}
                  height="20px"
                  width="20px"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
