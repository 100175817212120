import React, { useState } from "react"
import Remove from "../../icons/Remove"
import Add from "../../icons/Add"

import styles from "./ImageZoomer.module.scss"
import SmallerScreen from "./SmallerScreen/SmallerScreen"
import { useMediaPredicate } from "react-media-hook"
import SoldOut from "../../icons/states/SoldOut"

export default function ImageZoomer({
  isSoldOut,
  refClick,
  handleRemove,
  images,
  magnifierHeight = 500,
  magnifierWidth = 500,
  zoomLevel = 2,
  isAdmin,
}) {
  const [[x, y], setXY] = useState([0, 0])
  const [[imgWidth, imgHeight], setSize] = useState([0, 0])
  const [showMagnifier, setShowMagnifier] = useState(false)
  const [selected, setSelected] = useState(images[0])
  const lessThan850 = useMediaPredicate("(max-width: 850px)")

  if (lessThan850) {
    return <SmallerScreen images={images} selected={selected} />
  } else {
    return (
      <div className={styles.imageMain}>
        <div className={styles.side_images_main}>
          {Object.entries(images).map(([key, image]) => {
            return (
              <div key={key} className={styles.side_image_container}>
                {isAdmin && (
                  <div
                    onClick={() => {
                      handleRemove(key)
                    }}
                    className={styles.remove}>
                    <Remove />
                  </div>
                )}

                {selected !== image && (
                  <div
                    onClick={() => setSelected(image)}
                    className={styles.blur}
                  />
                )}
                <img
                  style={{ objectFit: "contain" }}
                  alt={"alt-img"}
                  src={
                    image.startsWith("uploads/images")
                      ? process.env.REACT_APP_BASE_URL + image
                      : image
                  }
                />
              </div>
            )
          })}
          {isAdmin && (
            <Add onClick={refClick} size={20} fill={"grey"} />
          )}
        </div>
        <div className={styles.selected_image}>
          <div className={styles.out_of_stock}>
            {isSoldOut && <SoldOut size={50} />}
          </div>
          <img
            alt="product_image"
            onMouseEnter={(e) => {
              // update image size and turn-on magnifier
              const elem = e.currentTarget
              const { width, height } = elem.getBoundingClientRect()
              setSize([width, height])
              setShowMagnifier(true)
            }}
            onMouseMove={(e) => {
              // update cursor position
              const elem = e.currentTarget
              const { top, left } = elem.getBoundingClientRect()

              // calculate cursor position on the image
              const x = e.pageX - left - window.pageXOffset
              const y = e.pageY - top - window.pageYOffset
              setXY([x, y])
            }}
            onMouseLeave={() => {
              // close magnifier
              setShowMagnifier(false)
            }}
            className={styles.image}
            src={
              selected.startsWith("uploads/images")
                ? process.env.REACT_APP_BASE_URL + selected
                : selected
            }
          />
          <div
            style={{
              display: showMagnifier ? "" : "none",
              position: "absolute",

              // prevent magnifier blocks the mousemove event of img
              pointerEvents: "none",
              // set size of magnifier
              height: `${magnifierHeight}px`,
              width: `90%`,
              // move element center to cursor pos
              // top: `${y - magnifierHeight / 2}px`,
              // left: `${x - magnifierWidth / 2}px`,
              opacity: "1", // reduce opacity so you can verify position

              backgroundColor: "white",
              backgroundImage: `url(${
                selected.startsWith("uploads/images")
                  ? process.env.REACT_APP_BASE_URL + selected
                  : selected
              })`,
              backgroundRepeat: "no-repeat",
              borderRadius: "1rem",

              //calculate zoomed image size
              backgroundSize: `${imgWidth * zoomLevel}px ${
                imgHeight * zoomLevel
              }px`,

              //calculate position of zoomed image.
              backgroundPositionX: `${
                -x * zoomLevel + magnifierWidth / 2
              }px`,
              backgroundPositionY: `${
                -y * zoomLevel + magnifierHeight / 2
              }px`,
            }}></div>
        </div>
      </div>
    )
  }
}
