import React from 'react'

export default function Lebanon({ fill, size, onClick, onChange }) {
  return (
    <svg
      onChange={onChange}
      onClick={onClick}
      cursor={'pointer'}
      width={size || '30'}
      height={size || '30'}
      viewBox="0 0 36 36"
    >
      <g>
        <path
          fill={'#f0f0f0'}
          d="M18 36c9.941 0 18-8.059 18-18S27.941 0 18 0 0 8.059 0 18s8.059 18 18 18z"
        ></path>
        <path
          fill={fill || '#d80027'}
          d="M1.786 10.174C4.698 4.153 10.864 0 18 0s13.303 4.153 16.214 10.174zm32.428 15.652C31.302 31.847 25.136 36 18 36S4.698 31.847 1.786 25.826z"
        ></path>
        <path
          fill={fill || '#6da544'}
          d="M22.696 21.13 18 12.522l-4.696 8.61h3.522v2.348h2.348v-2.35z"
        ></path>
      </g>
    </svg>
  )
}
