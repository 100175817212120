import React from "react"

import CarouselManagement from "../../carousel/CarouselManagement"

import styles from "./Home.module.scss"

export default function Home() {
  return (
    <div className={styles.main}>
      <CarouselManagement />
      <CarouselManagement isMobile />
    </div>
  )
}
