import React, { useState } from "react"
import styles from "./Card.module.scss"
import { useDispatch } from "react-redux"
import {
  hideCategory,
  updateCategories,
} from "../../../../state/categoriesSlice"
import TransInput from "../../../custom/transInput/TransInput"
import { Controller, useForm } from "react-hook-form"
import SubCat from "../SubCat/SubCat"

export default function Card({ value }) {
  const { control, getValues } = useForm({
    defaultValues: {
      category: value.name,
      subCategories: value.subCategories,
    },
  })
  const [isVisible, setVisible] = useState(value.isVisible || false)
  const dispatch = useDispatch()
  const [isEdit, setEdit] = useState(false)

  return (
    <div className={styles.oM_b_c}>
      <div className={styles.card}>
        <Controller
          control={control}
          name="category"
          render={({ field: { onChange, value } }) => {
            return (
              <input
                disabled={!isEdit}
                value={value}
                onChange={onChange}
                className={styles.iB}
                placeholder="Name"
              />
            )
          }}
        />
      </div>
      <p
        style={isEdit ? { cursor: "pointer" } : { cursor: "default" }}
        onClick={() => {
          if (isEdit) {
            dispatch(hideCategory(value._id))
            setVisible(!isVisible)
          }
        }}>
        {isVisible ? "true" : "false"}
      </p>
      <p>2</p>
      <div className={styles.card}>
        <Controller
          control={control}
          name="subCategories"
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <SubCat
                onChange={onChange}
                isEdit={isEdit}
                value={value}
              />
            )
          }}
        />
      </div>
      <span
        onClick={() => {
          if (isEdit) {
            console.log(value)
            const payload = {
              id: value._id,
              name: getValues("category"),
              subCats: getValues("subCategories"),
            }
            dispatch(updateCategories(payload))
          }
          setEdit(!isEdit)
        }}
        className={styles.actionButton}>
        {isEdit ? "save" : "edit"}
      </span>
    </div>
  )
}
