import React, { useEffect, useRef, useState } from "react"
import styles from "./CountryCard.module.scss"

export default function CountryCard({
  isSelected,
  country,
  onChange,
}) {
  const checkboxRef = useRef("")

  const handleButtonClick = () => {
    checkboxRef.current.checked = !checkboxRef.current.checked

    onChange(country.name, checkboxRef.current.checked)
  }

  // const [selected, setSelected] = useState(isSelected || false)

  // useEffect(() => {
  //   console.log("isSelected", isSelected)
  //   setSelected(isSelected)
  // }, [isSelected])

  return (
    <div onClick={handleButtonClick} className={styles.countryCard}>
      <p className={styles.countryName}>{country.name}</p>
      <input
        onChange={() => {
          console.log()
        }}
        onClick={(e) => {
          checkboxRef.current.checked = !checkboxRef.current.checked
          onChange(country.name, checkboxRef.current.checked)
        }}
        value={country.code}
        ref={checkboxRef}
        checked={isSelected}
        type="checkbox"
      />
    </div>
  )
}
