import React, { useEffect, useState } from "react"
import Title from "../../../components/admin/title/Title"
import styles from "./Orders.module.scss"

import Card from "../../../components/admin/orders/Card"
import { useDispatch, useSelector } from "react-redux"
import { getOrders } from "../../../state/orderSlice"
import Editor from "../../../components/admin/orders/editor/Editor"

export default function Orders() {
  const dispatch = useDispatch()
  const { orders } = useSelector((state) => state.orders)
  const [editor, setEditor] = useState(null)
  const [status, setStatus] = useState("All")

  useEffect(() => {
    dispatch(getOrders())
  }, [dispatch])

  function onToggle(order) {
    setEditor(order)
  }
  let filteredOrders = [...orders]

  if (status !== "All") {
    filteredOrders = filteredOrders.filter(
      (order) => order.orderStatus === status
    )
  }

  return (
    <section id={"mainContainer"}>
      <Title>Orders</Title>
      <main className={styles.main}>
        {editor && <Editor toggle={onToggle} order={editor} />}
        <div className={styles.order_manager}>
          <div className={styles.oM_h}>
            <div className={styles.entitle}>
              <h3>Product Orders</h3>
              <p>Avg. 57 orders per day</p>
            </div>
            <div className={styles.filter}>
              <p>Category</p>
              <select>
                <option>Show All</option>
              </select>
            </div>
            <div className={styles.filter}>
              <p>Status</p>
              <select
                defaultValue={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option>All</option>
                <option>Pending</option>
                <option>Confirmed</option>
                <option>Cancelled</option>
                <option>Shipped</option>
                <option>Received</option>
                <option>Returned</option>
                <option>Refunded</option>
              </select>
            </div>
            <div className={styles.filter}>
              <input placeholder="Search" />
            </div>
          </div>
          <div className={styles.oM_b}>
            <div className={styles.oM_b_h}>
              <p>Order ID</p>
              <p>Created</p>
              <p>Customer</p>
              <p>Type</p>
              <p>Country</p>
              <p>Total Price</p>
              <p>Status</p>
              <div style={{ width: "20px" }} />
            </div>
            {Object.entries(filteredOrders).map(([key, value]) => {
              return <Card toggle={onToggle} key={key} order={value} />
            })}
          </div>
        </div>
      </main>
    </section>
  )
}
