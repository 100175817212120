import axios from "axios"

export const getBrandsRequest = async (token) => {
  try {
    const res = await axios.get("brands/getBrands")
    return res.data
  } catch (error) {
    throw error
  }
}

export const createBrandRequest = async (token, payload) => {
  try {
    const res = await axios.post("brands/addBrand", {
      token,
      payload,
    })
    return res.data
  } catch (error) {
    throw error
  }
}

export const deleteBrandRequest = async (token, payload) => {
  try {
    const id = payload
    const res = await axios.delete(`brands/deleteBrand/${id}`, {
      params: {
        ...token,
      },
    })
    return res.data
  } catch (error) {
    throw error
  }
}

export const updateBrandRequest = async (token, payload) => {
  try {
    const res = await axios.patch("brands/updateBrand", {
      payload,
      token,
    })
    return res.data
  } catch (error) {
    throw error
  }
}
