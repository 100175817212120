import React, { useEffect, useState } from "react"
import styles from "./Layout.module.scss"
import Whatsapp from "../../icons/Whatsapp"
import Skype from "../../icons/Skype"
import Call from "../../icons/Call"
import Notification from "../../icons/Notification"
import { useSelector } from "react-redux"
import { send } from "../../helper"

export default function Layout() {
  const { contact } = useSelector((state) => state.seo)
  const { currentCountry, country } = useSelector(
    (state) => state.auth
  )
  // useEffect(() => {
  //   console.log(currentCountry, country)
  // }, [currentCountry, country])

  const { whatsapp, phoneNumber, skype } = contact

  const [showNotifications, setShowNotifications] = useState(false)

  useEffect(() => {
    if (
      !localStorage.getItem("notificationKey") &&
      (country || currentCountry)
    ) {
      setShowNotifications(true)
    }
  }, [currentCountry, country])

  return (
    <div className={styles.container}>
      {phoneNumber === "" || <Call href={phoneNumber} size={40} />}
      {whatsapp === "" || <Whatsapp href={whatsapp} size={40} />}
      {skype === "" || <Skype href={skype} size={40} />}

      {showNotifications && (
        <div
          onClick={() => {
            send(
              "Welcome",
              "Thank you for subscribing to our notifications",
              country || currentCountry
            ).then(() => {
              setShowNotifications(false)
              localStorage.setItem("notificationKey", true)
            })
          }}
          className={styles.notif}>
          <Notification size={30} fill="#fff" />
        </div>
      )}
    </div>
  )
}
