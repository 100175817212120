import React from 'react'
import styles from './Card.module.scss'

export default function Card() {
  return (
    <div className={styles.mail_item}>
      <div className={styles.indicator} />
      <div className={styles.mail_content}>
        <p className={styles.sender}>Jhon Doe</p>
        <p className={styles.subject}>Subject Test</p>
        <p className={styles.description}>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book.
        </p>
      </div>
      <div className={styles.date}>
        <p>28/12/2022</p>
      </div>
    </div>
  )
}
