import React, { useEffect, useState } from "react"
import styles from "./Notifications.module.scss"
import Title from "../../../components/admin/title/Title"
import { Controller, set, useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import {
  clearMessages,
  pushNotification,
} from "../../../state/notificationSlice"
import CountriesSelector from "../../../components/admin/countries/CountriesSelector"

export default function Notifications() {
  const dispatch = useDispatch()

  const [showMessage, setShowMessage] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const { control, handleSubmit } = useForm({
    defaultValues: {
      title: "",
      message: "",
      countries: [],
    },
  })

  function submit(data) {
    dispatch(pushNotification(data))
  }

  const { confirmationMessage, errorMessage } = useSelector(
    (state) => state.notifications
  )

  useEffect(() => {
    setShowMessage(true)
    setTimeout(() => {
      dispatch(clearMessages())
      setShowMessage(false)
    }, 5000)
  }, [confirmationMessage, errorMessage])
  return (
    <div>
      <div className={styles.title}>
        <Title>Notifications</Title>
      </div>

      <div className={styles.notificationComposer}>
        <Controller
          control={control}
          name="title"
          render={({ field: { onChange, value } }) => {
            return (
              <input onChange={onChange} value={value} placeholder="Title" />
            )
          }}
        />
        <Controller
          control={control}
          name="message"
          render={({ field: { onChange, value } }) => {
            return (
              <input onChange={onChange} value={value} placeholder="Message" />
            )
          }}
        />
        <span
          style={{ color: "#0099ff", cursor: "pointer" }}
          onClick={() => setShowModal(true)}
        >
          Select Countries
        </span>

        <Controller
          name="countries"
          control={control}
          render={({ field: { onChange, value } }) => {
            if (showModal) {
              return (
                <CountriesSelector
                  onSave={onChange}
                  value={value}
                  cancel={() => setShowModal(false)}
                />
              )
            }
          }}
        />

        <button onClick={handleSubmit(submit)}>Notify Users</button>
        {showMessage && confirmationMessage && (
          <p className={styles.valid}>{confirmationMessage}</p>
        )}
        {showMessage && errorMessage && (
          <p className={styles.error}>And error occured</p>
        )}
      </div>
    </div>
  )
}
