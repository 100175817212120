import React, { useEffect, useRef, useState } from "react"

import { formatNumber } from "../../../../helpers/Formats"

import TransInput from "../../../custom/transInput/TransInput"

import countries from "../../../../helpers/Countries"

export default function Price({
  country,
  value,
  placeholder,
  onChange,
}) {
  const [number, setNumber] = useState(value)
  const [isEU, setIsEU] = useState(false)

  useEffect(() => {
    setNumber(value)
    formatNumber(value, setNumber)
  }, [value])

  useEffect(() => {
    countries.map((c) => {
      if (c.name === country && c?.eu === true) {
        setIsEU(true)
      }
    })
  }, [country])

  return (
    <>
      <p>{isEU ? "€" : "$"}</p>

      <TransInput
        value={number}
        onChange={(e) => {
          onChange(e, placeholder)
        }}
        placeholder={placeholder}
      />
    </>
  )
}
