import {
  createAsyncThunk,
  createSlice,
  current,
} from "@reduxjs/toolkit"
import {
  uploadImageRequest,
  getImagesRequest,
  disableImageRequest,
} from "../api/images"

const initialState = {
  isLoading: false,
  images: [],
  errorMessage: "",
}

export const uploadImage = createAsyncThunk(
  "images/upload",
  async (payload, thunkApi) => {
    try {
      const token = thunkApi.getState().auth
      return await uploadImageRequest(payload, token)
    } catch (error) {
      console.log("uploadImage" + error)
      throw error
    }
  }
)

export const getImages = createAsyncThunk(
  "images/get",
  async (payload, thunkApi) => {
    try {
      return await getImagesRequest(payload)
    } catch (error) {
      console.log("getImages" + error)
      throw error
    }
  }
)

export const disableImage = createAsyncThunk(
  "images/disable",
  async (payload, thunkApi) => {
    try {
      const token = thunkApi.getState().auth
      return await disableImageRequest(payload, token)
    } catch (error) {
      console.log("disableImage" + error)
      throw error
    }
  }
)

export const imageSlice = createSlice({
  name: "images",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(uploadImage.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(uploadImage.fulfilled, (state, action) => {
      let newArray = []
      const array = current(state.images)

      for (let index = 0; index < array.length; index++) {
        newArray.push(array[index])
      }

      newArray.push(action.payload.image)

      state.isLoading = false
      state.images = newArray
    })
    builder.addCase(uploadImage.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.error.message
    })
    builder.addCase(getImages.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getImages.fulfilled, (state, action) => {
      state.isLoading = false
      state.images = action.payload.images
    })
    builder.addCase(getImages.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.error.message
    })
    builder.addCase(disableImage.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(disableImage.fulfilled, (state, action) => {
      const array = current(state.images)

      const newArray = array.filter(
        (image) => image._id !== action.payload.image._id
      )

      state.isLoading = false
      state.images = newArray
    })
    builder.addCase(disableImage.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.error.message
    })
  },
})

export default imageSlice.reducer
