import React from "react"
import Header from "../../components/Header/Header"
import Footer from "../../components/footer/Footer"
import styles from "./PageNotFound.module.scss"
import Arrow from "../../icons/Arrow"
import Layout from "../../components/layout/Layout"

export default function PageNotFound() {
  return (
    <>
      <Layout />
      <Header />
      <main id={styles.main}>
        <p className={styles.text}>OOPS! PAGE NOT FOUND</p>
        <h1 className={styles.error_code}>404</h1>
        <p className={styles.text}>
          WE ARE SORRY, BUT THE PAGE YOU REQUESTED WAS NOT FOUND.
        </p>
        <a href="/" className={styles.referral}>
          <div className={styles.arrow}>
            <Arrow />
          </div>
          <p>Go Home</p>
        </a>
      </main>
      <Footer />
    </>
  )
}
