import React, { useState } from "react"
import { Controller } from "react-hook-form"
import Form from "react-bootstrap/Form"
import Dropdown from "react-bootstrap/Dropdown"
import styles from "./DropDown.module.scss"

const CustomToggle = React.forwardRef(
  ({ errors, children, onClick }, ref) => (
    <div
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
      className={errors ? styles.error : styles.container}>
      <span>{children}</span>
      <span>&#x25bc;</span>
    </div>
  )
)

export default function DropDown({ errors, onChange }) {
  const [selected, setSelected] = useState("Select your account type")

  return (
    <Dropdown
      onSelect={(key, event) => {
        setSelected(event.target.innerText)
        onChange(event.target.innerText)
      }}>
      <Dropdown.Toggle
        errors={errors}
        as={CustomToggle}
        id="dropdown-custom-components">
        {selected}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item eventKey="1">Corporate</Dropdown.Item>
        <Dropdown.Item eventKey="2">Retailer</Dropdown.Item>
        <Dropdown.Item eventKey="3">Distributor</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}
