import React, { useEffect, useState } from "react"
import styles from "./SubCat.module.scss"
import Add from "../../../../icons/Add"
import Remove from "../../../../icons/Remove"

export default function SubCat(props) {
  const [inputs, setInputs] = useState([""])

  const handleInputChange = (index, value) => {
    const newInputs = [...inputs]
    newInputs[index] = value
    setInputs(newInputs)
    props.onChange(newInputs)
  }

  const handleDeleteInput = (index) => {
    const newInputs = [...inputs]
    newInputs.splice(index, 1)
    setInputs(newInputs)
    props.onChange(newInputs)
  }

  useEffect(() => {
    console.log(props.value)
    if (props.value !== undefined) {
      setInputs(props.value)
    } else {
      setInputs([""])
    }
  }, [props.value])

  const renderInput = (input, index) => {
    return (
      <div
        key={index}
        style={{ display: "flex", alignItems: "center" }}>
        <input
          disabled={!props.isEdit}
          placeholder="SubCat"
          value={input}
          onChange={(e) => handleInputChange(index, e.target.value)}
          type="text"
          className={styles.input}
        />

        {props.isEdit ? (
          <Remove size={9} onClick={() => handleDeleteInput(index)} />
        ) : (
          <div />
        )}
      </div>
    )
  }

  return (
    <ul className={styles.featuresList}>
      {inputs?.map(renderInput)}

      <div className={styles.addContainer}>
        {props.isEdit ? (
          <Add
            onClick={() => {
              const newInputs = [...inputs, ""]
              setInputs(newInputs)
              props.onChange(newInputs)
            }}
            fill="#b2b2b2"
            size={15}
          />
        ) : (
          <div />
        )}
      </div>
    </ul>
  )
}
