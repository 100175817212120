import React from 'react'

export default function Products() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20">
      <g>
        <g>
          <path
            d="M19.8238 10.8756C19.7542 10.7926 19.663 10.7306 19.5603 10.6963L14.9805 9.17012L10 10.8299V18.372L14.7952 19.97C14.9155 20.01 15.0455 20.01 15.1657 19.97L19.5602 18.5055C19.6769 18.4666 19.7784 18.392 19.8503 18.2922C19.9222 18.1924 19.9609 18.0726 19.9609 17.9496V11.2522C19.9609 11.111 19.9103 10.9787 19.8238 10.8756Z"
            fill="#8BB9FF"
          />
          <path
            d="M14.9805 12.4896V20C15.043 20 15.1056 19.99 15.1657 19.97L19.5602 18.5055C19.6769 18.4666 19.7784 18.392 19.8503 18.2922C19.9222 18.1924 19.9609 18.0726 19.9609 17.9496V11.2522C19.9609 11.111 19.9103 10.9787 19.8238 10.8756L14.9805 12.4896Z"
            fill="#005EDF"
          />
          <path
            d="M10 10.8299V18.372L14.7952 19.97C14.8554 19.99 14.9179 20 14.9805 20V12.4896L10 10.8299Z"
            fill="#4793FF"
          />
          <path
            d="M14.8433 1.67379C14.7738 1.5908 14.6825 1.52874 14.5798 1.49454L10.1853 0.0300433C10.065 -0.0100348 9.93502 -0.0100348 9.81477 0.0300433L5.42023 1.49454C5.31749 1.52873 5.22626 1.59079 5.15672 1.67379C5.06809 1.77928 5.01951 1.91265 5.01953 2.05043V9.17012L10 10.8299L14.9805 9.17012V2.05043C14.9805 1.91266 14.932 1.77928 14.8433 1.67379Z"
            fill="#FFDD54"
          />
          <path
            d="M14.9805 2.05044C14.9805 1.90923 14.9298 1.77692 14.8433 1.6738L10 3.28782V10.8299L14.9805 9.17013V2.05044Z"
            fill="#FF8E00"
          />
          <path
            d="M10 3.28781L5.15672 1.67379C5.06809 1.77928 5.01951 1.91265 5.01953 2.05043V9.17012L10 10.8299V3.28781ZM14.8433 1.67379C14.7737 1.5908 14.6825 1.52874 14.5798 1.49453L10.1852 0.0300391C10.1255 0.0101336 10.063 -9.63217e-06 10 6.86345e-09V3.28781L14.8433 1.67379Z"
            fill="#FFB454"
          />
          <path
            d="M5.01953 9.17012L0.439727 10.6964C0.336992 10.7306 0.245775 10.7926 0.17625 10.8756C0.0876161 10.9811 0.0390356 11.1145 0.0390625 11.2522V17.9496C0.0390602 18.0726 0.0777592 18.1924 0.149677 18.2922C0.221594 18.392 0.323083 18.4666 0.439766 18.5055L4.8343 19.97C4.95454 20.01 5.08453 20.01 5.20477 19.97L10 18.3719V10.8299L5.01953 9.17012Z"
            fill="#FF6B6B"
          />
          <path
            d="M0.17625 10.8756C0.0876222 10.9811 0.0390431 11.1145 0.0390625 11.2522V17.9496C0.0390602 18.0726 0.0777592 18.1924 0.149677 18.2922C0.221594 18.392 0.323083 18.4666 0.439766 18.5055L4.8343 19.97C4.89441 19.99 4.95699 20 5.01953 20V12.4896L0.17625 10.8756Z"
            fill="#F90024"
          />
          <path
            d="M5.01953 12.4896V20C5.08211 20 5.14465 19.99 5.20477 19.97L10 18.3719V10.8299L5.01953 12.4896Z"
            fill="#A50017"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_16_77">
          <rect width="20" height="20" fill="white" />
        </clipPath>
        <clipPath id="clip1_16_77">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
