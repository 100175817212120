import React from 'react'

export default function Remove(props) {
  return (
    <svg
      style={{ cursor: 'pointer' }}
      {...props}
      width={props.size || '15'}
      height={props.size || '15'}
      viewBox="0 0 512.001 512.001"
    >
      <g>
        <path
          d="M294.111 256.001 504.109 46.003c10.523-10.524 10.523-27.586 0-38.109-10.524-10.524-27.587-10.524-38.11 0L256 217.892 46.002 7.894C35.478-2.63 18.416-2.63 7.893 7.894s-10.524 27.586 0 38.109l209.998 209.998L7.893 465.999c-10.524 10.524-10.524 27.586 0 38.109 10.524 10.524 27.586 10.523 38.109 0L256 294.11l209.997 209.998c10.524 10.524 27.587 10.523 38.11 0 10.523-10.524 10.523-27.586 0-38.109L294.111 256.001z"
          fill="#000000"
        ></path>
      </g>
    </svg>
  )
}
