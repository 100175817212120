import React, { useEffect } from "react"
import styles from "./Cart.module.scss"
import { useDispatch, useSelector } from "react-redux"
import CustomLoading from "../../custom/loading/CustomLoading"
import Card from "./card/Card"
import Arrow from "../../../icons/Arrow"
import { deleteOrder, updateOrder } from "../../../state/orderSlice"
import { useNavigate } from "react-router-dom"
import { useMediaPredicate } from "react-media-hook"

export default function Cart({ order }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const lessThan650 = useMediaPredicate("(max-width: 650px)")

  let cartPayload = {
    orderId: !order ? "" : order.orderId,
    orderDate: !order ? "" : order.orderDate,
    orderStatus: !order ? "" : order.orderStatus,
    totalPrice: !order ? "" : order.totalPrice,
    products: !order ? "" : order.products,
    user: !order ? "" : order.user,
    paymentMethod: !order ? "" : order.paymentMethod,
    paymentStatus: !order ? "" : order.paymentStatus,
    checkedOut: !order ? "" : order.checkedOut,
  }

  function onChange(quantity, productId) {
    const index = order.products.findIndex(
      (product) => product.product === productId
    )
    let tempD = [...order.products]
    let tempQuantity = tempD[index].quantity

    tempQuantity = quantity

    const updatedProduct = {
      ...tempD[index],
      quantity: tempQuantity,
    }
    let tempP = [...cartPayload.products]
    tempP[index] = updatedProduct

    cartPayload.products = tempP

    cartPayload.totalPrice = cartPayload.products.reduce(
      (acc, product) => acc + product.price * product.quantity,
      0
    )
  }

  function onDelete(productId) {
    let productIndex = cartPayload.products.findIndex(
      (product) => product.product === productId
    )

    cartPayload.totalPrice =
      cartPayload.totalPrice -
      cartPayload.products[productIndex].price *
        cartPayload.products[productIndex].quantity

    let tempList = [...cartPayload.products]
    tempList.splice(productIndex, 1)

    cartPayload.products = tempList
  }

  function updateCart(cartData) {
    if (cartData.products.length === 0) {
      localStorage.removeItem("cart_key")
      const storageEvent = new Event("cart_key")
      window.dispatchEvent(storageEvent)
      dispatch(deleteOrder(cartData.orderId))
    } else {
      dispatch(updateOrder(cartData)).then(() => {
        const modify_cart = new Event("modify_cart")
        window.dispatchEvent(modify_cart)
      })
    }
  }

  if (Object.getOwnPropertyNames(order).length === 0) {
    return <CustomLoading />
  } else {
    return (
      <div className={styles.cart_content}>
        <div className={styles.cart_header}>
          <h6 className={styles.content_header}>PRODUCT</h6>
          {lessThan650 && (
            <h6 className={styles.content_details}>QUANTITY</h6>
          )}
          <h6 className={styles.content_details}>PRICE</h6>
          {lessThan650 || (
            <>
              <h6 className={styles.content_details}>QUANTITY</h6>
              <h6 className={styles.content_details}>SUBTOTAL</h6>
            </>
          )}
        </div>
        <div className={styles.item_list}>
          {Object.entries(order.products).map(([key, product]) => {
            return (
              <Card
                onChange={onChange}
                onDelete={onDelete}
                key={key + product.product}
                product={product}
              />
            )
          })}
        </div>
        <div className={styles.btns}>
          <div
            onClick={() => navigate("/")}
            className={styles.btn + " " + styles.outline}>
            <div className={styles.back_arrow}>
              <Arrow size={15} />
            </div>
            <p>Continue Shopping</p>
          </div>
          <div
            onClick={() => updateCart(cartPayload)}
            className={styles.btn + " " + styles.inline}>
            <p>Update Cart</p>
          </div>
        </div>
      </div>
    )
  }
}
