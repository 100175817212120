import React from 'react'

export default function Add(props) {
  return (
    <svg
      style={{ cursor: 'pointer' }}
      {...props}
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
    >
      <g>
        <path
          d="M18 2c2.206 0 4 1.794 4 4v12c0 2.206-1.794 4-4 4H6c-2.206 0-4-1.794-4-4V6c0-2.206 1.794-4 4-4zm0-2H6a6 6 0 0 0-6 6v12a6 6 0 0 0 6 6h12a6 6 0 0 0 6-6V6a6 6 0 0 0-6-6z"
          fill={props.fill}
        ></path>
        <path
          d="M12 18a1 1 0 0 1-1-1V7a1 1 0 0 1 2 0v10a1 1 0 0 1-1 1z"
          fill={props.fill}
        ></path>
        <path
          d="M6 12a1 1 0 0 1 1-1h10a1 1 0 0 1 0 2H7a1 1 0 0 1-1-1z"
          fill={props.fill}
        ></path>
      </g>
    </svg>
  )
}
