import React, { useEffect, useState } from "react"
import styles from "./Dashboard.module.scss"
import Title from "../../../components/admin/title/Title"
import { UserData } from "./Data"
import Mails from "../../../components/admin/dashboard/mails/Mails"
import { useSelector, useDispatch } from "react-redux"
import { fetchProducts } from "../../../state/productSlice"
import ProductsList from "../../../components/admin/dashboard/products/ProductsList"
import StatCards from "../../../components/admin/dashboard/analytics/Card"

export default function Dashboard() {
  const [userData, setUserData] = useState({
    labels: UserData.map((data) => data.year),
    datasets: [
      {
        fill: true,
        label: "Users Gained",
        data: UserData.map((data) => data.userGain),
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "black",
        borderWidth: 2,
      },
    ],
  })
  const dispatch = useDispatch()
  const { products } = useSelector((state) => state.product)

  useEffect(() => {
    const payload = {
      subCat: undefined,
      cat: "all",
    }
    dispatch(fetchProducts(payload))
  }, [dispatch])

  return (
    <section id={styles.mainContainer}>
      <div className={styles.title}>
        <Title onClick={() => {}}>Dashboard</Title>
      </div>
      <div className={styles.stats_container}>
        <div className={styles.stats}>
          <div className={styles.level}>
            {/* <StatCards data={userData} header={'Stats'} /> */}
            {/* <StatCards data={userData} header={'Stats'} /> */}
          </div>
          <div className={styles.level}>
            {/* <StatCards data={userData} header={'Stats'} /> */}
            {/* <StatCards data={userData} header={'Stats'} /> */}
          </div>
        </div>
        <div className={styles.stocks}>
          <div className={styles.stocks_container}>
            <p className={styles.header}>Sales</p>
            <ProductsList products={products} />
          </div>
        </div>
      </div>
      <div className={styles.mail}>
        <div className={styles.mail_container}>
          <p className={styles.header}>Mails</p>
          <Mails />
        </div>
      </div>
    </section>
  )
}
