import React, { useState, useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import Button from "react-bootstrap/Button"

import styles from "./SignUpForm.module.scss"

import Input from "../custom/input/Input"
import DropDown from "../dropdown/DropDown"
import { register } from "../../state/authSlice"

function SignUpForm() {
  const [country, setCountry] = useState("")
  const [type, setType] = useState("")
  const { errorMessage } = useSelector((state) => state.auth)
  const { currentCountry } = useSelector((state) => state.auth)

  useEffect(() => {
    setCountry(currentCountry)
  }, [currentCountry])

  const dispatch = useDispatch()
  const {
    handleSubmit,
    control,

    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      verifyPassword: "",
      type: "",
      phoneNumber: "",
      comapnyName: "",
    },
  })

  const onSubmit = async (data) => {
    const payload = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      password: data.password,
      type: data.type,
      country: country,
      phoneNumber: data.phoneNumber,
      companyName: data.companyName,
    }
    dispatch(register(payload))
  }

  console.log(errors, "errors")

  return (
    <form id={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.names}>
        <Controller
          rules={{ required: "first name " }}
          control={control}
          name="firstName"
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <Input
                errors={errors?.firstName?.message}
                type={"text"}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                placeholder="First Name"
              />
            )
          }}
        />
        <Controller
          rules={{ required: "last name required" }}
          control={control}
          name="lastName"
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <Input
                errors={errors?.lastName?.message}
                type={"text"}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                placeholder="Last Name"
              />
            )
          }}
        />
      </div>

      <Controller
        rules={{
          required: true,
          pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        }}
        control={control}
        name="email"
        render={({ field: { onChange, onBlur, value } }) => {
          return (
            <Input
              errors={errors?.email?.message}
              type={"email"}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              placeholder="Enter your email"
            />
          )
        }}
      />
      <Controller
        rules={{ required: "Password required" }}
        control={control}
        name="password"
        render={({ field: { onChange, onBlur, value } }) => {
          return (
            <Input
              errors={errors?.password?.message}
              type={"password"}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              placeholder="Enter your password"
            />
          )
        }}
      />
      <Controller
        rules={{ required: "passwords doesn't match" }}
        control={control}
        name="verifyPassword"
        render={({ field: { onChange, onBlur, value } }) => {
          return (
            <Input
              errors={errors?.verifyPassword?.message}
              type={"password"}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              placeholder="Confirm your password"
            />
          )
        }}
      />
      <Controller
        rules={{ required: "Select a type" }}
        name="type"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => {
          return (
            <DropDown
              errors={errors?.type?.message}
              onChange={(e) => {
                setType(e)
                onChange(e)
              }}
              control={control}
            />
          )
        }}
      />
      <Controller
        rules={{ required: "Phone number required" }}
        control={control}
        name="phoneNumber"
        render={({ field: { onChange, onBlur, value } }) => {
          return (
            <Input
              errors={errors?.phoneNumber?.message}
              type={"text"}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              placeholder="Phone Number"
            />
          )
        }}
      />
      {(type === "Corporate" || type === "Distributor") && (
        <Controller
          rules={{ required: "Company name required" }}
          control={control}
          name="companyName"
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <Input
                errors={errors?.phoneNumber?.message}
                type={"text"}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                placeholder="Company Name"
              />
            )
          }}
        />
      )}
      {errorMessage && <p className={styles.error}>{errorMessage}</p>}
      <Button type="submit" className={styles.btn}>
        Sign Up
      </Button>
    </form>
  )
}

export default SignUpForm
