import React, { useEffect } from "react"
import styles from "./Editor.module.scss"

import { useDispatch, useSelector } from "react-redux"

import {
  notifyUser,
  resetConfirmation,
  updateOrderStatus,
} from "../../../../state/orderSlice"
import { formatDate } from "../../../../helpers/Orders"

export default function Editor({ order, toggle }) {
  const [status, setStatus] = React.useState(order.orderStatus)
  const [extendClass, setExtendClass] = React.useState("")
  const [isVisible, setIsVisible] = React.useState(false)
  const [shipmentFees, setShipmentFees] = React.useState(
    order.deliveryCharges || 0
  )
  const dispatch = useDispatch()
  const { confirmationMessage } = useSelector((state) => state.orders)

  useEffect(() => {
    setIsVisible(confirmationMessage !== "")
    const timer = setTimeout(() => {
      setIsVisible(false)
      dispatch(resetConfirmation())
    }, 3000)

    return () => clearTimeout(timer)
  }, [confirmationMessage])

  useEffect(() => {
    if (status === "Pending") {
      setExtendClass(styles.pending)
    } else if (status === "Confirmed") {
      setExtendClass(styles.confirmed)
    } else if (status === "Cancelled") {
      setExtendClass(styles.cancelled)
    } else if (status === "Shipped") {
      setExtendClass(styles.shipped)
    } else if (status === "Received") {
      setExtendClass(styles.received)
    } else if (status === "Returned") {
      setExtendClass(styles.returned)
    } else if (status === "Refunded") {
      setExtendClass(styles.refunded)
    }
  }, [status])

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        dispatch(resetConfirmation())
        toggle(null)
      }
    }

    // Attach the event listener
    window.addEventListener("keydown", handleKeyDown)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [])
  const address = order.address
  return (
    <div className={styles.m_e_c}>
      <div className={styles.e_c}>
        <div>
          <div className={styles.header}>
            <h3 className={styles.order_id}>#{order.orderId}</h3>
            <div className={styles.user_info}>
              <p className={styles.userId}>{order.user}</p>
              <p className={styles.user_position}>
                {order.userPosition}
              </p>
            </div>
          </div>
          <div className={styles.order_list}>
            {Object.entries(order.products).map(([key, value]) => {
              return (
                <a key={key} href={value.pageUrl}>
                  <div className={styles.order_card}>
                    <div
                      style={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center",
                      }}>
                      <div className={styles.image_container}>
                        <img
                          className={styles.image}
                          src={
                            process.env.REACT_APP_BASE_URL +
                            value.image
                          }
                        />
                      </div>
                      <div className={styles.details}>
                        <h6 className={styles.product_title}>
                          {value.title}
                        </h6>
                        <p className={styles.price}>
                          $ {value.price}
                        </p>
                      </div>
                    </div>
                    <div style={{ display: "flex", gap: "1rem" }}>
                      <p className={styles.quantity}>
                        x {value.quantity}
                      </p>
                      <p className={styles.price}>
                        $ {value.quantity * value.price}
                      </p>
                    </div>
                  </div>
                </a>
              )
            })}
            <div className={styles.b_a_c}>
              <p className={styles.h}>Billing Address</p>
              <div className={styles.container}>
                <div className={styles.b_a}>
                  <p className={styles.b_a_t}>Full Name:</p>
                  <p className={styles.data}>
                    {address.firstName + " " + address.lastName}
                  </p>
                </div>
                <div className={styles.b_a}>
                  <p className={styles.b_a_t}>Phone Number:</p>
                  <p className={styles.data}>{address.phoneNumber}</p>
                </div>
                <div className={styles.b_a}>
                  <p className={styles.b_a_t}>Email:</p>
                  <p className={styles.data}>
                    {address.emailAddress}
                  </p>
                </div>
                <div className={styles.b_a}>
                  <p className={styles.b_a_t}>Country:</p>
                  <p className={styles.data}>{address.country}</p>
                </div>
                <div className={styles.b_a_email}>
                  <p className={styles.b_a_t}>Address:</p>
                  <p className={styles.data}>{address.address}</p>
                </div>
              </div>
            </div>
            <div style={{ marginTop: 10 }}>
              <p className={styles.h}>Order Description</p>
              <p>{order.description}</p>
            </div>
            <div>
              <p className={styles.h}>Shipment Fees</p>
              <div style={{ display: "flex", gap: 5 }}>
                <p>$</p>
                <input
                  value={shipmentFees}
                  onChange={(e) => setShipmentFees(+e.target.value)}
                />
              </div>
            </div>
            <div className={styles.status_container}>
              <p className={styles.h}>Order Status</p>
              <div className={styles.status_grid}>
                <select
                  defaultValue={status}
                  className={styles.select + " " + extendClass}
                  onChange={(e) => setStatus(e.target.value)}>
                  <option className={styles.option} value="Pending">
                    Pending
                  </option>
                  <option className={styles.option} value="Confirmed">
                    Confirmed
                  </option>
                  <option className={styles.option} value="Cancelled">
                    Cancelled
                  </option>
                  <option className={styles.option} value="Shipped">
                    Shipped
                  </option>
                  <option className={styles.option} value="Received">
                    Received
                  </option>
                  <option className={styles.option} value="Returned">
                    Returned
                  </option>
                  <option className={styles.option} value="Refunded">
                    Refunded
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        {isVisible && (
          <div className={styles.conf_message}>
            {confirmationMessage}
          </div>
        )}
        <div className={styles.btn_c}>
          <div onClick={() => toggle(null)} className={styles.cancel}>
            Cancel
          </div>
          <div
            onClick={() => {
              const payload = {
                email: order.address.emailAddress,
                status: status,
                orderId: order.orderId,
                fullName:
                  order.address.firstName +
                  " " +
                  order.address.lastName,
                price: order.totalPrice,
                date: formatDate(new Date(order.orderDate)),
                paymentMethod: order.paymentMethod,
              }
              dispatch(notifyUser(payload))
            }}
            className={styles.notify}>
            Notify User
          </div>
          <div
            onClick={() => {
              const payload = {
                orderId: order.orderId,
                orderStatus: status,
                shipmentFees: shipmentFees,
              }

              dispatch(updateOrderStatus(payload)).then((e) =>
                toggle(null)
              )
            }}
            className={styles.btn}>
            Save
          </div>
        </div>
      </div>
    </div>
  )
}
