import axios from "axios"

export const getUsers = async (token) => {
  try {
    const res = await axios.get("db-admin/getUsers", {
      params: {
        ...token,
      },
    })
    return res.data
  } catch (error) {
    throw error
  }
}

export const toggleVerifyUser = async (payload, token) => {
  const userId = payload._id

  try {
    const res = await axios.patch(
      `db-admin/toggle-verify/${userId}`,
      token
    )
    return res
  } catch (error) {
    throw error
  }
}
