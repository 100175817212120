import React from "react"
import styles from "./Category.module.scss"

export default function Category(props) {
  const selected = props.value === props.title
  return (
    <div
      {...props}
      onClick={props.onClick}
      className={selected ? styles.selected : styles.mainContainer}>
      <p>{props.title}</p>
    </div>
  )
}
