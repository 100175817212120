import {
  createSlice,
  createAsyncThunk,
  current,
} from "@reduxjs/toolkit"
import {
  createCategoryRequest,
  getCategoriesRequest,
  getCategoriesUrlForSeoRequest,
  getMetaInformationRequest,
  toggleVisbilityRequest,
  updateCategoriesRequest,
} from "../api/categories"

///Catgeories
const initialState = {
  categories: [],
  isLoading: true,
  errorMessage: "",
  categoriesUrls: [],
  metaData: null,
}

export const addCategory = createAsyncThunk(
  "db-admin/addCategory",
  async (payload, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.token
      const res = await createCategoryRequest(token, payload)
      return res
    } catch (error) {
      console.log("addCategory" + error)
      throw error
    }
  }
)

export const getCategories = createAsyncThunk(
  "db-admin/getCategories",
  async (payload, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.token
      const res = await getCategoriesRequest(token)

      return res
    } catch (error) {
      console.log("getCategories" + error)
      throw error
    }
  }
)

export const updateCategories = createAsyncThunk(
  "db-admin/updateCategories",
  async (payload, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.token
      const res = await updateCategoriesRequest(token, payload)
      return res
    } catch (error) {
      console.log("updateCategories" + error)
      throw error
    }
  }
)

export const hideCategory = createAsyncThunk(
  "db-admin/hideCategory",
  async (payload, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.token
      const res = await toggleVisbilityRequest(token, payload)
      return res
    } catch (error) {
      console.log("hideCategory" + error)
      throw error
    }
  }
)

export const getCategoriesUrlForSeo = createAsyncThunk(
  "db-admin/getCategoriesUrlForSeo",
  async (payload, thunkApi) => {
    try {
      const res = await getCategoriesUrlForSeoRequest()
      return res
    } catch (error) {
      console.log("getCategoriesUrlForSeo" + error)
      throw error
    }
  }
)

export const getMetaInformation = createAsyncThunk(
  "db-admin/getMetaInformation",
  async (payload, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.token
      const res = await getMetaInformationRequest(payload)

      return res
    } catch (error) {
      console.log("getMetaInformation" + error)
      throw error
    }
  }
)

export const categoriesSlice = createSlice({
  name: "categories",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addCategory.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(addCategory.fulfilled, (state, action) => {
      state.isLoading = false

      state.categories = [
        ...current(state.categories),
        action.payload.category,
      ]
    })
    builder.addCase(addCategory.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.error
    })
    builder.addCase(getCategories.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getCategories.fulfilled, (state, action) => {
      state.isLoading = false
      state.categories = action.payload.categories
    })
    builder.addCase(getCategories.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.error
    })
    builder.addCase(hideCategory.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(hideCategory.fulfilled, (state, action) => {
      const array = current(state.categories)
      const newArray = array.map((item) => {
        if (item._id === action.payload._id) {
          return action.payload
        }
        return item
      })
      state.isLoading = false
      state.categories = newArray
    })
    builder.addCase(hideCategory.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.error
    })

    builder.addCase(updateCategories.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(updateCategories.fulfilled, (state, action) => {
      const array = current(state.categories)
      const newArray = array.map((item) => {
        if (item._id === action.payload._id) {
          return action.payload
        }
        return item
      })
      state.isLoading = false
      state.categories = newArray
    })
    builder.addCase(updateCategories.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.error
    })
    builder.addCase(
      getCategoriesUrlForSeo.pending,
      (state, action) => {
        state.isLoading = true
      }
    )
    builder.addCase(
      getCategoriesUrlForSeo.fulfilled,
      (state, action) => {
        state.isLoading = false
        let temp = action.payload[0]
        temp = temp.concat(action.payload[1])
        state.categoriesUrls = temp
      }
    )
    builder.addCase(
      getCategoriesUrlForSeo.rejected,
      (state, action) => {
        state.isLoading = false
        state.errorMessage = action.error
      }
    )
    builder.addCase(getMetaInformation.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getMetaInformation.fulfilled, (state, action) => {
      state.isLoading = false
      state.metaData = action.payload
    })
    builder.addCase(getMetaInformation.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.error
    })
  },
})

export default categoriesSlice.reducer
