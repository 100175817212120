import React, { useEffect, useState } from "react"
import Header from "../../../../components/admin/header/Header"
import NotFound from "../../../../icons/NotFound"
import Product from "../../../../components/admin/product/Product"
import { useSelector } from "react-redux"
import styles from "./ProductsList.module.scss"

export default function ProductsList({
  setUpdate,
  setVisible,
  page,
  pageCount,
  handlePrevious,
  handleNext,
}) {
  const { products } = useSelector((state) => state.product)
  const [listedProducts, setListedProducts] = useState(
    products ? [...products] : []
  )

  useEffect(() => {
    setListedProducts(products)
  }, [products])

  return products?.length === 0 ? (
    <div className={styles.notFound}>
      <NotFound />
      <h5>No Products Found</h5>
    </div>
  ) : (
    <>
      <div>
        <Header
          categories={["Image", "Name", "Category", "Price", "actions"]}
        />

        <div className={styles.cardList}>
          {products &&
            Object.entries(listedProducts).map(([key, product]) => {
              return (
                <Product
                  setVisible={() => {
                    setUpdate(true)
                    setVisible(true)
                  }}
                  key={key}
                  product={product}
                />
              )
            })}
        </div>
        <footer id={styles.footer}>
          <div className={styles.mainC}>
            <p className={styles.f_pa_di}>
              {page}/{pageCount}
            </p>

            <div className={styles.btns}>
              <button
                className={styles.prev}
                disabled={page === 1}
                onClick={handlePrevious}
              >
                Prev
              </button>
              <button
                className={styles.next}
                disabled={page === pageCount}
                onClick={handleNext}
              >
                Next
              </button>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}
