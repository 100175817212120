import axios from "axios"

export const uploadImageRequest = async (payload, token) => {
  try {
    let formData = new FormData()

    formData.append("image", payload.image)
    formData.append("usage", payload.usage)
    formData.append("token", JSON.stringify(token))
    console.log(payload.isMobile)
    formData.append("isMobile", payload.isMobile)

    const res = await axios.post("images/addImage", formData)

    return res.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const getImagesRequest = async (payload) => {
  try {
    const res = await axios.get("images/getImages", {
      params: {
        usage: payload.usage,
      },
    })

    return res.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const disableImageRequest = async (payload, token) => {
  try {
    const imageId = payload.imageId
    const res = await axios.patch(
      `images/disableImage/${imageId}`,
      token
    )

    return res.data
  } catch (error) {
    console.log(error)
    throw error
  }
}
