import { configureStore } from "@reduxjs/toolkit"
import adminReducer from "./adminSlice"
import authReducer from "./authSlice"
import productReducer from "./productSlice"
import imageReducer from "./imagesSlice"
import seoSlice from "./seoSlice"
import categoriesSlice from "./categoriesSlice"
import brands from "./brandSlice"
import drivers from "./driversSlice"
import countries from "./countriesSlice"
import orders from "./orderSlice"
import catalogues from "./catalogueSlice"
import offers from "./offerSlice"
import notifications from "./notificationSlice"

export const store = configureStore({
  reducer: {
    brands: brands,
    auth: authReducer,
    admin: adminReducer,
    product: productReducer,
    images: imageReducer,
    seo: seoSlice,
    categories: categoriesSlice,
    drivers: drivers,
    countries: countries,
    orders: orders,
    catalogues: catalogues,
    offers: offers,
    notifications: notifications,
  },
})
