import React from "react"

export default function PinFilled({ onClick }) {
  return (
    <svg
      onClick={onClick}
      width="20"
      height="20"
      viewBox="0 0 464.968 464.968">
      <g>
        <path
          fill="#3fa9f5"
          d="M88.969 225.938 239.03 376c9.371 9.371 24.567 9.371 33.938 0A23.998 23.998 0 0 0 280 359.023V280.97l144-144 12 12c6.629 6.625 17.371 6.625 24 0 6.625-6.63 6.625-17.371 0-24l-120-120c-6.629-6.625-17.371-6.625-24 0-6.625 6.629-6.625 17.37 0 24l12 12-144 144h-78.055A23.998 23.998 0 0 0 88.97 192c-9.371 9.371-9.371 24.563 0 33.938zm0 0"
          opacity="1"
          data-original="#3fa9f5"
          class=""></path>
        <path
          fill="#cccccc"
          d="M176 312.969 55.32 433.649A119.073 119.073 0 0 1 0 464.968a119.073 119.073 0 0 1 31.32-55.32L152 288.968zm0 0"
          opacity="1"
          data-original="#cccccc"></path>
      </g>
    </svg>
  )
}
