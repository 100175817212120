import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { toggleVerify } from "../../../state/adminSlice"
import Lebanon from "../../../icons/countries/Lebanon"
import Verified from "../../../icons/verify/Verified"
import Verify from "../../../icons/verify/Verify"
import styles from "./UserCard.module.scss"
import Cyprus from "../../../icons/countries/Cyprus"
import { set } from "react-hook-form"
import { updateUserData } from "../../../state/authSlice"

export default function UserCard(props) {
  const dispatch = useDispatch()
  const [type, setType] = useState(props.type)

  const payload = {
    _id: props.id,
  }
  return (
    <div className={styles.mainCard}>
      <div className={styles.country}>
        {props.country === "Lebanon" ? <Lebanon /> : <Cyprus />}
      </div>
      <div className={styles.name}>{props.email}</div>
      <div className={styles.name}>{props.name}</div>
      <div className={styles.name}>{props.phone}</div>
      <div className={styles.name}>{props.company}</div>
      <div className={styles.type}>
        <select
          value={type}
          onChange={(e) => {
            dispatch(
              updateUserData({ type: e.target.value, id: props.id })
            )
          }}>
          <option value="Retailer">Retailer</option>
          <option value="Corporate">Corporate</option>
          <option value="Distributor">Distributor</option>
        </select>
      </div>
      <div className={styles.actions}>
        {props.verified ? (
          <Verified onClick={() => dispatch(toggleVerify(payload))} />
        ) : (
          <Verify onClick={() => dispatch(toggleVerify(payload))} />
        )}
      </div>
    </div>
  )
}
