import axios from "axios"

// get all drivers
export const getDrivers = async () => {
  try {
    const res = await axios.get(`drivers/getDrivers`)
    return res.data
  } catch (error) {
    throw error
  }
}

// add driver
export const createDriverRequest = async (token, payload, update) => {
  try {
    // const data = payload
    // let formData = new FormData()

    // formData.append("name", data.name)
    // formData.append("windows", data.windows)
    // formData.append("mac", data.mac)
    // formData.append("linux", data.linux)
    // formData.append("imgCollection", data.image)
    // formData.append("description", data.description)
    // formData.append("brand", data.brand)
    // formData.append("version", data.version)
    // formData.append("token", JSON.stringify(token))

    var res
    if (update) {
      // formData.append("_id", data.id)
      res = await axios.patch(`drivers/updateDriver`, {token,payload})
    } else {
      // console.log(formData)
      res = await axios.post(`drivers/addDriver`, {token,payload})
    }
    return res.data
  } catch (error) {
    throw error
  }
}

//delete driver
export const deleteDriverRequest = async (id, token) => {
  try {
    const res = await axios.delete(`drivers/deleteDriver/`, {
      data: { id, token },
    })
    return res.data
  } catch (error) {
    throw error
  }
}

// handle visibile in drivers
export const handleVisibleRequest = async (id, token) => {
  try {
    const res = await axios.patch(`drivers/toggleVisible/`, {
      id,
      token,
    })
    return res.data
  } catch (error) {
    throw error
  }
}
