import React, { useEffect, useState } from "react"
import Footer from "../components/footer/Footer"
import Header from "../components/Header/Header"
import LandingPage from "../components/home/Home"
import ProductsList from "../components/ProductsList/ProductsList"

import { useDispatch, useSelector } from "react-redux"
import { getMeta } from "../state/seoSlice"

import { HelmetProvider, Helmet } from "react-helmet-async"
import Layout from "../components/layout/Layout"
import Services from "../components/home/Services/Services"
import styles from "../offers.module.scss"
import Close from "../icons/Close"
import {
  getOfferByDate,
  resetActiveOffers,
} from "../state/offerSlice"
import Arrow from "../icons/Arrow"

import { getCategoriesUrlForSeo } from "../state/categoriesSlice"
import { getProductsUrlForSeo } from "../state/productSlice"
import { Link, useParams } from "react-router-dom"

export default function Home() {
  const [keyword, setKeyword] = useState("")
  const { meta, errorMessage } = useSelector((state) => state.seo)
  const { currentCountry, country } = useSelector(
    (state) => state.auth
  )
  const [error, setEerror] = useState(errorMessage)
  const dispatch = useDispatch()
  var title = ""
  var description = ""
  var keywords = ""

  useEffect(() => {
    dispatch(getMeta()).then(() => setEerror(errorMessage))
  }, [dispatch])

  useEffect(() => {
    if (country || currentCountry) {
      dispatch(getOfferByDate(country || currentCountry))
    }
  }, [dispatch, country, currentCountry])

  const { activeOffers } = useSelector((state) => state.offers)
  const { categoriesUrls } = useSelector((state) => state.categories)
  const { productsUrls } = useSelector((state) => state.product)
  const [index, setIndex] = useState(0)

  for (let i = 0; i < meta.length; i++) {
    if (meta[i].url === "home") {
      title = meta[i].metaTitle
      description = meta[i].metaDescription
      keywords = meta[i].metaKeywords
    }
  }

  useEffect(() => {
    dispatch(getProductsUrlForSeo())
    dispatch(getCategoriesUrlForSeo())
  }, [dispatch])

  const params = useParams()

  if (error === "Network Error") {
    return <div>Error</div>
  } else {
    return (
      <>
        {activeOffers.length === 0 || (
          <div className={styles.layer}>
            <div className={styles.offerContainer}>
              <img
                className={styles.offerImage}
                src={
                  process.env.REACT_APP_BASE_URL +
                  activeOffers[index].image
                }
              />
              <div
                onClick={() => {
                  setIndex(index === 0 ? 0 : index - 1)
                }}
                className={styles.arrow}>
                <Arrow fill="white" />
              </div>
              <div
                onClick={() => {
                  setIndex(
                    index === activeOffers.length - 1
                      ? index
                      : index + 1
                  )
                }}
                className={styles.arrow + " " + styles.right}>
                <Arrow fill="white" />
              </div>
              <div className={styles.closeContainer}>
                <Close
                  onClick={() => {
                    const OldoffersId = JSON.parse(
                      localStorage.getItem("offersId")
                    )
                    let offersId = []
                    if (OldoffersId) {
                      offersId = [...OldoffersId]
                    }
                    // add an id if the id is not in the array
                    activeOffers.forEach((offer) => {
                      if (offersId.includes(offer._id)) {
                        return
                      } else {
                        offersId.push(offer._id)
                      }
                    })

                    localStorage.setItem(
                      "offersId",
                      JSON.stringify(offersId)
                    )
                    dispatch(resetActiveOffers())
                  }}
                  fill="white"
                  height={20}
                  width={20}
                />
              </div>
            </div>
          </div>
        )}
        <HelmetProvider>
          <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <link rel="canonical" href={process.env.REACT_APP_URL} />
            <link
              rel="next"
              href={
                process.env.REACT_APP_URL +
                `/${params.page === undefined ? 2 : params.page + 1}`
              }
            />
            {params.page === undefined || (
              <link rel="prev" href={process.env.REACT_APP_URL} />
            )}
            <meta charset="UTF-8" />

            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0"
            />

            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta
              property="og:url"
              content={process.env.REACT_APP_URL}
            />
            <meta property="og:type" content="website" />
            <meta
              property="og:image"
              content="https://www.compuonelb.com/static/media/logo.704b86f20af085db0b41.png"
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta
              name="twitter:url"
              content={process.env.REACT_APP_URL}
            />
            <meta
              name="twitter:image"
              content="https://www.compuonelb.com/static/media/logo.704b86f20af085db0b41.png"
            />
            <meta name="keywords" content={keywords} />
          </Helmet>
        </HelmetProvider>
        <Layout />
        <Header onChange={(e) => setKeyword(e.target.value)} />
        {currentCountry === "Lebanon" && <Services />}
        {keyword === "" ? (
          <LandingPage />
        ) : (
          <ProductsList sort={"relevance"} />
        )}
        <div style={{ display: "none" }}>
          {categoriesUrls &&
            Object.entries(categoriesUrls).map(([key, url]) => {
              return (
                <div key={key}>
                  <Link
                    style={{ fontSize: 8, fontWeight: 300 }}
                    to={`${url}/`}>
                    {`${url}/`}
                  </Link>
                </div>
              )
            })}
          {productsUrls &&
            Object.entries(productsUrls).map(([key, url]) => {
              return (
                <div key={key}>
                  <Link
                    style={{ fontSize: 8, fontWeight: 300 }}
                    to={`${url}/`}>
                    {`${url}/`}
                  </Link>
                </div>
              )
            })}
        </div>
        <Footer />
      </>
    )
  }
}
