import React, { useEffect } from "react"
import Carousel from "../carousel/Carousel"

import styles from "./Home.module.scss"
import ProductsList from "../ProductsList/ProductsList"
import { useSelector } from "react-redux"

export default function Home() {
  return (
    <main>
      <section id={styles.carousel}>
        {/* <h1 className={styles.heading}>Fresh News</h1> */}
        <Carousel />
      </section>
      <section id={styles.services}>
        <div className={styles.head_prod}>
          <hr />
          <span>Featured Products</span>
          <hr />
        </div>
        <ProductsList cat={null} subCat={null} sort={"relevance"} />
      </section>
    </main>
  )
}
