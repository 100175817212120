import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import { getUsers, toggleVerifyUser } from "../api/admin"

const initialState = {
  users: [],
  isLoading: false,
  errorMessage: "",
}

export const fetchUsers = createAsyncThunk(
  "db-admin/getUsers",
  async (payload, thunkApi) => {
    try {
      const token = thunkApi.getState().auth

      const usersData = await getUsers(token)

      return usersData
    } catch (error) {
      console.log("fetchUser " + error)
      throw error
    }
  }
)

export const toggleVerify = createAsyncThunk(
  "db-admin/verify",
  async (payload, thunkApi) => {
    try {
      const token = thunkApi.getState().auth

      const res = await toggleVerifyUser(payload, token)

      return res.data
    } catch (error) {
      console.log("toggleVeridy" + error)
      throw error
    }
  }
)

export const adminSlice = createSlice({
  name: "admin",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(fetchUsers.pending, (state, action) => {
      state.isLoading = true
    })

    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.isLoading = false
      state.users = action.payload
    })
    builder.addCase(fetchUsers.rejected, (state, action) => {
      state.isLoading = false
      console.log(action)
      state.errorMessage = action.payload
    })

    builder.addCase(toggleVerify.fulfilled, (state, action) => {
      const index = state.users.findIndex(
        (e) => e._id === action.payload.user._id
      )
      state.users[index] = action.payload.user
    })
  },
})

export default adminSlice.reducer
