import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDrivers } from '../../state/driversSlice'
import styles from './DriverList.module.scss'
import Card from './card/Card'
import Loading from 'react-loading'
import CustomLoading from '../custom/loading/CustomLoading'

export default function DriverList({ brand }) {
  const dispatch = useDispatch()
  const { drivers, isLoading } = useSelector((state) => state.drivers)

  useEffect(() => {
    dispatch(fetchDrivers())
  }, [dispatch])
  let sortedDrivers = drivers
  if (brand !== 'all' && brand != undefined) {
    sortedDrivers = sortedDrivers.filter(
      (p) => p.brand.toLowerCase() === brand.toLowerCase(),
    )
  }
  if (isLoading) {
    return <CustomLoading />
  } else {
    return (
      <div className={styles.main}>
        <div className={styles.drivers_list}>
          {Object.entries(sortedDrivers).map(([key, value]) => {
            return <Card key={key} driver={value} />
          })}
        </div>
      </div>
    )
  }
}
