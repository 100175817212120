import React, { useEffect, useState } from "react"
import styles from "./ProductsLists.module.scss"
import ProductItem from "./productItem/ProductItem"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchProducts,
  incrementClicked,
  setProduct,
} from "../../state/productSlice"
import { useNavigate, useParams } from "react-router-dom"
import NotFound from "../../icons/NotFound"
import { sortProducts } from "../../helpers/Products"
import { formatUrl } from "../../helpers/Formats"

export default function ProductsList({ cat, subCat, sort, brand }) {
  const params = useParams()

  const [page, setPage] = useState(params.page || 1)
  const [pageCount, setPageCount] = useState(0)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { type, country, isLoading, accessLevel, currentCountry } =
    useSelector((state) => state.auth)
  const { products, errorMessage } = useSelector(
    (state) => state.product
  )

  useEffect(() => {
    setPage(params.page || 1)
  })

  useEffect(() => {
    const payload = {
      subCat: subCat,
      cat: cat,
      country: country || currentCountry,
      accessLevel: accessLevel,
      page: page,
      type: type,
    }
    dispatch(fetchProducts(payload)).then((res) => {
      setPageCount(res.payload.pagination.pageCount)
    })
  }, [dispatch, cat, subCat, country, page, currentCountry])

  function handlePrevious() {
    if (params.category) {
      let category = formatUrl(cat)
      navigate(`/${category}/${parseInt(page) - 1}`)
      window.location.reload()
    } else if (params.subCat) {
      let category = formatUrl(cat)
      let subCategory = formatUrl(subCat)

      navigate(`/${category}/${subCategory}/${parseInt(page) - 1}`)
      window.location.reload()
    } else {
      navigate(`/home/${parseInt(page) - 1}`)
      window.location.reload()
    }
  }

  function handleNext() {
    if (params.category) {
      let category = formatUrl(cat)
      navigate(`/${category}/${parseInt(page) + 1}`)
      window.location.reload()
    } else if (params.subCat) {
      let category = formatUrl(cat)
      let subCategory = formatUrl(subCat)
      navigate(`/${category}/${subCategory}/${parseInt(page) + 1}`)
      window.location.reload()
    } else {
      navigate(`/home/${parseInt(page) + 1}`)
      window.location.reload()
    }
  }

  let hasProducts = products.length !== 0

  let sortedProducts = sortProducts(products, sort, type)

  if (cat || subCat) {
    sortedProducts = sortedProducts.sort((a, b) =>
      a.isPinned === b.isPinned ? 0 : a.isPinned ? -1 : 1
    )
  }

  if (brand !== "all" && brand != undefined) {
    sortedProducts = sortedProducts.filter(
      (p) => p.brand.toLowerCase() === brand.toLowerCase()
    )
  }
  if (errorMessage !== "") {
    return <div>Error</div>
  } else if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <div className={hasProducts ? styles.products : styles.container}>
      {hasProducts ? (
        <div className={styles.product_items}>
          {Object.entries(sortedProducts).map(([key, p]) => {
            return (
              <ProductItem
                onClick={() => {
                  dispatch(incrementClicked(p._id))

                  dispatch(setProduct(p))
                  let url = formatUrl(p.title)
                  navigate(`/${url}/`)
                }}
                key={key}
                product={p}
              />
            )
          })}
        </div>
      ) : (
        <>
          <NotFound />
          <p className={styles.noProducts}>
            No products found in "{cat}" category{" "}
          </p>
        </>
      )}

      {pageCount !== 0 && (
        <footer id={styles.footer}>
          <div className={styles.mainC}>
            <p className={styles.f_pa_di}>
              {page}/{pageCount}
            </p>

            <div className={styles.btns}>
              <button
                className={styles.prev}
                disabled={parseInt(page) === 1}
                onClick={handlePrevious}>
                Prev
              </button>
              <button
                className={styles.next}
                disabled={parseInt(page) === pageCount}
                onClick={handleNext}>
                Next
              </button>
            </div>
          </div>
        </footer>
      )}
    </div>
  )
}
