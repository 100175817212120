import React, { useState, useRef, useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import Placeholder from "../../../../../../icons/Placeholder"
import {
  deleteContact,
  updateContact,
} from "../../../../../../state/seoSlice"
import Input from "../../../../../custom/input/Input"
import TransInput from "../../../../../custom/transInput/TransInput"
import styles from "./Card.module.scss"
import Contacts from "./contacts/Contacts"
import CountriesSelector from "../../../../countries/CountriesSelector"

export default function Card({ cardData }) {
  const dispatch = useDispatch()
  const filePickerRef = useRef()
  const [imageFile, setImageFile] = useState([])
  const [image, setImage] = useState([])
  const [isVisible, setVisible] = useState(false)

  const { handleSubmit, control, getValues, watch, setValue } =
    useForm({
      defaultValues: {
        city: cardData.city || "",
        adressLine1: cardData.address1 || "",
        adressLine2: cardData.address2 || "",
        googleMapsLocation: cardData.googleMapsLocation || "",
        mondayFriday: cardData.mondayFriday || "",
        saturday: cardData.saturday || "",
        contacts: cardData.contacts || [],
        countries: cardData.countries || [],
        tiktok: cardData.tiktok || "",
        instagram: cardData.instagram || "",
        facebook: cardData.facebook || "",
        skype: cardData.skype || "",
        whatsapp: cardData.whatsapp || "",
        youtube: cardData.youtube || "",
        phoneNumber: cardData.phoneNumber || "",
      },
    })

  const pickImageHandler = () => {
    filePickerRef.current.click()
  }

  const onSave = (data) => {
    const payload = {
      id: cardData._id,
      city: data.city,
      adressLine1: data.adressLine1,
      adressLine2: data.adressLine2,
      googleMapsLocation: data.googleMapsLocation,
      mondayFriday: data.mondayFriday,
      saturday: data.saturday,
      contacts: data.contacts,
      image: imageFile[0] || cardData.image_url,
      countries: data.countries,
      tiktok: data.tiktok,
      instagram: data.instagram,
      facebook: data.facebook,
      skype: data.skype,
      whatsapp: data.whatsapp,
      youtube: data.youtube,
      phoneNumber: data.phoneNumber,
    }
    dispatch(updateContact(payload))
  }

  const imageTypeRegex = /image\/(png|jpg|jpeg)/gm

  const pickedHandler = (e) => {
    const { files } = e.target

    const validImageFile = []
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      if (file.type.match(imageTypeRegex)) {
        validImageFile.push(file)
      }
    }

    if (validImageFile.length) {
      setImageFile(validImageFile)
      filePickerRef.current.value = ""

      return
    }
    alert("Selected images are not of valid type!")
    filePickerRef.current.value = ""
  }

  useEffect(() => {
    const images = [],
      fileReaders = []
    let isCancel = false

    if (imageFile.length) {
      imageFile.forEach((file) => {
        const fileReader = new FileReader()
        fileReaders.push(fileReader)
        fileReader.onload = (e) => {
          const { result } = e.target
          if (result) {
            images.push(result)
          }
          if (images.length === imageFile.length && !isCancel) {
            setImage(images)
          }
        }
        fileReader.readAsDataURL(file)
      })
    }

    return () => {
      isCancel = true
      fileReaders.forEach((fileReader) => {
        if (fileReader.readyState === 1) {
          fileReader.abort()
        }
      })
    }
  }, [imageFile])

  return (
    <form
      onSubmit={handleSubmit(onSave)}
      className={styles.main_card}>
      {isVisible && (
        <Controller
          control={control}
          name="countries"
          render={({ field: { onChange, value } }) => {
            return (
              <CountriesSelector
                value={value}
                onSave={onChange}
                cancel={() => setVisible(false)}
              />
            )
          }}
        />
      )}

      <div
        onClick={pickImageHandler}
        className={styles.image_container}>
        <input
          onChange={pickedHandler}
          ref={filePickerRef}
          className={styles.file_upload}
          type="file"
        />
        {image[0] === undefined && cardData.image_url === "" ? (
          <Placeholder
            onClick={pickImageHandler}
            size={30}
            fill={"white"}
          />
        ) : (
          <>
            <div className={styles.cover} />
            <img
              alt="contact_card"
              className={styles.image}
              src={
                image[0] !== undefined
                  ? image[0]
                  : process.env.REACT_APP_BASE_URL +
                    cardData.image_url
              }
            />
          </>
        )}
      </div>
      <div className={styles.address}>
        <div className={styles.input}>
          <p className={styles.hint_text}>Address:</p>
          <Controller
            control={control}
            name="city"
            render={({ field: { onBlur, onChange, value } }) => {
              return (
                <Input
                  onBlur={onBlur}
                  value={value}
                  onChange={onChange}
                  placeholder="City"
                />
              )
            }}
          />

          <Controller
            control={control}
            name="adressLine1"
            render={({ field: { onBlur, onChange, value } }) => {
              return (
                <Input
                  onBlur={onBlur}
                  value={value}
                  onChange={onChange}
                  placeholder="Adress line 1"
                />
              )
            }}
          />

          <Controller
            control={control}
            name="adressLine2"
            render={({ field: { onBlur, onChange, value } }) => {
              return (
                <Input
                  onBlur={onBlur}
                  value={value}
                  onChange={onChange}
                  placeholder="Adress line 2"
                />
              )
            }}
          />
        </div>

        <div className={styles.time}>
          <div className={styles.time_header}>
            <p className={styles.time_days}>Monday - Friday</p>
            <p className={styles.time_days}>Saturday</p>
          </div>
          <div className={styles.time_entry}>
            <div className={styles.time_input}>
              <Controller
                control={control}
                name="mondayFriday"
                render={({ field: { onBlur, onChange, value } }) => {
                  return (
                    <TransInput
                      onBlur={onBlur}
                      value={value}
                      onChange={onChange}
                      placeholder="Time"
                    />
                  )
                }}
              />
            </div>
            <div className={styles.time_input}>
              <Controller
                control={control}
                name="saturday"
                render={({ field: { onBlur, onChange, value } }) => {
                  return (
                    <TransInput
                      onBlur={onBlur}
                      value={value}
                      onChange={onChange}
                      placeholder="Time"
                    />
                  )
                }}
              />
            </div>
          </div>
        </div>
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}

        <div className={styles.input}>
          <p className={styles.hint_text}>Location:</p>
          <em
            onClick={() => setVisible(true)}
            className={styles.countries}>
            Set Countries
          </em>

          <Controller
            control={control}
            name="googleMapsLocation"
            render={({ field: { onBlur, onChange, value } }) => {
              return (
                <Input
                  onBlur={onBlur}
                  value={value}
                  onChange={onChange}
                  placeholder="Google Maps Location"
                />
              )
            }}
          />
        </div>

        <div className={styles.input}>
          <p className={styles.hint_text}>YouTube:</p>
          <Controller
            control={control}
            name="youtube"
            render={({ field: { onBlur, onChange, value } }) => {
              return (
                <Input
                  onBlur={onBlur}
                  value={value}
                  onChange={onChange}
                  placeholder="YouTube"
                />
              )
            }}
          />
        </div>
        <div className={styles.input}>
          <p className={styles.hint_text}>Tiktok:</p>
          <Controller
            control={control}
            name="tiktok"
            render={({ field: { onBlur, onChange, value } }) => {
              return (
                <Input
                  onBlur={onBlur}
                  value={value}
                  onChange={onChange}
                  placeholder="TikTok"
                />
              )
            }}
          />
        </div>
        <div className={styles.input}>
          <p className={styles.hint_text}>Instagram:</p>
          <Controller
            control={control}
            name="instagram"
            render={({ field: { onBlur, onChange, value } }) => {
              return (
                <Input
                  onBlur={onBlur}
                  value={value}
                  onChange={onChange}
                  placeholder="Instagram"
                />
              )
            }}
          />
        </div>
        <div className={styles.input}>
          <p className={styles.hint_text}>Facebook:</p>
          <Controller
            control={control}
            name="facebook"
            render={({ field: { onBlur, onChange, value } }) => {
              return (
                <Input
                  onBlur={onBlur}
                  value={value}
                  onChange={onChange}
                  placeholder="Facebook"
                />
              )
            }}
          />
        </div>
        <div className={styles.input}>
          <p className={styles.hint_text}>Skype:</p>
          <Controller
            control={control}
            name="skype"
            render={({ field: { onBlur, onChange, value } }) => {
              return (
                <Input
                  onBlur={onBlur}
                  value={value}
                  onChange={onChange}
                  placeholder="Skype"
                />
              )
            }}
          />
        </div>
        <div className={styles.input}>
          <p className={styles.hint_text}>Whatsapp:</p>
          <Controller
            control={control}
            name="whatsapp"
            render={({ field: { onBlur, onChange, value } }) => {
              return (
                <Input
                  onBlur={onBlur}
                  value={value}
                  onChange={onChange}
                  placeholder="Whatsapp"
                />
              )
            }}
          />
        </div>
        <div className={styles.input}>
          <p className={styles.hint_text}>Phone Number:</p>
          <Controller
            control={control}
            name="phoneNumber"
            render={({ field: { onBlur, onChange, value } }) => {
              return (
                <Input
                  onBlur={onBlur}
                  value={value}
                  onChange={onChange}
                  placeholder="Phone Number"
                />
              )
            }}
          />
        </div>
      </div>
      <div className={styles.contact}>
        <Controller
          control={control}
          name="contacts"
          render={({ field: { onChange, value, onBlur } }) => {
            return (
              <Contacts
                contacts={getValues("contacts")}
                onChange={(e) => onChange(e)}
                value={value}
              />
            )
          }}
        />

        <div className={styles.main_div}>
          <button
            onClick={() => dispatch(deleteContact(cardData._id))}
            className={styles.delete}>
            Delete
          </button>
          <button className={styles.save_button}>Save</button>
        </div>
      </div>
    </form>
  )
}
