import axios from "axios"

export const getProducts = async (payload) => {
  try {
    const res = await axios.get("products/getProducts", {
      params: {
        subCat: payload.subCat,
        cat: payload.cat,
        country: payload.country,
        accessLevel: payload.accessLevel,
        page: payload.page,
        type: payload.type,
      },
    })

    return res.data
  } catch (error) {
    throw error
  }
}

export const getAdminProducts = async (payload) => {
  try {
    const res = await axios.get("products/getAdminProducts", {
      params: {
        page: payload.page,
      },
    })

    return res.data
  } catch (error) {
    throw error
  }
}

export const getProduct = async (payload) => {
  const url = payload.url
  const type = payload.type || "base"
  const country = payload.country

  try {
    const res = await axios.get(
      `products/getProduct/${url}/${type}/${country}`
    )

    return res.data
  } catch (error) {
    throw error
  }
}

export const getProductsByEntry = async (token, payload) => {
  try {
    const res = await axios.get("products/getAdminProducts", {
      params: {
        entry: payload.entry,
        page: payload.page,
      },
    })
    return res.data
  } catch (error) {
    throw error
  }
}

export const createProductRequest = async (
  token,
  payload,
  isUpdate
) => {
  try {
    const data = payload.product
    let formData = new FormData()

    // category: "Peripherals"
    formData.append("category", data.category)
    formData.append("subCategory", data.subCategory)

    // components: undefined
    const components = JSON.stringify(data.properties)
    formData.set("components", components)

    // description: "description"
    formData.append("description", data.description)

    // features: ["feature"] (1)
    for (let i = 0; i < data.features.length; i++) {
      formData.append("features", data.features[i])
    }

    // image: File {name: " .jpg", lastModified: 1677266693000, webkitRelativePath: "", size: 97408, type: "image/jpeg", …}

    for (const key of Object.keys(data.imageFiles)) {
      formData.append("imgFiles", data.imageFiles[key])
    }

    for (const key of Object.keys(data.imageLinks)) {
      formData.append("imgURL", data.imageLinks[key])
    }

    formData.set(
      "pricingGroupes",
      JSON.stringify(data.pricingGroupes)
    )

    // title: "title"
    formData.append("title", data.title)
    formData.append("brand", data.brand)

    formData.append("metaTitle", data.metaTitle)
    formData.append("metaDescription", data.metaDescription)
    formData.append("metaKeywords", data.metaKeywords)

    // warranty: [0, "years"] (2)
    for (let i = 0; i < data.warranty.length; i++) {
      formData.append("warranty", data.warranty[i])
    }
    formData.set("token", JSON.stringify(token))

    let res

    if (!isUpdate) {
      res = await axios.post("products/addProducts", formData)
    } else {
      res = await axios.post(
        `products/updateProduct/${payload.product.prodId}`,
        formData
      )
    }

    return res.data
  } catch (error) {
    throw error
  }
}

//delete product
export const deleteProductRequest = async (token, id) => {
  try {
    const res = await axios.delete(`products/deleteProduct/${id}`, {
      params: {
        ...token,
      },
    })
    return res.data
  } catch (error) {
    throw error
  }
}

// handle visibile in products
export const handleVisibleRequest = async (token, id) => {
  try {
    const res = await axios.patch(
      `products/toggleVisible/${id}`,
      token
    )
    return res.data
  } catch (error) {
    throw error
  }
}

// handle pinned in products
export const handlePinnedRequest = async (token, id) => {
  try {
    console.log(token)
    const res = await axios.patch(
      `products/toggleIsPinned/${id}`,
      token
    )
    return res.data
  } catch (error) {
    throw error
  }
}

// handle discount in products
export const handleDiscountRequest = async (token, id, discount) => {
  try {
    const res = await axios.patch(
      `products/toggleDiscount/${id}/${discount}`,
      token
    )
    return res.data
  } catch (error) {
    throw error
  }
}

// handle sold out in products
export const toggleSoldOutRequest = async (token, id, countries) => {
  const payload = {
    countries: countries,
    token: token,
  }
  try {
    const res = await axios.patch(
      `products/toggleSoldOut/${id}`,
      payload
    )
    return res.data
  } catch (error) {
    throw error
  }
}

export const incrementClicksRequest = async (token, id) => {
  try {
    const res = await axios.patch(
      `products/updateProductClicks/${id}`
    )
    return res.data
  } catch (error) {
    throw error
  }
}

export const updateRatingRequest = async (id, rating) => {
  try {
    const res = await axios.patch(`products/updateRating/${id}`, {
      rating,
    })
    return res.data
  } catch (error) {
    throw error
  }
}

export const getSortedRequest = async (payload) => {
  try {
    const res = await axios.get("products/getSortedProducts", {
      params: {
        country: payload.country,
        sort: payload.sort,
        type: payload.type,
      },
    })
    return res.data
  } catch (error) {
    throw error
  }
}

export const getProductsUrlForSeoRequest = async () => {
  try {
    const res = await axios.get("products/seoUrl")
    return res.data
  } catch (error) {
    throw error
  }
}
