import React from "react"
import NotFound from "../../../../icons/NotFound"
import Header from "../../../../components/admin/header/Header"
import styles from "./DriverList.module.scss"
import Driver from "../../../../components/admin/driver/Driver"
export default function DriverList({ setUpdate, setVisible, drivers }) {
  return drivers.length === 0 ? (
    <div className={styles.notFound}>
      <NotFound />
      <h5>No Drivers Found</h5>
    </div>
  ) : (
    <>
      <div>
        {/* <Header categories={["Image", "Name", "Brands", "actions"]} /> */}
        <Header categories={["Name", "Platform", "actions"]} />
        <div className={styles.cardList}>
          {Object.entries(drivers).map(([key, driver]) => {
            return (
              <Driver
                setVisible={() => {
                  setUpdate(true)
                  setVisible(true)
                }}
                key={key}
                driver={driver}
              />
            )
          })}
        </div>
      </div>
    </>
  )
}
