import React from "react"
import styles from "./Services.module.scss"
import Phone from "../../../icons/Phone"
import Payment from "../../../icons/Payment"
import Delivery from "../../../icons/Delivery"

export default function Services() {
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <div className={styles.service}>
          <Phone size={25} color={"grey"} />
          <div className={styles.text}>
            <a href="/contact-us">
              <h3>Contact Us</h3>
            </a>
            <p>+961 9 221 062</p>
          </div>
        </div>
        <div className={styles.service}>
          <Payment size={25} color={"grey"} />
          <div className={styles.text}>
            <h3>Payment Method</h3>
            <p>Cash on delivery</p>
          </div>
        </div>
        <div className={styles.service}>
          <Delivery size={25} color={"grey"} />
          <div className={styles.text}>
            <h3>Fast Delivery</h3>
            <p>Same Day up to 48 hours (Working Day)</p>
          </div>
        </div>
      </div>
    </section>
  )
}
