import React from 'react'

export default function NotFound() {
  return (
    <svg width="250" height="250" viewBox="0 0 511.985 511.985">
      <g>
        <path
          d="M344.162 0C251.625 0 176.34 75.285 176.34 167.822c0 43.577 16.699 83.323 44.028 113.188l-30.978 30.978-.365-.365c-4.444-4.444-10.354-6.893-16.64-6.893s-12.195 2.448-16.64 6.893L9.225 458.144c-12.3 12.301-12.3 32.315 0 44.616 6.15 6.15 14.229 9.226 22.308 9.226s16.158-3.075 22.308-9.226l146.52-146.52c4.445-4.445 6.893-10.354 6.893-16.64s-2.448-12.195-6.893-16.64l-.365-.365 30.978-30.978c29.865 27.329 69.612 44.028 113.188 44.028 92.538 0 167.823-75.285 167.823-167.823C511.985 75.285 436.7 0 344.162 0zM43.235 492.153c-6.452 6.452-16.951 6.452-23.403 0s-6.452-16.951 0-23.403l123.073-123.073 23.403 23.403zm146.52-146.52-12.841 12.841-23.403-23.403 12.841-12.841c1.611-1.611 3.754-2.499 6.033-2.499s4.422.888 6.033 2.499l11.336 11.337a8.475 8.475 0 0 1 2.499 6.033 8.47 8.47 0 0 1-2.498 6.033zm154.407-24.987c-84.267 0-152.822-68.556-152.822-152.823S259.896 15 344.162 15s152.823 68.556 152.823 152.822c0 84.267-68.556 152.824-152.823 152.824z"
          fill="#999"
        ></path>
        <path
          d="M440.158 71.827c-48.271-48.271-125.3-53.072-179.172-11.17a7.498 7.498 0 0 0-1.315 10.524 7.498 7.498 0 0 0 10.524 1.315c47.916-37.269 116.424-32.996 159.357 9.937 42.933 42.934 47.205 111.442 9.937 159.356a7.5 7.5 0 0 0 5.914 12.105 7.486 7.486 0 0 0 5.925-2.896c41.903-53.872 37.102-130.899-11.17-179.171zM418.131 263.147c-21.84 16.988-47.954 25.345-73.979 25.344-31.071-.001-62.014-11.916-85.377-35.28-42.933-42.933-47.205-111.442-9.937-159.356a7.5 7.5 0 0 0-11.839-9.21c-41.904 53.874-37.102 130.901 11.17 179.172 26.271 26.272 61.053 39.667 95.992 39.666 29.257-.001 58.627-9.398 83.18-28.496a7.5 7.5 0 1 0-9.21-11.84z"
          fill="#999"
        ></path>
        <path
          d="M282.746 165.109c1.464 1.465 3.384 2.197 5.303 2.197s3.839-.732 5.303-2.197l10.729-10.729 10.729 10.729c1.464 1.465 3.384 2.197 5.303 2.197s3.839-.732 5.303-2.197a7.5 7.5 0 0 0 0-10.606l-10.729-10.729 10.729-10.729c2.929-2.929 2.929-7.677 0-10.606s-7.678-2.929-10.606 0l-10.729 10.729-10.729-10.729a7.5 7.5 0 0 0-10.606 0 7.5 7.5 0 0 0 0 10.606l10.729 10.729-10.729 10.729a7.5 7.5 0 0 0 0 10.606zM362.908 165.109c1.464 1.465 3.384 2.197 5.303 2.197s3.839-.732 5.303-2.197l10.729-10.729 10.729 10.729c1.464 1.465 3.384 2.197 5.303 2.197s3.839-.732 5.303-2.197a7.5 7.5 0 0 0 0-10.606l-10.729-10.729 10.729-10.729c2.929-2.929 2.929-7.677 0-10.606s-7.678-2.929-10.606 0l-10.729 10.729-10.729-10.729a7.5 7.5 0 0 0-10.606 0 7.5 7.5 0 0 0 0 10.606l10.729 10.729-10.729 10.729a7.497 7.497 0 0 0 0 10.606zM298.774 218.637a7.501 7.501 0 0 0 10.616 10.598c8.774-8.79 21.448-13.831 34.772-13.831s25.999 5.041 34.773 13.831a7.498 7.498 0 0 0 10.606.009 7.5 7.5 0 0 0 .009-10.606c-11.567-11.588-28.111-18.233-45.389-18.233-17.276-.002-33.82 6.644-45.387 18.232z"
          fill="#999"
        ></path>
      </g>
    </svg>
  )
}
