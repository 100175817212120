import React from 'react'
import { Button } from 'react-bootstrap'
import styles from './Title.module.scss'

export default function Title(props) {
  return (
    <div className={styles.header}>
      <h1>{props.children}</h1>
      {props.isButton && (
        <Button onClick={props.onClick} className={styles.btn}>
          {props.buttonContent}
        </Button>
      )}
    </div>
  )
}
