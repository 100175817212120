import React from 'react'

export default function Verified(props) {
  return (
    <svg
      style={{ cursor: 'pointer' }}
      onClick={props.onClick}
      width="20"
      height="20"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          fill="#eceff1"
          d="M21.25 22H.75a.752.752 0 0 1-.728-.932l.53-2.122a3.39 3.39 0 0 1 2.224-2.402l3.922-1.307c.444-.187.751-.949.801-2.051-.503-.475-1.406-1.475-1.406-2.539a.732.732 0 0 0-.025-.212.748.748 0 0 1-.362-.393c-.052-.128-.496-1.262-.496-2.046l.023-.182c.066-.268.215-.537.434-.731.003-.084.008-.176.014-.273.024-.478.059-1.113.059-1.907C5.739 2.974 7.998 0 11 0c2.527 0 3.819 1.326 4.127 2.275.604.36 1.18 1.002 1.18 2.628v2.184c.215.186.363.435.442.664l.041.245c0 .784-.444 1.918-.495 2.045a.748.748 0 0 1-.362.393.707.707 0 0 0-.025.212c0 1.064-.903 2.064-1.406 2.539.051 1.102.361 1.866.852 2.07l3.871 1.288a3.394 3.394 0 0 1 2.224 2.401l.53 2.124a.752.752 0 0 1-.729.932m-5.467-11.51-.002.001zm-9.566 0 .002.001zm-.003-.001.001.001z"
        ></path>
        <path
          fill="#4caf50"
          d="m23.491 11.826-5.25-1.786a.737.737 0 0 0-.482 0l-5.25 1.786a.748.748 0 0 0-.509.71v4.018c0 4.904 5.474 7.288 5.707 7.387.188.08.397.08.586 0 .233-.1 5.707-2.483 5.707-7.387v-4.018a.748.748 0 0 0-.509-.71z"
        ></path>
        <path
          fill="#ffffff"
          d="m21.286 15.618-2.75 3.5a1 1 0 0 1-1.437.142l-1.75-1.5a1 1 0 1 1 1.301-1.518l.958.821 2.105-2.679a.998.998 0 0 1 1.404-.168.996.996 0 0 1 .169 1.402z"
        ></path>
        <path
          fill="#cdd0d2"
          d="M6.219 10.49h-.002zm-.004-.001-.001-.001zM11 0C7.998 0 5.739 2.974 5.739 4.903c0 .794-.034 1.43-.059 1.907l-.014.273a1.461 1.461 0 0 0-.434.731l-.022.182c0 .784.444 1.918.495 2.045a.748.748 0 0 0 .362.393c.011.034.025.1.025.212 0 1.064.903 2.064 1.406 2.539-.05 1.102-.356 1.864-.801 2.051l-3.922 1.307a3.393 3.393 0 0 0-2.224 2.402l-.53 2.122a.747.747 0 0 0 .137.643c.143.184.361.29.592.29H11z"
        ></path>
        <path
          fill="#429846"
          d="M18 10a.734.734 0 0 0-.241.04l-5.25 1.786a.748.748 0 0 0-.509.71v4.018c0 4.904 5.474 7.288 5.707 7.387A.763.763 0 0 0 18 24v-4.532a.99.99 0 0 1-.9-.209l-1.75-1.5A.998.998 0 0 1 16.001 16c.23 0 .462.079.65.24l.958.821.391-.498z"
        ></path>
        <path
          fill="#dedede"
          d="M16.001 16a1 1 0 0 0-.652 1.759l1.75 1.5a.99.99 0 0 0 .9.209v-2.905l-.392.498-.958-.821a.99.99 0 0 0-.648-.24"
        ></path>
      </g>
    </svg>
  )
}
