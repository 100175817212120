import React, { useEffect, useState } from "react"
import Title from "../../../components/admin/title/Title"
import styles from "./Offers.module.scss"
import Input from "../../../components/custom/input/Input"
import DatePicker from "react-datepicker"
import { useDispatch, useSelector } from "react-redux"
import "react-datepicker/dist/react-datepicker.css"
import { addOffer, getOffers, updateOffer } from "../../../state/offerSlice"
import Edit from "../../../icons/Edit"
import Delete from "../../../icons/Delete"
import { deleteOffer } from "../../../state/offerSlice"
import CountriesSelector from "../../../components/admin/countries/CountriesSelector"
import { set } from "react-hook-form"

export default function Offers() {
  const [showModel, setShowModel] = useState(false)
  const [offer, setOffer] = useState(null)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [titleValue, setTitleValue] = useState("")
  const [image, setImage] = useState(null)
  const [showCountrySelector, setShowCountrySelector] = useState(false)
  const [countries, setCountries] = useState([])

  const dispatch = useDispatch()

  useEffect(() => {
    if (offer) {
      // transform the date string to a date object
      setEndDate(new Date(offer?.endDate))
      setStartDate(new Date(offer?.startDate))
      setCountries(offer?.countries || [])
    } else {
      setEndDate(new Date())
      setStartDate(new Date())
    }
  }, [offer])

  const onChange = (dates) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }
  const imageTypeRegex = /image\/(jpg|jpeg|png|webp)/gm

  function pickImage(e) {
    const { files } = e.target
    const file = files[0]

    if (file.type.match(imageTypeRegex)) {
      setImage(file)
    }
  }

  function onCountrySelect(countries) {
    setCountries(countries)
  }

  const submitOffer = (e) => {
    const payload = {
      title: titleValue,
      startDate: startDate,
      endDate: endDate,
      image: image,
      id: offer?._id,
      countries: countries,
    }
    dispatch(e === "add" ? addOffer(payload) : updateOffer(payload))
    setOffer(null)
    setImage(null)
    setCountries([])
  }

  const { offers } = useSelector((state) => state.offers)

  useEffect(() => {
    dispatch(getOffers())
  }, [dispatch])

  return (
    <section id="mainContainer">
      <Title
        isButton="true"
        onClick={() => setShowModel(true)}
        buttonContent={"Add"}
      >
        Offers
      </Title>
      {showModel && (
        <div className={styles.layer}>
          <div className={styles.cardContainer}>
            <p>Offers</p>

            <Input
              onChange={(e) => {
                setTitleValue(e.target.value)
              }}
              value={titleValue}
              placeholder="Title"
            />

            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
            {/* I need to display the selected dates */}
            <p>
              {startDate?.getDate() +
                "/" +
                startDate?.getMonth() +
                "/" +
                startDate?.getFullYear()}{" "}
              -{" "}
              {endDate
                ? endDate?.getDate() +
                  "/" +
                  endDate?.getMonth() +
                  "/" +
                  endDate?.getFullYear()
                : "Select end date"}
            </p>
            <input
              onChange={pickImage}
              type="file"
              accept=".jpg,.jpeg,.png,.webp"
            />
            <span
              style={{ color: "#0099ff", cursor: "pointer" }}
              onClick={() => setShowCountrySelector(true)}
            >
              Select Countries
            </span>
            {showCountrySelector && (
              <CountriesSelector
                value={countries}
                cancel={() => {
                  setShowCountrySelector(false)
                }}
                onSave={(countries) => {
                  onCountrySelect(countries)
                  setShowCountrySelector(false)
                }}
              />
            )}
            <button
              onClick={() => {
                submitOffer(offer ? "updarte" : "add")
                setShowModel(false)
                setOffer(null)
                setTitleValue("")
                setStartDate(new Date())
                setEndDate(new Date())
              }}
            >
              {offer ? "Update Offer" : "Add Offer"}
            </button>
          </div>
          <div
            onClick={() => {
              setShowModel(false)
              setOffer(null)
              setTitleValue("")
              setStartDate(new Date())
              setEndDate(new Date())
            }}
            className={styles.layerBg}
          />
        </div>
      )}
      <div className={styles.offersList}>
        <div className={styles.header}>
          <p>Image</p>
          <p>Title</p>
          <p>Start Date</p>
          <p>End Date</p>
          <p>Actions</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column-reverse",
          }}
        >
          {Object.entries(offers).map(([key, offer]) => {
            return (
              <div key={key} className={styles.offerCard}>
                <div>
                  <div className={styles.imageContainer}>
                    <img
                      className={styles.image}
                      src={process.env.REACT_APP_BASE_URL + offer.image}
                      alt="offer"
                    />
                  </div>
                </div>
                <div className={styles.title}>
                  <p>{offer.title}</p>
                </div>
                <div className={styles.date}>
                  <p>
                    {offer.startDate
                      .split("T")[0]
                      .split("-")
                      .reverse()
                      .join("-")}
                  </p>
                </div>
                <div className={styles.date}>
                  <p>
                    {offer.endDate.split("T")[0].split("-").reverse().join("-")}
                  </p>
                </div>
                <div className={styles.icons}>
                  <Edit
                    onClick={() => {
                      setShowModel(true)
                      setTitleValue(offer.title)
                      setEndDate(offers.endDate)
                      setStartDate(offers.startDate)
                      setOffer(offer)
                    }}
                    size={15}
                  />
                  <Delete
                    onClick={() => {
                      dispatch(deleteOffer(offer))
                    }}
                    size={15}
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
