import React, { useState } from "react"
import styles from "./Card.module.scss"
import { useMediaPredicate } from "react-media-hook"

export default function Card({ product, onChange, onDelete }) {
  const [quantity, setQuantity] = useState(product.quantity)
  const lessThan650 = useMediaPredicate("(max-width: 650px)")

  return (
    <>
      <div className={styles.item_card}>
        <div
          style={{
            flex: 4,
            display: "flex",
            alignItems: "center",
            gap: 10,
          }}>
          <div className={styles.image_container}>
            <img
              src={process.env.REACT_APP_BASE_URL + product.image}
            />
          </div>
          <a href={product.pageUrl} className={styles.item_title}>
            {product.title} {product.variant && "-"} {product.variant}
          </a>
        </div>
        {/* <div className={styles.flex}>
          <p className={styles.price}>$ {product.price}</p>
        </div> */}
        {/* <div className={styles.flex}>
          <div className={styles.qt_selector}>
            <div
              className={styles.qt_button + " " + styles.remove}
              onClick={() => {
                setQuantity(quantity - 1)
                if (quantity - 1 === 0) {
                  onDelete(product.product)
                  return
                }
                onChange(quantity - 1, product.product)
              }}>
              -
            </div>
            <div className={styles.qt_container}>{quantity}</div>
            <div
              className={styles.qt_button + " " + styles.add}
              onClick={() => {
                if (quantity >= 10) return
                setQuantity(quantity + 1)
                onChange(quantity + 1, product.product)
              }}>
              +
            </div>
          </div>
        </div> */}

        <div className={styles.flex}>
          <p className={styles.price}>x {quantity}</p>
        </div>
        <div className={styles.flex}>
          <p className={styles.subTotal}>
            $ {quantity * product.price}
          </p>
        </div>
      </div>
      <hr className={styles.divider} />
    </>
  )
}
