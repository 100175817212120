import React from "react"
import styles from "./Status.module.scss"

export default function Status({ status }) {
  if (status === "Pending") {
    return <div className={styles.pending}>{status}</div>
  } else if (status === "Confirmed") {
    return <div className={styles.confirmed}>{status}</div>
  } else if (status === "Cancelled") {
    return <div className={styles.cancelled}>{status}</div>
  } else if (status === "Shipped") {
    return <div className={styles.shipped}>{status}</div>
  } else if (status === "Received") {
    return <div className={styles.received}>{status}</div>
  } else if (status === "Returned") {
    return <div className={styles.returned}>{status}</div>
  } else if (status === "Refunded") {
    return <div className={styles.refunded}>{status}</div>
  }
}
