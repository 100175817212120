import React from "react"
import Drawer from "react-modern-drawer"
import styles from "./MobileCart.module.scss"
import { useDispatch, useSelector } from "react-redux"
import Remove from "../../../../icons/Remove"
import {
  deleteOrder,
  updateOrder,
} from "../../../../state/orderSlice"
import { useMediaPredicate } from "react-media-hook"
import { useNavigate } from "react-router-dom"

export default function MobileCart(props) {
  const lessThan460 = useMediaPredicate("(max-width: 460px)")
  const { order } = useSelector((state) => state.orders)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const toggleDrawer = () => {
    props.toggleDrawer(false)
  }
  let orderPayload = {
    orderId: !order ? "" : order.orderId,
    products: !order ? "" : order.products,
    totalPrice: !order ? "" : order.totalPrice,
  }
  return (
    <Drawer
      size={lessThan460 ? "80%" : "60%"}
      open={props.isOpen}
      onClose={() => {
        return
      }}
      lockBackgroundScroll={true}
      direction="right">
      <div
        onClick={() => {
          return
        }}
        className={styles.main}>
        <h1 className={styles.header}>CART</h1>
        {Object.entries(order.products).map(([key, product]) => {
          return (
            <div key={key}>
              <div className={styles.list_products}>
                <div className={styles.product_card}>
                  <div className={styles.image_container}>
                    <img
                      className={styles.image}
                      src={
                        process.env.REACT_APP_BASE_URL + product.image
                      }
                    />
                  </div>
                  <div className={styles.product_content}>
                    <p className={styles.product_name}>
                      {product.title}
                    </p>
                    <div className={styles.product_price_container}>
                      <p className={styles.product_price}>
                        {product.quantity}
                      </p>
                      <p className={styles.product_price_icon}>x</p>
                      <p className={styles.product_price}>
                        ${product.price}
                      </p>
                    </div>
                  </div>
                  <div className={styles.remove_container}>
                    <Remove
                      onClick={() => {
                        let temp = [...order.products]
                        const productsPrice =
                          product.price * product.quantity
                        orderPayload.totalPrice -= productsPrice
                        temp.splice(key, 1)
                        orderPayload.products = temp
                        if (orderPayload.products.length === 0) {
                          localStorage.removeItem("cart_key")
                          const storageEvent = new Event("cart_key")
                          window.dispatchEvent(storageEvent)
                          dispatch(deleteOrder(order.orderId))
                        } else {
                          dispatch(updateOrder(orderPayload))
                        }
                      }}
                      size={"10px"}
                    />
                  </div>
                </div>
              </div>
            </div>
          )
        })}
        <div style={{ height: 20 }} />
        <hr className={styles.divider} />
        <div className={styles.subTotal}>
          <p className={styles.text}>SubTotal:</p>
          <p className={styles.totalPrice}>${order.totalPrice}</p>
        </div>
        <hr className={styles.divider} />
        <div className={styles.cart_footer}>
          <div
            onClick={() => {

              if (order.orderStatus === "Pending") {
                navigate(`/cart/${order.orderId}/payment`)
              } else {
                navigate(`/cart/${order.orderId}/cart`)
              }
            }}
            className={styles.view_cart}>
            <p>View Cart</p>
          </div>
        </div>
        <div className={styles.remove}>
          <Remove size={15} />
        </div>
      </div>
    </Drawer>
  )
}
