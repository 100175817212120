import {
  createSlice,
  createAsyncThunk,
  current,
} from "@reduxjs/toolkit"
import {
  createDriverRequest,
  deleteDriverRequest,
  getDrivers,
  handleVisibleRequest,
} from "../api/drivers"

const initialState = {
  drivers: [],
  isLoading: true,
  errorMessage: "",
  driver: undefined,
}

export const fetchDrivers = createAsyncThunk(
  "drivers/getDrivers",
  async (payload, thunkApi) => {
    try {
      const driversData = await getDrivers()
      return driversData
    } catch (error) {
      console.log("fetchDrivers" + error)
      throw error
    }
  }
)

export const addDriver = createAsyncThunk(
  "db-admin/addDriver",
  async (payload, thunkApi) => {
    try {
      const token = thunkApi.getState().auth
      const res = await createDriverRequest(token, payload, false)
      return res
    } catch (error) {
      console.log("addDriver" + error)
      throw error
    }
  }
)

export const updateDriver = createAsyncThunk(
  "db-admin/updateDriver",
  async (payload, thunkApi) => {
    try {
      const token = thunkApi.getState().auth
      const res = await createDriverRequest(token, payload, true)
      return res
    } catch (error) {
      console.log("updateDriver" + error)
      throw error
    }
  }
)

export const deleteDriver = createAsyncThunk(
  "db-admin/deleteDriver",
  async (payload, thunkApi) => {
    try {
      const token = thunkApi.getState().auth
      const res = await deleteDriverRequest(payload.id, token)
      return res
    } catch (error) {
      console.log("deleteDriver" + error)
      throw error
    }
  }
)

export const handleVisible = createAsyncThunk(
  "db-admin/handleVisible",
  async (payload, thunkApi) => {
    try {
      const token = thunkApi.getState().auth
      const res = await handleVisibleRequest(payload.id, token)
      return res
    } catch (error) {
      console.log("handleVisible" + error)
      throw error
    }
  }
)

export const driversSlice = createSlice({
  name: "drivers",
  initialState,
  reducers: {
    setDriver: (state, action) => {
      state.driver = action.payload
    },
    resetDriver: (state, action) => {
      state.driver = undefined
    },
  },
  extraReducers: (builder) => {
    //All
    builder.addCase(fetchDrivers.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchDrivers.fulfilled, (state, action) => {
      state.isLoading = false
      state.drivers = action.payload
    })
    builder.addCase(fetchDrivers.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.payload
    })
    //Add
    builder.addCase(addDriver.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(addDriver.fulfilled, (state, action) => {
      state.isLoading = false
      state.drivers = [...current(state.drivers), action.payload]
    })
    builder.addCase(addDriver.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.payload
    })
    //Update
    builder.addCase(updateDriver.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(updateDriver.fulfilled, (state, action) => {
      state.isLoading = false
      const index = state.drivers.findIndex(
        (driver) => driver.id === action.payload.id
      )
      console.log(action.payload)
      state.drivers[index] = action.payload
    })
    builder.addCase(updateDriver.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.payload
    })
    //Delete
    builder.addCase(deleteDriver.pending, (state, action) => {
      state.isLoading = true
    })

    builder.addCase(deleteDriver.fulfilled, (state, action) => {
      state.isLoading = false
      const index = state.drivers.findIndex(
        (driver) => driver.id === action.payload.id
      )
      state.drivers.splice(index, 1)
    })
    builder.addCase(deleteDriver.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.payload
    })
    //Handle Visible

    builder.addCase(handleVisible.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(handleVisible.fulfilled, (state, action) => {
      state.isLoading = false
      let drivers = [...current(state.drivers)]
      console.log(action.payload)

      const index = drivers.findIndex((driver) => {
        return driver._id === action.payload._id
      })

      state.drivers[index] = action.payload
    })
    builder.addCase(handleVisible.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.payload
    })
  },
})

export const { resetDriver, setDriver } = driversSlice.actions

export default driversSlice.reducer
