const customerTypes = [
  "basePrice",
  "corporatePrice",
  "retailerPrice",
  "distributorPrice",
]

export function sortProducts(products, sort, type) {
  let res = [...products]
  if (sort === "price-asce") {
    res = res.sort((a, b) => a.price - b.price)
  } else if (sort === "price-desc") {
    res = res.sort((a, b) => b.price - a.price)
  } else if (sort === "relevance") {
    res = res.sort((a, b) => b.clicks - a.clicks)
  } else if (sort === "date") {
    res = res.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    )
  }

  return res
}

export function getMaxPrice(type, product) {
  const maxPrices = {}
  customerTypes.forEach((type) => {
    let maxPrice = 0
    let temp = 0
    if (product === undefined) {
      return
    }
    product.components.forEach((component) => {
      component.component.properties.forEach((property) => {
        if (parseInt(property[type]) > parseInt(temp)) {
          temp = property[type]
        }
      })

      maxPrice = parseInt(maxPrice) + parseInt(temp)
      temp = 0
    })
    maxPrices[type] = maxPrice
  })
  Object.entries(maxPrices).forEach(([key, value]) => {
    maxPrices[key] = parseInt(product[key]) + parseInt(value)
  })
  if (type === "Retailer") {
    return maxPrices["retailerPrice"]
  } else if (type === "Corporate") {
    return maxPrices["corporatePrice"]
  } else if (type === "Distributor") {
    return maxPrices["distributorPrice"]
  } else {
    return maxPrices["basePrice"]
  }
}

export function getMinPrice(type, product) {
  const minPrices = {}
  customerTypes.forEach((type) => {
    let minPrice = 0
    let temp = Infinity
    if (product === undefined) {
      return
    }

    product.components.forEach((component) => {
      component.component.properties.forEach((property) => {
        if (parseInt(property[type]) < temp) {
          temp = property[type]
        }
      })
      minPrice = parseInt(minPrice) + parseInt(temp)
      temp = Infinity
    })
    minPrices[type] = minPrice
  })
  Object.entries(minPrices).forEach(([key, value]) => {
    minPrices[key] = parseInt(product[key]) + parseInt(value)
  })
  if (type === "Retailer") {
    return minPrices["retailerPrice"]
  } else if (type === "Corporate") {
    return minPrices["corporatePrice"]
  } else if (type === "Distributor") {
    return minPrices["distributorPrice"]
  } else {
    return minPrices["basePrice"]
  }
}

export function getShowPrice(
  country,
  currentCountry,
  token,
  verified
) {
  if (currentCountry === "") {
    return false
  }
  if (country === "Cyprus" || currentCountry === "Cyprus") {
    if (token && verified) {
      return true
    } else {
      return false
    }
  } else if (token && verified) {
    return true
  } else if (token && !verified) {
    return false
  } else {
    return true
  }
}

export function areObjectsEqual(obj1, obj2) {
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (let key of keys1) {
    if (!obj2.hasOwnProperty(key)) {
      return false
    }
    if (obj1[key] !== obj2[key]) {
      return false
    }
  }

  return true
}

export function sortProductsByCat(products, cat) {
  const res = []
  products.forEach((product) => {
    if (product.category === cat) {
      res.push(product)
    }
  })
  return res
}

export function getLatestProducts(products) {
  let res = [...products]

  res = res.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  )
  res = res.slice(0, 5)
  return res
}

export function getMaxClicks(products) {
  let maxClicks = 0

  products.forEach((product) => {
    if (product.clicks > maxClicks) {
      maxClicks = product.clicks
    }
  })

  return maxClicks
}

function calculateScore(product, maxClicks) {
  const clicksScore = (product.clicks / maxClicks) * 0.2
  const reviewScore = (product.reviewRating / 5) * 0.7
  const discountScore = product.hasDiscount ? 0.1 : 0
  return clicksScore + reviewScore + discountScore
}

export function getTopProducts(products, maxClicks) {
  let res = [...products]
  res = res.sort(
    (a, b) =>
      calculateScore(b, maxClicks) - calculateScore(a, maxClicks)
  )
  res = res.slice(0, 5)
  return res
}
