import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit'
import {
  addCountryRequest,
  getCountriesRequest,
  getCountryRequest,
  updateCountryRequest,
} from '../api/countries'

const initialState = {
  countries: [],
  loading: false,
  errorMessage: '',
  country: undefined,
}

export const fetchCountries = createAsyncThunk(
  'countries/getCountries',
  async (payload, thunkApi) => {
    try {
      const countries = await getCountriesRequest()
      return countries
    } catch (error) {
      console.log('fetchCountries' + error)
      throw error
    }
  },
)

export const addCountry = createAsyncThunk(
  'db-admin/addCountry',
  async (payload, thunkApi) => {
    try {
      const res = await addCountryRequest(payload)
      return res
    } catch (error) {
      console.log('addCountry' + error)
      throw error
    }
  },
)

export const updateCountry = createAsyncThunk(
  'db-admin/updateCountry',
  async (payload, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.token
      const res = await updateCountryRequest(payload)
      return res
    } catch (error) {
      console.log('updateCountry' + error)
      throw error
    }
  },
)
export const getCountry = createAsyncThunk(
  'db-admin/getCountry',
  async (payload, thunkApi) => {
    try {
      const res = await getCountryRequest(payload)
      return res
    } catch (error) {
      console.log('getCountry' + error)
      throw error
    }
  },
)

export const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchCountries.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(fetchCountries.fulfilled, (state, action) => {
      state.loading = false
      state.countries = action.payload
    })
    builder.addCase(fetchCountries.rejected, (state, action) => {
      state.loading = false
      state.errorMessage = action.payload
    })
    builder.addCase(addCountry.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(addCountry.fulfilled, (state, action) => {
      state.loading = false
      let list = [...current(state.countries), action.payload]

      state.countries = list
    })
    builder.addCase(addCountry.rejected, (state, action) => {
      state.loading = false
      state.errorMessage = action.payload
    })
    builder.addCase(updateCountry.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(updateCountry.fulfilled, (state, action) => {
      state.loading = false
      const index = state.countries.findIndex(
        (country) => (country.id = action.payload.id),
      )
      state.countries[index] = action.payload
    })
    builder.addCase(updateCountry.rejected, (state, action) => {
      state.loading = false
      state.errorMessage = action.payload
    })
  },
})

export default countriesSlice.reducer
