import React, { useEffect, useState } from "react"
import SliderImage from "react-bootstrap/Carousel"
import { useDispatch, useSelector } from "react-redux"
import { getImages } from "../../state/imagesSlice"

import styles from "./Carousel.module.scss"

export default function Carousel() {
  const dispatch = useDispatch()
  const [imageUrls, setImageUrls] = useState([])
  const [isMobile, setIsMobile] = useState(false)
  const { images } = useSelector((state) => state.images)

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true)
    }
    if (window.innerWidth >= 768) {
      setIsMobile(false)
    }
  }, [window.innerWidth])

  useEffect(() => {
    dispatch(getImages({ usage: "carousel" }))
  }, [dispatch])

  useEffect(() => {
    let urls = [...images]
    urls = urls.filter((image) => image.isMobile === isMobile)

    setImageUrls(urls)
  }, [images])

  return (
    <div className={styles.mainContainer}>
      <SliderImage
        style={{ borderRadius: 8 }}
        controls={false}
        interval={2500}>
        {Object.entries(imageUrls).map(([key, image]) => {
          return (
            <SliderImage.Item key={key}>
              <img
                className="d-block w-100"
                src={process.env.REACT_APP_BASE_URL + image.image_url}
                alt="First slide"
              />
            </SliderImage.Item>
          )
        })}
      </SliderImage>
    </div>
  )
}
