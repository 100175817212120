import React from 'react'

export default function Windows(props) {
  return (
    <svg {...props} viewBox="0 0 512 512">
      <g>
        <path
          d="M0 80v160h224V52zM256 48v192h256V16zM256 272v192l256 32V272zM0 272v160l224 28V272z"
          fill="#2b2b2b"
        ></path>
      </g>
    </svg>
  )
}
