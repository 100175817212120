import React from 'react'
import styles from './SmallerScreen.module.scss'

export default function SmallerScreen(props) {
  const [selected, setSelected] = React.useState(props.selected)

  return (
    <div className={styles.main}>
      <div className={styles.main_image}>
        <img
          className={styles.image}
          src={process.env.REACT_APP_BASE_URL + selected}
        />
      </div>
      <div className={styles.main_low_container}>
        {Object.entries(props.images).map(([key, image]) => {
          return (
            <div key={key} className={styles.low_image_container}>
              <img src={process.env.REACT_APP_BASE_URL + image} />
              {selected !== image && (
                <div
                  onClick={() => setSelected(image)}
                  className={styles.blur}
                />
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}
