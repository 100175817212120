import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {
  contactUsRequest,
  getUserDataRequest,
  loginRequest,
  registerRequest,
  resetUserData,
  updateUserDataRequest,
} from "../api/users"

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  token: "",
  user_id: "",
  isLoading: true,
  errorMessage: "",
  verified: false,
  type: "",
  country: "",
  currentCountry: "",
  accessLevel: "",
}

export const login = createAsyncThunk(
  "users/login",
  async (payload, thunkAPI) => {
    try {
      const userData = await loginRequest(payload)

      return userData
    } catch (error) {
      if (error?.response?.data?.message) {
        return thunkAPI.rejectWithValue({
          error: error.response.data.message,
        })
      }
      return thunkAPI.rejectWithValue({ error: "Unknown error" })
    }
  }
)

export const register = createAsyncThunk(
  "users/register",
  async (payload, thunkAPI) => {
    try {
      return await registerRequest(payload)
    } catch (error) {
      if (error?.response?.data?.message) {
        return thunkAPI.rejectWithValue({
          error: error.response.data.message,
        })
      }
      return thunkAPI.rejectWithValue({ error: "Unknown error" })
    }
  }
)

export const getUserData = createAsyncThunk(
  "users/fetch",
  async (payload, thunkAPI) => {
    try {
      var userData = await getUserDataRequest()

      return userData
    } catch (error) {
      if (error?.response?.data?.message) {
        return thunkAPI.rejectWithValue({
          error: error.response.data.message,
        })
      }
      return thunkAPI.rejectWithValue({ error: "Unknown error" })
    }
  }
)

export const updateUserData = createAsyncThunk(
  "users/update",
  async (payload, thunkAPI) => {
    try {
      var userData = await updateUserDataRequest(payload)

      return userData
    } catch (error) {
      console.log("UPDATE USER ERROR" + error)
      throw error
    }
  }
)

export const contactUs = createAsyncThunk(
  "users/contact",
  async (payload, thunkAPI) => {
    try {
      const res = await contactUsRequest(payload)
      return res
    } catch (error) {
      throw error
    }
  }
)

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout(state) {
      localStorage.removeItem("cart_key")
      localStorage.removeItem("offersId")
      const storageEvent = new Event("cart_key")
      window.dispatchEvent(storageEvent)
      resetUserData()
      return initialState
    },
    setActualCountry(state, value) {
      state.currentCountry = value.payload
      state.isLoading = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state, action) => {
      state.isLoading = true
    })

    builder.addCase(login.fulfilled, (state, action) => {
      state.token = action.payload.token
      state.email = action.payload.user.email
      state.firstName = action.payload.user.firstName
      state.lastName = action.payload.user.lastName
      state.user_id = action.payload.user._id
      state.isLoading = false
      state.verified = action.payload.user.verified
      state.type = action.payload.user.type
      state.country = action.payload.user.country
      state.errorMessage = ""
      state.accessLevel = action.payload.user.accessLevel
    })

    builder.addCase(login.rejected, (state, action) => {
      state.errorMessage = action.payload.error
      state.isLoading = false
    })
    builder.addCase(register.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(register.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.payload.error
    })
    builder.addCase(register.fulfilled, (state, action) => {
      state.token = action.payload.token
      state.email = action.payload.user.email
      state.firstName = action.payload.user.firstName
      state.lastName = action.payload.user.lastName
      state.user_id = action.payload.user._id
      state.isLoading = false
      state.verified = action.payload.user.verified
      state.type = action.payload.user.type
      state.country = action.payload.user.country
      state.errorMessage = ""
      state.accessLevel = action.payload.user.accessLevel
    })
    builder.addCase(getUserData.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getUserData.fulfilled, (state, action) => {
      if (action.payload) {
        state.token = action.payload.token
        state.email = action.payload.user.email
        state.firstName = action.payload.user.firstName
        state.lastName = action.payload.user.lastName
        state.user_id = action.payload.user._id
        state.isLoading = false
        state.verified = action.payload.user.verified
        state.type = action.payload.user.type
        state.country = action.payload.user.country
        state.errorMessage = ""
        state.accessLevel = action.payload.user.accessLevel
      }
    })
    builder.addCase(getUserData.rejected, (state, action) => {
      state.isLoading = false
    })
    builder.addCase(updateUserData.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(updateUserData.fulfilled, (state, action) => {
      state.isLoading = false
      state.firstName = action.payload.user.firstName
      state.lastName = action.payload.user.lastName
      state.email = action.payload.user.email
      state.country = action.payload.user.country
      state.type = action.payload.user.type
      state.accessLevel = action.payload.user.accessLevel
    })
    builder.addCase(updateUserData.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.payload.error
    })

    builder.addCase(contactUs.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(contactUs.fulfilled, (state, action) => {
      state.isLoading = false
    })
    builder.addCase(contactUs.rejected, (state, action) => {
      state.isLoading = false
    })
  },
})

export const { logout, setActualCountry } = authSlice.actions
export default authSlice.reducer
