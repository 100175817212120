import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import UserCard from "../../../components/admin/userCard/UserCard"
import NotFound from "../../../icons/NotFound"
import { fetchUsers } from "../../../state/adminSlice"
import styles from "./UsersManager.module.scss"
import CircularProgress from "@mui/joy/CircularProgress"
import Header from "../../../components/admin/header/Header"
import Input from "../../../components/custom/input/Input"
import TransInput from "../../../components/custom/transInput/TransInput"
import { updateUserData } from "../../../state/authSlice"
import countries from "../../../helpers/Countries"

export default function UsersManager() {
  const { users, isLoading } = useSelector((state) => state.admin)
  const [name, setName] = useState("")
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchUsers())
  }, [dispatch])

  let admin
  console.log(users)
  if (users.length > 0) {
    admin = users.filter((user) => user.accessLevel === "admin")
  }

  let commonUsers
  if (users.length > 0) {
    commonUsers = users.filter(
      (user) =>
        user.accessLevel !== "admin" && user.accessLevel !== "super-admin"
    )
  }
  if (name !== "") {
    commonUsers = commonUsers.filter((user) => {
      const firstName = user.firstName.toLowerCase()
      const lastName = user.lastName.toLowerCase()
      const fullName = firstName + " " + lastName
      const nameToSearch = name.toLowerCase()
      return fullName.includes(nameToSearch)
    })
  }
  // changing the type of user (admin)
  function changeType(type, id) {
    dispatch(updateUserData({ type, id }))
  }

  function changeCountry(country, id) {
    dispatch(updateUserData({ country, id }))
  }

  return (
    <section id={"mainContainer"}>
      <h1>User Management</h1>
      {isLoading ? (
        <div>
          <CircularProgress color="danger" />
        </div>
      ) : users.length === 0 ? (
        <div className={styles.notFound}>
          <NotFound />
          <h5>No Users Found</h5>
        </div>
      ) : (
        <>
          <h3>Admin</h3>
          {Object.entries(admin).map(([key, value]) => {
            return (
              <div key={key} className={styles.adminCard}>
                <div className={styles.entry}>
                  <p>Email</p>
                  <Input disabled={true} value={value.email} />
                </div>
                <div className={styles.entry}>
                  <p>First Name</p>
                  <Input disabled={true} value={value.firstName} />
                </div>
                <div className={styles.entry}>
                  <p>Last Name</p>
                  <Input disabled={true} value={value.lastName} />
                </div>
                <div className={styles.entry}>
                  <p>View as</p>
                  <select
                    onChange={(e) => changeType(e.target.value, value._id)}
                    value={value.type}
                    className={styles.dropDown}
                  >
                    <option value={"Retailer"}>Retailer</option>
                    <option value={"Distributor"}>Distributor</option>
                    <option value={"Base User"}>Base User</option>
                    <option value={"Corporate"}>Corporate</option>
                  </select>
                </div>
                <div className={styles.entry}>
                  <p>Country</p>
                  <select
                    onChange={(e) =>
                      // changeType(e.target.value, value._id)
                      {
                        changeCountry(e.target.value, value._id)
                      }
                    }
                    value={value.country}
                    className={styles.dropDown}
                  >
                    {Object.entries(countries).map(([key, items]) => {
                      return (
                        <option key={key} value={items.name}>
                          {items.name}
                        </option>
                      )
                    })}
                  </select>
                </div>
                <div className={styles.entry}>
                  <p>ID-key</p>
                  <Input
                    style={{ paddingRight: "80%" }}
                    disabled={true}
                    value={value._id}
                  />
                </div>
              </div>
            )
          })}
          <h3>Users</h3>
          <div className={styles.mainList}>
            <div className={styles.search_container}>
              <div className={styles.search_input}>
                <TransInput
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Search"
                />
              </div>
            </div>
            <Header
              categories={[
                "Country",
                "Email",
                "Name",
                "Phone number",
                "Company Name",
                "Type",
                "Actions",
              ]}
            />
            <div className={styles.cardList}>
              {commonUsers.map((u) => {
                const firstName =
                  u.firstName.charAt(0).toUpperCase() + u.firstName.slice(1)
                const lastName =
                  u.lastName.charAt(0).toUpperCase() + u.lastName.slice(1)
                const name = firstName + " " + lastName

                return (
                  <UserCard
                    key={u._id}
                    verified={u.verified}
                    country={u.country}
                    accessLevel={u.accessLevel}
                    type={u.type}
                    name={name}
                    email={u.email}
                    phone={u.phoneNumber}
                    company={u.companyName}
                    id={u._id}
                  />
                )
              })}
            </div>
          </div>
        </>
      )}
    </section>
  )
}
