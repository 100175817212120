import React from "react"

export default function Cart(props) {
  return (
    <svg
      onClick={props.onClick}
      width={props.size || "512"}
      height={props.size || "512"}
      viewBox="0 0 32 32">
      <g>
        <path
          d="M23.8 30H8.2c-3.3 0-6-2.7-6-6v-.2l.6-16C2.9 4.5 5.6 2 8.8 2h14.4c3.2 0 5.9 2.5 6 5.8l.6 16c.1 1.6-.5 3.1-1.6 4.3S25.6 30 24 30h-.2zM8.8 4c-2.2 0-3.9 1.7-4 3.8L4.2 24c0 2.2 1.8 4 4 4H24c1.1 0 2.1-.5 2.8-1.3s1.1-1.8 1.1-2.9l-.6-16c-.1-2.2-1.8-3.8-4-3.8z"
          fill={props.fill || "#000000"}></path>
        <path
          d="M16 14c-3.9 0-7-3.1-7-7 0-.6.4-1 1-1s1 .4 1 1c0 2.8 2.2 5 5 5s5-2.2 5-5c0-.6.4-1 1-1s1 .4 1 1c0 3.9-3.1 7-7 7z"
          fill={props.fill || "#000000"}></path>
      </g>
    </svg>
  )
}
