import React from "react"
import styles from "./HorizontalList.module.scss"
import ProductItem from "../productItem/ProductItem"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { incrementClicked } from "../../../state/productSlice"
import { getShowPrice } from "../../../helpers/Products"

export default function HorizontalList({ products }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  return (
    <div className={styles.recommendation}>
      <h3>Similar Products</h3>
      <div className={styles.list}>
        {Object.entries(products).map(([key, p]) => {
          return (
            <div key={key} className={styles.object}>
              <ProductItem
                key={key}
                product={p}
                onClick={() => {
                  dispatch(incrementClicked(p._id))
                  navigate(
                    `/products/${p.category}/${p.subCategory}/${p.title}/${p._id}`
                  )
                  window.location.reload()
                }}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}
