import React from "react"
import Search from "../../../icons/Search"
import styles from "./Input.module.scss"
import MailchimpSubscribe from "react-mailchimp-subscribe"

export default function Input(props) {
  return (
    <div className={styles.width}>
      <div
        className={
          props.isleading
            ? styles.L_Input
            : props.errors
            ? styles.error
            : styles.inputs
        }>
        {props.isleading && <Search />}
        <input {...props} autoCorrect="false" spellCheck="false" />
      </div>
    </div>
  )
}

export function NewsletterForm() {
  return (
    <div className={styles.mail_chimp}>
      <div>
        <link
          href="https://d2hphlhl5wdf3o.cloudfront.net/client-108851/subscription-form/a31ce247f376464fbc31b66e951631c7/script.css"
          rel="stylesheet"
        />
        <div
          is="diq-div"
          id="diq-container-a31ce247f376464fbc31b66e951631c7"></div>
        <script src="https://d2hphlhl5wdf3o.cloudfront.net/client-108851/subscription-form/a31ce247f376464fbc31b66e951631c7/script.js"></script>
      </div>
    </div>
  )
}
