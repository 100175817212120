import React from "react"
import Title from "../../../components/admin/title/Title"
import styles from "./Categories.module.scss"
import { useDispatch, useSelector } from "react-redux"
import Card from "../../../components/admin/categories/Card/Card"
import Add from "../../../icons/Add"
import { addCategory } from "../../../state/categoriesSlice"

export default function Categories() {
  const { categories } = useSelector((state) => state.categories)
  const dispatch = useDispatch()
  return (
    <section id="mainContainer">
      <Title>Categories</Title>
      <main className={styles.main}>
        <div className={styles.cM_b}>
          <div className={styles.cM_b_h}>
            <p>Category</p>
            <p>Is Visible</p>
            <p>Number of Items</p>
            <p>SubCategories</p>

            <div style={{ width: "20px" }} />
          </div>
          {Object.entries(categories).map(([key, value]) => {
            return <Card value={value} key={key} />
          })}
          <div
            style={{
              flex: 1,
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Add
              onClick={() => dispatch(addCategory())}
              fill="grey"
              size={25}
            />
          </div>
        </div>
      </main>
    </section>
  )
}
