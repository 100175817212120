import React, { useEffect, useState } from "react"
import styles from "./Features.module.scss"
import Add from "../../../../icons/Add"
import TransInput from "../../../custom/transInput/TransInput"
import Remove from "../../../../icons/Remove"

export default function Features(props) {
  const [inputs, setInputs] = useState([""])

  const handleInputChange = (index, value) => {
    const newInputs = [...inputs]
    newInputs[index] = value
    setInputs(newInputs)
    props.onChange(newInputs)
  }

  const handleDeleteInput = (index) => {
    const newInputs = [...inputs]
    newInputs.splice(index, 1)
    setInputs(newInputs)
    props.onChange(newInputs)
  }

  useEffect(() => {
    if (props.value !== undefined) {
      setInputs(props.value)
    } else {
      setInputs([""])
    }
  }, [props.value])

  const renderInput = (input, index) => {
    return (
      <div key={index} style={{ display: "flex" }}>
        <li>
          <TransInput
            placeholder="Enter the feature"
            value={input}
            onChange={(e) => handleInputChange(index, e.target.value)}
            type="text"
          />
        </li>
        <Remove onClick={() => handleDeleteInput(index)} />
      </div>
    )
  }

  return (
    <ul className={styles.featuresList}>
      {inputs?.map(renderInput)}

      <div className={styles.addContainer}>
        <Add
          onClick={() => {
            const newInputs = [...inputs, ""]
            setInputs(newInputs)
            props.onChange(newInputs)
          }}
          fill="#b2b2b2"
          size={25}
        />
      </div>
    </ul>
  )
}
