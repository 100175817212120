import React from "react"
import styles from "./Card.module.scss"

import { getTimeAgo } from "../../../helpers/Orders"
import Options from "../../../icons/Options"
import Status from "./status/Status"

export default function Card({ order, toggle }) {
  const timeAgo = getTimeAgo(new Date(order.orderDate))

  return (
    <div className={styles.oM_b_c}>
      <p>#{order.orderId}</p>
      <p>{timeAgo}</p>
      <p>{order.address.emailAddress}</p>
      <p>{order.userPosition}</p>
      <p>{order.address.country}</p>
      <p>$ {order.totalPrice}</p>
      <div className={styles.card}>
        <Status status={order.orderStatus} />
      </div>

      <Options onClick={() => toggle(order)} fill="grey" />
    </div>
  )
}
