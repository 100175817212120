import React, { useEffect, useState } from "react"
import styles from "./ProductItem.module.scss"

import { useSelector } from "react-redux"

import SoldOut from "../../../icons/states/SoldOut"
import OnOrder from "../../../icons/states/OnOrder"
import ComingSoon from "../../../icons/states/ComingSoon"

import { ShimmerThumbnail } from "react-shimmer-effects"
import countries from "../../../helpers/Countries"

export default function ProductItem(props) {
  const { type, currentCountry, token } = useSelector(
    (state) => state.auth
  )
  const { showPrice } = useSelector((state) => state.product)
  const [isLoading, setIsLoading] = useState(false)
  const [isEU, setIsEU] = useState(false)
  const product = props.product
  const price = product.price

  function getState() {
    if (product.state === "none" || product.state === "stay tuned") {
      return false
    }
    return product.state
  }

  function renderStateIcon() {
    if (getState() === "sold out") {
      return (
        <div className={styles.product_sold_out}>
          <SoldOut size={50} />
        </div>
      )
    } else if (getState() === "on order") {
      return (
        <div className={styles.product_sold_out}>
          <OnOrder size={50} />
        </div>
      )
    } else if (getState() === "coming soon") {
      return (
        <div className={styles.product_sold_out}>
          <ComingSoon size={50} />
        </div>
      )
    }
  }

  useEffect(() => {
    countries.map((country) => {
      if (country.name === currentCountry && country?.eu === true) {
        setIsEU(true)
      }
    })
  }, [currentCountry])

  function showPriceComponent() {
    if (product.isCall) {
      return <p>Call for price</p>
    }
    if (showPrice && price) {
      return (
        <p
          className={
            product.discount !== 0
              ? styles.discounted
              : styles.product_price
          }>
          {isEU ? "€" : "$"}
          {price - (price * product.discount) / 100}
        </p>
      )
    } else return <></>
  }

  return (
    <div onClick={props.onClick} className={styles.product}>
      <div className={styles.product_content}>
        {product.discount !== 0 && (
          <div className={styles.product_discount}>
            <p className={styles.discount}>{product.discount}% OFF</p>
          </div>
        )}
        {renderStateIcon()}

        <div
          className={
            getState() ? styles.product_img_out : styles.product_img
          }>
          {isLoading ? (
            <ShimmerThumbnail height={"100%"} />
          ) : (
            <img
              src={
                process.env.REACT_APP_BASE_URL +
                product.image_collection[0]
              }
              alt={product.title}
              loading="lazy"
              onLoad={(e) => {
                setIsLoading(false)
              }}
            />
          )}
        </div>
        <div className={styles.info}>
          <p className={styles.product_name}>{product.title}</p>
          <p className={styles.product_cat}>{product.subCategory}</p>
        </div>
      </div>

      <div className={styles.product_info}>
        {/* {isSoldOut() && <p className={styles.sold_out}>Sold Out</p>}
        {product.discount !== 0 && showPrice && price && (
          <p className={styles.discounted_o}>${price}</p>
        )} */}
        {showPriceComponent()}
      </div>
    </div>
  )
}
