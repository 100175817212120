import React from "react"

export default function Skype(props) {
  return (
    <a href={props.href} target="_blank">
      <svg
        style={{ cursor: "pointer" }}
        {...props}
        width={props.size || "512"}
        height={props.size || "512"}
        viewBox="0 0 291.364 291.364">
        <g>
          <path
            d="M282.966 176.612a136.24 136.24 0 0 0 3.204-29.405c0-75.661-62.197-137.011-138.941-137.011-8.093 0-16.032.692-23.752 2.003C111.061 4.479 96.367 0 80.618 0 36.101 0 .032 35.577.032 79.475c0 14.657 4.051 28.385 11.07 40.193a135.273 135.273 0 0 0-2.813 27.539c0 75.688 62.197 134.753 138.932 134.753 8.694 0 17.197-.792 25.436-2.294 11.352 6.008 24.316 11.698 38.099 11.698 44.508 0 80.577-35.568 80.577-79.466a78.266 78.266 0 0 0-8.367-35.286z"
            fill="#00aff0"></path>
          <path
            d="M210.6 203.131c-5.362 7.62-13.21 13.61-23.542 17.916-10.333 4.342-22.568 6.491-36.724 6.491-16.978 0-30.98-2.95-42.023-8.858-7.811-4.242-14.174-9.923-19.072-17.006-4.88-7.083-7.328-13.983-7.328-20.693 0-3.878 1.466-7.21 4.397-9.969 2.931-2.795 6.673-4.169 11.198-4.169 3.678 0 6.8 1.065 9.331 3.241 2.549 2.158 4.725 5.353 6.509 9.604 2.194 5.053 4.552 9.268 7.11 12.645 2.531 3.377 6.118 6.181 10.724 8.375 4.625 2.176 10.697 3.286 18.198 3.286 10.324 0 18.717-2.203 25.181-6.637 6.445-4.415 9.686-9.959 9.686-16.578 0-5.244-1.748-9.532-5.226-12.782-3.487-3.277-7.984-5.781-13.501-7.501-5.508-1.739-12.882-3.578-22.131-5.517-12.354-2.649-22.705-5.781-31.025-9.331-8.33-3.578-14.957-8.43-19.837-14.575-4.898-6.172-7.338-13.819-7.338-22.95 0-8.721 2.576-16.45 7.747-23.214 5.162-6.773 12.618-11.971 22.413-15.613 9.777-3.641 21.285-5.453 34.494-5.453 10.56 0 19.7 1.211 27.411 3.605 7.711 2.422 14.093 5.626 19.172 9.623 5.089 3.997 8.812 8.184 11.152 12.581 2.349 4.37 3.523 8.685 3.523 12.836 0 3.814-1.457 7.274-4.388 10.324-2.931 3.059-6.591 4.597-10.97 4.597-3.997 0-7.028-.929-9.104-2.758-2.076-1.839-4.324-4.843-6.737-9.022-3.141-5.972-6.882-10.633-11.27-13.974-4.388-3.35-11.416-5.025-21.121-5.025-8.985 0-16.25 1.803-21.767 5.453-5.517 3.623-8.284 8.011-8.284 13.118 0 3.15.956 5.899 2.822 8.202 1.866 2.321 4.452 4.279 7.756 5.927a55.661 55.661 0 0 0 9.959 3.905c3.359.938 8.922 2.294 16.678 4.097 9.686 2.085 18.471 4.397 26.337 6.91 7.857 2.522 14.557 5.59 20.074 9.167 5.508 3.605 9.823 8.175 12.909 13.692 3.086 5.499 4.643 12.244 4.643 20.238.003 9.56-2.691 18.163-8.036 25.792z"
            fill="#ffffff"></path>
        </g>
      </svg>
    </a>
  )
}
