import React from 'react'
import styles from './Alert.module.scss'

export default function Alert({
  cancel,
  confirm,
  children,
  confirmText,
  cancelText,
  heading,
  description,
}) {
  return (
    <div className={styles.alert_overlay}>
      <div className={styles.alert_container}>
        <h3>{heading}</h3>
        <p>{description}</p>
        <>{children}</>
        <div className={styles.sep} />
        <div className={styles.buttons_container}>
          <div onClick={cancel} className={styles.container}>
            {cancelText}
          </div>
          <div onClick={confirm} className={styles.container_s}>
            {confirmText}
          </div>
        </div>
      </div>
    </div>
  )
}
