import React from 'react'

export default function NotVisible(props) {
  return (
    <svg
      {...props}
      cursor={'pointer'}
      width="20"
      height="20"
      viewBox="0 0 32 32"
    >
      <g>
        <path
          d="M1.13 15.51a1 1 0 0 0 0 .98 22.057 22.057 0 0 0 5.76 6.38l3.9-3.9a6 6 0 0 1 8.18-8.18l3.48-3.48A15.994 15.994 0 0 0 16 6C6.52 6 1.34 15.13 1.13 15.51zM30.91 15.59a17.691 17.691 0 0 0-3.45-4.81 13.914 13.914 0 0 0-1.49-1.33l3.74-3.74a1 1 0 1 0-1.42-1.42L24.3 8.28l-3.78 3.78-1.42 1.42-5.62 5.62-1.42 1.42-3.46 3.46-4.31 4.31a1.008 1.008 0 0 0 0 1.42 1.037 1.037 0 0 0 1.42 0l4.79-4.79a17.68 17.68 0 0 0 2.13.69A14.549 14.549 0 0 0 16 26c9.48 0 14.66-9.13 14.87-9.51a.987.987 0 0 0 .04-.9zm-8.916.66a6.2 6.2 0 0 1-4.613 5.56A5.507 5.507 0 0 1 16 22a5.778 5.778 0 0 1-2.17-.41l1.63-1.63A3.272 3.272 0 0 0 16 20a4 4 0 0 0 4-4 3.272 3.272 0 0 0-.04-.54l1.63-1.63A5.778 5.778 0 0 1 22 16c0 .084 0 .167-.006.25z"
          fill={props.fill}
        ></path>
        <path
          d="M12 16a3.779 3.779 0 0 0 .29 1.47l5.18-5.18A3.779 3.779 0 0 0 16 12a4 4 0 0 0-4 4z"
          fill={props.fill}
        ></path>
      </g>
    </svg>
  )
}
