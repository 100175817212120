import React from 'react'
import styles from './TransInput.module.scss'

export default function TransInput(props) {
  return (
    <input
      {...props}
      className={
        props.class ? props.class : props.wide ? styles.title : styles.input
      }
    />
  )
}
