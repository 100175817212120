import React from 'react'

export default function Computer(props) {
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 512 512">
      <g>
        <path
          d="M476 48H36A28.031 28.031 0 0 0 8 76v296a28.031 28.031 0 0 0 28 28h162.24l-4.8 24H160a8 8 0 0 0-8 8v24a8 8 0 0 0 8 8h192a8 8 0 0 0 8-8v-24a8 8 0 0 0-8-8h-33.44l-4.8-24H476a28.031 28.031 0 0 0 28-28V76a28.031 28.031 0 0 0-28-28ZM344 440v8H168v-8Zm-134.24-16 4.8-24h82.88l4.8 24ZM488 372a12.01 12.01 0 0 1-12 12H36a12.01 12.01 0 0 1-12-12v-20h464Zm0-36H24V76a12.01 12.01 0 0 1 12-12h440a12.01 12.01 0 0 1 12 12Z"
          fill="#000000"
        ></path>
        <path
          d="M264 360h-16a8 8 0 0 0 0 16h16a8 8 0 0 0 0-16Z"
          fill="#000000"
        ></path>
      </g>
    </svg>
  )
}
