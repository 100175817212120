import cities from "./Cities"
export function getCities(country) {
  let result = []
  cities.forEach((city) => {
    if (city.country_name === country) {
      result.push(city)
    }
  })
  return result
}

export function getTimeAgo(date) {
  const currentTime = new Date()

  const timeDifference = currentTime - date

  // Time units in milliseconds
  const minute = 60 * 1000
  const hour = 60 * minute
  const day = 24 * hour
  const week = 7 * day
  const month = 30 * day
  const year = 365 * day

  if (timeDifference < minute) {
    const seconds = Math.round(timeDifference / 1000)
    return `${seconds} second${seconds !== 1 ? "s" : ""} ago`
  } else if (timeDifference < hour) {
    const minutes = Math.round(timeDifference / minute)
    return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`
  } else if (timeDifference < day) {
    const hours = Math.round(timeDifference / hour)
    return `${hours} hour${hours !== 1 ? "s" : ""} ago`
  } else if (timeDifference < week) {
    const days = Math.round(timeDifference / day)
    return `${days} day${days !== 1 ? "s" : ""} ago`
  } else if (timeDifference < month) {
    const weeks = Math.round(timeDifference / week)
    return `${weeks} week${weeks !== 1 ? "s" : ""} ago`
  } else if (timeDifference < year) {
    const months = Math.round(timeDifference / month)
    return `${months} month${months !== 1 ? "s" : ""} ago`
  } else {
    const years = Math.round(timeDifference / year)
    return `${years} year${years !== 1 ? "s" : ""} ago`
  }
}

export function formatDate(dateObj) {
  const options = { day: "numeric", month: "long", year: "numeric" }
  const formattedDate = dateObj.toLocaleDateString(undefined, options)
  return formattedDate
}
