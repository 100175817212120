import React, { useState, useEffect, useRef } from "react"
import { Button } from "react-bootstrap"
import { useForm, Controller } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"

import { formatNumber } from "../../../helpers/Formats"

import {
  addProduct,
  reactivateLoading,
  resetProduct,
  updateProduct,
} from "../../../state/productSlice"

import TransInput from "../../custom/transInput/TransInput"
import styles from "./AddProducts.module.scss"
import Categories from "./categories/Categories"
import Features from "./features/Features"
import Image from "./image/Image"
import Warranty from "./waranty/Warranty"
import CountriesSelector from "../countries/CountriesSelector"
import BrandList from "./brands/BrandList"
import Loading from "react-loading"
import PriceGroupes from "./prices/priceGroupes/PriceGroupes"
import Link from "../../../icons/Link"

export default function AddProduct(props) {
  const { components, product, isLoading, errorMessage } = useSelector(
    (state) => state.product
  )
  const dispatch = useDispatch()
  const [subCat, setSubCat] = useState([])
  const [visible, setVisible] = useState(false)
  const { categories } = useSelector((state) => state.categories)
  const [groupIndex, setGroupIndex] = useState(0)
  function onOpen(index) {
    setGroupIndex(index)
    setVisible(true)
  }

  function getSub(title) {
    Object.entries(categories).forEach(([key, e]) => {
      if (e.name === title) {
        setSubCat(e.subCategories)
      }
    })
  }
  const { control, handleSubmit, formState, getValues, setValue } = useForm({
    defaultValues: {
      title: "",
      description: "",
      category: "",
      subCategory: "",
      imageFiles: [],
      imageLinks: [],
      features: undefined,
      warranty: [0, "years"],
      pricingGroupes: [],
      properties: [],
      brand: "",
      metaTitle: "",
      metaKeywords: "",
      metaDescription: "",
    },
  })
  let isValid = formState.isValid
  const [number, setNumber] = useState(product ? product.basePrice : 0)
  const [cat, setCat] = useState("")

  useEffect(() => {
    if (!props.update) {
      const productData = localStorage.getItem("product_info")
      if (productData) {
        const data = JSON.parse(productData)
        setValue("title", data.title)
        setValue("description", data.description)
        setValue("category", data.category)
        setValue("subCategory", data.subCategory)
        setValue("imageFiles", data.imageFiles)
        setValue("features", data.features)
        setValue("warranty", data.warranty)
        setValue("pricingGroupes", data.pricingGroupes)
        setValue("properties", data.properties)
        setValue("brand", data.brand)
        setValue("metaTitle", data.metaTitle)
        setValue("metaKeywords", data.metaKeywords)
        setValue("metaDescription", data.metaDescription)
        setCat(data.category)
      }
    } else {
      if (product) {
        setValue("title", product.title)
        setValue("description", product.description)
        setValue("category", product.category)
        setValue("subCategory", product.subCategory)
        setValue("imageLinks", product.image_collection)
        setValue("features", product.features)
        setValue("warranty", product.warranty)
        setValue("pricingGroupes", product.pricingGroupes)
        setValue("properties", product.components)
        setValue("brand", product.brand)
        setValue("metaTitle", product.metaTitle)
        setValue("metaKeywords", product.metaKeywords)
        setValue("metaDescription", product.metaDescription)
        setCat(product.category)
      }
    }
  }, [])

  useEffect(() => {
    if (cat !== "") {
      getSub(cat)
    }
    if (number !== 0) {
      formatNumber(null, setNumber, number)
    }
  }, [cat, number])

  const onSubmit = (data) => {
    const payload = {
      product: {
        prodId: product ? product._id : null,
        title: data.title,
        category: data.category,
        description: data.description,
        features: data.features,
        warranty: data.warranty,
        components: components,
        imageFiles: data.imageFiles,
        subCategory: data.subCategory,
        pricingGroupes: data.pricingGroupes,
        properties: data.properties,
        isUpdate: props.update,
        brand: data.brand,
        imageLinks: data.imageLinks,
        metaTitle: data.metaTitle,
        metaKeywords: data.metaKeywords,
        metaDescription: data.metaDescription,
      },
    }

    dispatch(props.update ? updateProduct(payload) : addProduct(payload)).then(
      (e) => {
        props.setUpdate(false)
        dispatch(resetProduct())
        dispatch(reactivateLoading())
        const status = e.meta.requestStatus
        if (status === "fulfilled") {
          localStorage.removeItem("product_info")
          props.added(false)
        }
      }
    )
  }

  function countryPrices(e) {
    let newValue = [...getValues("pricingGroupes")]
    newValue[groupIndex].countries = e
    setValue("pricingGroupes", newValue)
  }

  return (
    <div className={styles.main}>
      {visible && (
        <CountriesSelector
          value={getValues("pricingGroupes")[groupIndex].countries}
          onSave={countryPrices}
          cancel={() => setVisible(false)}
        />
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          width: "100%",
        }}
      >
        <a
          href={`https://compuonelb.com/${product?.urlIdentifier}`}
          target="_blank"
        >
          <Link size="20" fill="#000" />
        </a>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ width: "100%", display: "contents" }}
      >
        <div className={styles.header}>
          {/* Title */}
          <Controller
            rules={{ required: "You must enter a title" }}
            control={control}
            name="title"
            render={({ field: { onBlur, onChange, value } }) => {
              return (
                <TransInput
                  onChange={(e) => {
                    onChange(e)
                  }}
                  onBlur={onBlur}
                  value={value}
                  placeholder="Title"
                  wide="true"
                />
              )
            }}
          />

          {/* Category */}

          <Controller
            rules={{ required: "You must specify the category" }}
            control={control}
            name="category"
            render={({ field: { onBlur, onChange, value } }) => {
              return (
                <Categories
                  value={value}
                  list={categories}
                  isCat="true"
                  onChildData={(e) => {
                    getSub(e)
                    onChange(e)
                    setCat(e)
                  }}
                />
              )
            }}
          />

          {/* Sub Category */}

          {cat === "" || (
            <Controller
              rules={{ required: "You must specfy the sub category" }}
              control={control}
              name="subCategory"
              render={({ field: { onBlur, onChange, value } }) => {
                return (
                  <Categories
                    value={value}
                    list={subCat}
                    onChildData={(e) => {
                      onChange(e)
                    }}
                  />
                )
              }}
            />
          )}
        </div>
        <div className={styles.body}>
          <div className={styles.first}>
            {/* Image */}

            <Controller
              control={control}
              name="imageFiles"
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <Image
                    size={product ? product.image_collection.length : 0}
                    value={value}
                    images={product ? product.image_collection : []}
                    onChangeFile={(e) => {
                      onChange(e)
                    }}
                    onChangeLink={(e) => setValue("imageLinks", e)}
                  />
                )
              }}
            />
            <div className={styles.description}>
              <h3>Description:</h3>
              {/* Description */}

              <Controller
                rules={{
                  required: "You must provide a description to your product.",
                }}
                control={control}
                name="description"
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <textarea
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      className={styles.multilineInput}
                      placeholder="Description"
                    />
                  )
                }}
              />
            </div>

            <h3>SEO - Meta Content</h3>

            <Controller
              control={control}
              name="metaTitle"
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <>
                    <TransInput
                      onChange={(e) => {
                        onChange(e)
                      }}
                      onBlur={onBlur}
                      value={value}
                      placeholder="Meta Title"
                    />
                    <p className={styles.counter}>{value.length}/60</p>
                  </>
                )
              }}
            />
            <div style={{ height: 20 }} />
            <Controller
              control={control}
              name="metaKeywords"
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <TransInput
                    onChange={(e) => {
                      onChange(e)
                    }}
                    onBlur={onBlur}
                    value={value}
                    placeholder="Meta Keywords: lebanon, pos, ..."
                  />
                )
              }}
            />
            <Controller
              control={control}
              name="metaDescription"
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <>
                    <textarea
                      value={value}
                      onBlur={onBlur}
                      onChange={(e) => {
                        onChange(e)
                      }}
                      className={styles.metaDescription}
                      placeholder="Meta Description"
                    />
                    <p className={styles.counter}>{value.length}/120</p>
                  </>
                )
              }}
            />
          </div>
          <div className={styles.second}>
            <h4 className={styles.prices}>$ Min and Max $</h4>
            {/* Features */}

            <Controller
              rules={{
                required: {
                  value: true,
                  message: "You need to add features",
                },
                validate: () => {
                  let values = getValues("features")
                  let length = values.length
                  let valid = true
                  for (let index = 0; index < length; index++) {
                    if (values[index].length === 0) {
                      valid = false
                    }
                  }

                  return valid
                },
              }}
              control={control}
              name="features"
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <Features
                    value={value}
                    onChange={(e) => {
                      onChange(e)
                    }}
                  />
                )
              }}
            />
            {/* Warranty */}

            <Controller
              rules={{ required: false }}
              control={control}
              name="warranty"
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <Warranty
                    value={value}
                    onChange={(e) => {
                      onChange(e)
                    }}
                  />
                )
              }}
            />
            {/* Properties */}
            {/* <Controller
              name="properties"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <Properties value={value} onChange={onChange} />
                )
              }}
            /> */}

            <Controller
              control={control}
              name="pricingGroupes"
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <PriceGroupes
                    onChange={onChange}
                    value={value}
                    onOpen={(e) => onOpen(e)}
                  />
                )
              }}
            />

            <div className={styles.brands}>
              <h3 className={styles.brands_header}>Brands</h3>
              <Controller
                rules={{ required: true }}
                name="brand"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => {
                  return <BrandList value={value} onChange={onChange} />
                }}
              />
            </div>
          </div>
        </div>
        <div className={styles.btns}>
          <Button
            onClick={() => {
              const res = JSON.stringify({
                title: getValues("title"),
                description: getValues("description"),
                category: getValues("category"),
                subCategory: getValues("subCategory"),
                imageFiles: getValues("imageFiles"),
                features: getValues("features"),
                warranty: getValues("warranty"),
                countries: getValues("countries"),
                properties: getValues("properties"),
                brand: getValues("brand"),
              })
              localStorage.setItem("product_info", res)
            }}
            type="button"
            className="btn btn-secondary"
          >
            Save Locally
          </Button>
          <Button disabled={props.update ? false : !isValid} type="submit">
            {props.update ? "Save Changes" : "Add Product"}
          </Button>
        </div>
        <div>
          <em className={styles.error}>{errorMessage}</em>
        </div>
      </form>
      {isLoading && (
        <div className={styles.loading_overlay}>
          <p>Please Wait until the product is succesfully uploaded</p>
          <Loading type="spin" height={"30px"} width={"30px"} color="white" />
        </div>
      )}
    </div>
  )
}
