import React, { useEffect, useState } from "react"
import styles from "./PriceGroupes.module.scss"
import TransInput from "../../../../custom/transInput/TransInput"
import PriceContainer from "../container/PriceContainer"
import SoldOut from "../../../../../icons/states/SoldOut"

import Globe from "../../../../../icons/Globe"

import { useDispatch } from "react-redux"
import PhoneProduct from "../../../../../icons/PhoneProduct"

export default function PriceGroupes({ onOpen, onChange, value }) {
  const priceGroupeModel = {
    name: "",
    prices: {
      base: 0,
      corporate: 0,
      distributor: 0,
      retailer: 0,
    },
    countries: [],
    isOutOfStock: false,
    callForPrices: false,
    state: "none",
  }

  const [priceGroupes, setPriceGroupes] = useState([])
  useEffect(() => {
    setPriceGroupes(value)
  })

  const addPriceGroupe = () => {
    const newValue = [...priceGroupes, priceGroupeModel]
    onChange(newValue)
    setPriceGroupes(newValue)
  }
  const nameOnChange = (e, index) => {
    let newValue = [...priceGroupes]
    newValue[index].name = e.target.value
    onChange(newValue)
    setPriceGroupes(newValue)
  }

  const pricesChange = (prices, index) => {
    let newValue = [...priceGroupes]
    newValue[index].prices = prices
    onChange(newValue)
    setPriceGroupes(newValue)
  }

  const toggleState = (index, state) => {
    let newValue = [...priceGroupes]
    newValue[index].state = state
    onChange(newValue)
    setPriceGroupes(newValue)
  }

  const setCallForPrices = (index, state) => {
    let newValue = [...priceGroupes]
    newValue[index].callForPrices = state
    onChange(newValue)
    setPriceGroupes(newValue)
  }

  return (
    <div className={styles.pricing}>
      {priceGroupes.map((priceGroupe, index) => {
        return (
          <div key={index}>
            <div className={styles.functions}>
              <div>
                <TransInput
                  onChange={(e) => nameOnChange(e, index)}
                  placeholder={"name"}
                  wide={false}
                />
              </div>
              <div style={{ display: "flex", gap: 20 }}>
                <select
                  onChange={(e) => {
                    toggleState(index, e.target.value)
                  }}
                  defaultValue={priceGroupe.state}>
                  <option value="none">None</option>
                  <option value="sold out">Sold Out</option>
                  <option value="coming soon">Coming Soon</option>
                  <option value="on order">On Order</option>
                </select>
                <Globe
                  onClick={() => {
                    onOpen(index)
                  }}
                  size={20}
                  fill={"grey"}
                />
                <PhoneProduct
                  onClick={() => {
                    setCallForPrices(
                      index,
                      !priceGroupe.callForPrices
                    )
                  }}
                  size={20}
                  fill={priceGroupe.callForPrices ? "blue" : "grey"}
                />
              </div>
            </div>
            <PriceContainer
              onChange={(e) => pricesChange(e, index)}
              value={priceGroupe}
            />
          </div>
        )
      })}
      <em onClick={addPriceGroupe} className={styles.pricing_button}>
        Add price grouping
      </em>
    </div>
  )
}
