import React from 'react'
import styles from './Header.module.scss'

export default function Header(props) {
  const cats = props.categories || []
  return (
    <div className={styles.header}>
      {Object.entries(cats).map(([key, cat]) => {
        return (
          <p key={key} className={styles.cat}>
            {cat}
          </p>
        )
      })}
    </div>
  )
}
