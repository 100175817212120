import {
  createSlice,
  createAsyncThunk,
  current,
} from "@reduxjs/toolkit"
import {
  getCatalogues,
  createCatalogueRequest,
  deleteCatalogueRequest,
  handleVisibleRequest,
} from "../api/catalogue"

const initialState = {
  catalogues: [],
  isLoading: true,
  errorMessage: "",
  catalogue: undefined,
}

export const fetchCatalogues = createAsyncThunk(
  "catalogues/getCatalogues",
  async (payload, thunkApi) => {
    try {
      const cataloguesData = await getCatalogues()

      return cataloguesData
    } catch (error) {
      console.log("fetchCatalogues" + error)
      throw error
    }
  }
)

export const addCatalogue = createAsyncThunk(
  "db-admin/addCatalogue",
  async (payload, thunkApi) => {
    try {
      const token = thunkApi.getState().auth
      const res = await createCatalogueRequest(token, payload, false)
      return res
    } catch (error) {
      console.log("addCatalogue" + error)
      throw error
    }
  }
)

export const updateCatalogue = createAsyncThunk(
  "db-admin/updateCatalogue",
  async (payload, thunkApi) => {
    try {
      const token = thunkApi.getState().auth
      const res = await createCatalogueRequest(token, payload, true)
      return res
    } catch (error) {
      console.log("updateCatalogue" + error)
      throw error
    }
  }
)

export const deleteCatalogue = createAsyncThunk(
  "db-admin/deleteCatalogue",
  async (payload, thunkApi) => {
    try {
      const token = thunkApi.getState().auth
      const res = await deleteCatalogueRequest(payload.id, token)
      return res
    } catch (error) {
      console.log("deleteCatalogue" + error)
      throw error
    }
  }
)

export const handleVisible = createAsyncThunk(
  "db-admin/handleVisible",
  async (payload, thunkApi) => {
    try {
      const token = thunkApi.getState().auth
      const res = await handleVisibleRequest(payload.id, token)
      return res
    } catch (error) {
      console.log("handleVisible" + error)
      throw error
    }
  }
)

export const cataloguesSlice = createSlice({
  name: "catalogues",
  initialState,
  reducers: {
    setCatalogue: (state, action) => {
      state.catalogue = action.payload
    },
    resetCatalogue: (state, action) => {
      state.catalogue = undefined
    },
    resetErrorMessage: (state, action) => {
      state.errorMessage = ""
    },
  },
  extraReducers: (builder) => {
    //All
    builder.addCase(fetchCatalogues.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchCatalogues.fulfilled, (state, action) => {
      state.isLoading = false
      state.catalogues = action.payload
    })
    builder.addCase(fetchCatalogues.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.payload
    })
    //Add
    builder.addCase(addCatalogue.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(addCatalogue.fulfilled, (state, action) => {
      state.isLoading = false
      state.catalogues = [
        ...current(state.catalogues),
        action.payload,
      ]
    })
    builder.addCase(addCatalogue.rejected, (state, action) => {
      state.isLoading = false
      console.log(action)
      state.errorMessage = action.payload
    })
    //Update
    builder.addCase(updateCatalogue.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(updateCatalogue.fulfilled, (state, action) => {
      const index = state.catalogues.findIndex(
        (catalogue) => catalogue.id === action.payload.id
      )
      state.catalogues[index] = action.payload
      console.log("action ",action.payload)
      console.log("state ",current(state.catalogues))
      state.isLoading = false

    })
    builder.addCase(updateCatalogue.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.payload
    })
    //Delete
    builder.addCase(deleteCatalogue.pending, (state, action) => {
      state.isLoading = true
    })

    builder.addCase(deleteCatalogue.fulfilled, (state, action) => {
      state.isLoading = false
      const index = state.catalogues.findIndex(
        (catalogue) => catalogue.id === action.payload.id
      )
      state.catalogues.splice(index, 1)
    })
    builder.addCase(deleteCatalogue.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.payload
    })
    //Handle Visible

    builder.addCase(handleVisible.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(handleVisible.fulfilled, (state, action) => {
      state.isLoading = false
      const index = state.catalogues.findIndex(
        (catalogue) => catalogue.id === action.payload.id
      )
      state.catalogues[index] = action.payload
    })
    builder.addCase(handleVisible.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.payload
    })
  },
})

export const { resetCatalogue, setCatalogue, resetErrorMessage } =
  cataloguesSlice.actions

export default cataloguesSlice.reducer
