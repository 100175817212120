import React, { useState } from "react"
import Price from "../Price"
import styles from "./PriceContainer.module.scss"

export default function PriceContainer({ value, onChange }) {
  let schema = {
    base: 0,
    corporate: 0,
    retailer: 0,
    distributor: 0,
  }

  const [prices, setPrices] = useState(
    value.prices ? value.prices : schema
  )

  const dataChange = (e, placeholder) => {
    if (placeholder === "Base Price") {
      setPrices({ ...prices, base: e.target.value })
      onChange({ ...prices, base: e.target.value }, value.name)
    }
    if (placeholder === "Corporate Price") {
      setPrices({ ...prices, corporate: e.target.value })
      onChange({ ...prices, corporate: e.target.value }, value.name)
    }
    if (placeholder === "Retailer Price") {
      setPrices({ ...prices, retailer: e.target.value })
      onChange({ ...prices, retailer: e.target.value }, value.name)
    }
    if (placeholder === "Distributor Price") {
      setPrices({ ...prices, distributor: e.target.value })
      onChange({ ...prices, distributor: e.target.value }, value.name)
    }
  }

  return (
    <>
      <details>
        <summary>{value.name} Prices</summary>
        <div className={styles.priceRow}>
          <p className={styles.priceTitle}>Base price</p>
          <p className={styles.priceTitle}>Corporate Price</p>
          <p className={styles.priceTitle}>Retailer Price</p>
          <p className={styles.priceTitle}>Ditributor Price</p>
        </div>

        <hr
          style={{
            borderTop: "2px dashed black",
            marginTop: 10,
          }}
        />

        <div className={styles.basePrice}>
          <Price
            country={value.name}
            value={prices.base}
            onChange={dataChange}
            placeholder="Base Price"
          />
          <Price
            country={value.name}
            value={prices.corporate}
            onChange={dataChange}
            placeholder="Corporate Price"
          />
          <Price
            country={value.name}
            value={prices.retailer}
            onChange={dataChange}
            placeholder="Retailer Price"
          />
          <Price
            country={value.name}
            value={prices.distributor}
            onChange={dataChange}
            placeholder="Distributor Price"
          />
        </div>
      </details>
      <hr
        style={{
          borderTop: "2px dashed black",
          marginTop: 20,
        }}
      />
    </>
  )
}
