import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { updateMeta } from "../../../../../../state/seoSlice"
import Input from "../../../../../custom/input/Input"
import styles from "./MetaCard.module.scss"

export default function MetaCard(props) {
  const { accessLevel } = useSelector((state) => state.auth)
  const item = props.data
  const dispatch = useDispatch()
  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      title: item.metaTitle || "",
      description: item.metaDescription || "",
      url: item.url || "",
      keywords: item.metaKeywords || "",
    },
  })

  const onSubmit = (data) => {
    const payload = {
      id: item._id,
      title: data.title,
      description: data.description,
      keywords: data.keywords,
      url: data.url,
    }

    dispatch(updateMeta(payload))
  }

  return (
    <div className={styles.main_card}>
      <div className={styles.visual}>
        <div className={styles.header_main}>
          <div className={styles.image_container}>
            <div className={styles.image}></div>
          </div>
          <div className={styles.text}>
            <p className={styles.siteName}>Compu One</p>
            <p className={styles.url}>https://compuonelb/{item.url}</p>
          </div>
        </div>
        <div>
          <h3 className={styles.header}>{item.metaTitle}</h3>
          <span className={styles.description}>{item.metaDescription}</span>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} id={styles.form}>
        <div className={styles.edit}>
          <div className={styles.entries}>
            <div className={styles.page_title}>
              <p className={styles.input_title}>Page Title:</p>
              <Controller
                control={control}
                name="title"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    onChange={(e) => {
                      if (e.target.value.length > 60) return
                      onChange(e)
                    }}
                    onBlur={onBlur}
                    value={value}
                    placeholder="Page URL"
                  />
                )}
              />
              <p className={styles.counter}>{watch("title").length}/60</p>
            </div>
            <div className={styles.page_title}>
              <p className={styles.input_title}>Page URL:</p>
              <Controller
                control={control}
                name="url"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    style={
                      accessLevel !== "super-admin"
                        ? { cursor: "not-allowed" }
                        : null
                    }
                    disabled={accessLevel !== "super-admin"}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    placeholder="Page URL"
                  />
                )}
              />
            </div>
            <div style={{ height: "1rem" }} />
            <div className={styles.page_title}>
              <p className={styles.input_title}>Page Keywords:</p>
              <Controller
                control={control}
                name="keywords"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    placeholder="Keywords"
                  />
                )}
              />
            </div>
            <div className={styles.page_description}>
              <p className={styles.input_title}>Page Description:</p>
              <Controller
                control={control}
                name="description"
                render={({ field: { onChange, onBlur, value } }) => (
                  <textarea
                    className={styles.input}
                    onChange={(e) => {
                      if (e.target.value.length > 160) return
                      onChange(e)
                    }}
                    onBlur={onBlur}
                    value={value}
                    placeholder="Page desciption"
                  />
                )}
              />
              <p className={styles.counter}>
                {watch("description").length}/160
              </p>
            </div>
          </div>
          <div className={styles.save_container}>
            <button type="submit" className={styles.save_button}>
              <p>Save</p>
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
