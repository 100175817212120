import {
  createAsyncThunk,
  createSlice,
  current,
} from "@reduxjs/toolkit"
import {
  getCardsRequest,
  updateCardsRequest,
  getMetaRequest,
  addMetaRequest,
  updateMetaRequest,
  getContactRequest,
  addContactRequest,
  updateContactRequest,
  deleteContactRequest,
  getContactByCountryRequest,
} from "../api/seo"

const initialState = {
  cards: null,
  meta: [],
  contacts: [],
  contact: {
    city: "",
    address1: "",
    address2: "",
    googleMapsLocation: "",
    image_url: "",
    contacts: [],
    mondayFriday: "",
    saturday: "",
    country: "",
    shipment: [],
    instagram: "",
    tiktok: "",
    facebook: "",
    whatsapp: "",
    skype: "",
    phoneNumber: "",
  },
  isLoading: true,
  errorMessage: "",
}

///Cards

export const getCards = createAsyncThunk(
  "seo/getCards",
  async (payload, thunkApi) => {
    try {
      const cardsData = await getCardsRequest()
      return cardsData
    } catch (error) {
      console.log("getCards" + error)
      throw error
    }
  }
)

export const updateCards = createAsyncThunk(
  "seo/updateCards",
  async (payload, thunkApi) => {
    try {
      const cardsData = await updateCardsRequest(payload)
      return cardsData
    } catch (error) {
      console.log("updateCards" + error)
      throw error
    }
  }
)

//Meta
export const getMeta = createAsyncThunk(
  "seo/getMeta",
  async (payload, thunkApi) => {
    try {
      const meta = await getMetaRequest()
      return meta
    } catch (error) {
      console.log("getMeta" + error)
      throw error
    }
  }
)

export const addMeta = createAsyncThunk(
  "seo/addMeta",
  async (payload, thunkApi) => {
    try {
      const token = thunkApi.getState().auth
      const meta = await addMetaRequest(token)
      return meta
    } catch (error) {
      console.log("addMeta" + error)
      throw error
    }
  }
)

export const updateMeta = createAsyncThunk(
  "seo/updateMeta",
  async (payload, thunkApi) => {
    try {
      const token = thunkApi.getState().auth
      const meta = await updateMetaRequest(payload, token)
      return meta
    } catch (error) {
      console.log("updateMeta" + error)
      throw error
    }
  }
)

//Contact
export const getContact = createAsyncThunk(
  "seo/getContact",
  async (payload, thunkApi) => {
    try {
      const contact = await getContactRequest()
      return contact
    } catch (error) {
      console.log("getContact" + error)
      throw error
    }
  }
)

export const addContact = createAsyncThunk(
  "seo/addContact",
  async (payload, thunkApi) => {
    try {
      const token = thunkApi.getState().auth
      const contact = await addContactRequest(token)
      return contact
    } catch (error) {
      console.log("addContact" + error)
      throw error
    }
  }
)

export const updateContact = createAsyncThunk(
  "seo/updateContact",
  async (payload, thunkApi) => {
    try {
      const token = thunkApi.getState().auth
      const contact = await updateContactRequest(payload, token)
      return contact
    } catch (error) {
      console.log("updateContact" + error)
      throw error
    }
  }
)

export const deleteContact = createAsyncThunk(
  "seo/deleteContact",
  async (payload, thunkApi) => {
    try {
      const token = thunkApi.getState().auth
      const contact = await deleteContactRequest(payload, token)
      return contact
    } catch (error) {
      console.log("deleteContact" + error)
      throw error
    }
  }
)

export const getContactByCountry = createAsyncThunk(
  "seo/getContactByCountry",
  async (payload, thunkApi) => {
    try {
      const contact = await getContactByCountryRequest(payload)
      return contact
    } catch (error) {
      console.log("getContactByCountry" + error)
      throw error
    }
  }
)

export const seoSlice = createSlice({
  name: "seo",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getCards.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getCards.fulfilled, (state, action) => {
      state.isLoading = false
      state.cards = action.payload
    })
    builder.addCase(getCards.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.error.message
    })
    builder.addCase(updateCards.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(updateCards.fulfilled, (state, action) => {
      state.isLoading = false
      state.cards = action.payload
    })
    builder.addCase(updateCards.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.error.message
    })
    builder.addCase(getMeta.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getMeta.fulfilled, (state, action) => {
      state.isLoading = false
      state.meta = action.payload
    })
    builder.addCase(getMeta.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.error.message
    })
    builder.addCase(addMeta.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(addMeta.fulfilled, (state, action) => {
      const array = current(state.meta)
      const newArray = [...array, action.payload]
      state.isLoading = false
      state.meta = newArray
    })
    builder.addCase(addMeta.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.error.message
    })
    builder.addCase(updateMeta.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(updateMeta.fulfilled, (state, action) => {
      state.isLoading = false

      const index = state.meta.findIndex(
        (item) => item._id === action.payload._id
      )

      state.meta[index] = action.payload
    })
    builder.addCase(updateMeta.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.error.message
    })
    builder.addCase(getContact.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getContact.fulfilled, (state, action) => {
      state.isLoading = false
      state.contacts = action.payload
    })
    builder.addCase(getContact.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.error.message
    })
    builder.addCase(addContact.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(addContact.fulfilled, (state, action) => {
      const array = current(state.contacts)
      const newArray = [...array, action.payload]
      state.isLoading = false
      state.contacts = newArray
    })
    builder.addCase(addContact.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.error.message
    })
    builder.addCase(updateContact.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(updateContact.fulfilled, (state, action) => {
      const array = current(state.contacts)
      const newArray = array.map((item) => {
        if (item._id === action.payload._id) {
          return action.payload
        }
        return item
      })
      state.isLoading = false
      state.contacts = newArray
    })
    builder.addCase(updateContact.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.error.message
    })
    builder.addCase(deleteContact.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(deleteContact.fulfilled, (state, action) => {
      const array = current(state.contacts)
      const newArray = array.filter(
        (item) => item._id !== action.payload
      )
      state.isLoading = false
      state.contacts = newArray
    })
    builder.addCase(deleteContact.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.error.message
    })
    builder.addCase(getContactByCountry.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(
      getContactByCountry.fulfilled,
      (state, action) => {
        state.isLoading = false

        if (action.payload.contact) {
          state.contact = action.payload.contact
        } else {
          state.contact = {
            city: "",
            address1: "",
            address2: "",
            googleMapsLocation: "",
            image_url: "",
            contacts: [],
            mondayFriday: "",
            saturday: "",
            countries: [],
            shipment: [],
            instagram: "",
            tiktok: "",
            facebook: "",
            whatsapp: "",
            skype: "",
            youtube: "",
          }
        }
      }
    )
    builder.addCase(getContactByCountry.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.error.message
    })
  },
})
export default seoSlice.reducer
