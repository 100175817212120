import React from "react"

export default function Duplicate(props) {
  return (
    <svg
      {...props}
      style={{ cursor: "pointer" }}
      width={props.size || "512"}
      height={props.size || "512"}
      viewBox="0 0 32 32">
      <g>
        <path
          d="M20 29H6a3 3 0 0 1-3-3V12a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3zM6 11a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V12a1 1 0 0 0-1-1zM10 8a1 1 0 0 1-1-1V6a3 3 0 0 1 3-3h1a1 1 0 0 1 0 2h-1a1 1 0 0 0-1 1v1a1 1 0 0 1-1 1zM26 23h-1a1 1 0 0 1 0-2h1a1 1 0 0 0 1-1v-1a1 1 0 0 1 2 0v1a3 3 0 0 1-3 3zM28 8a1 1 0 0 1-1-1V6a1 1 0 0 0-1-1h-1a1 1 0 0 1 0-2h1a3 3 0 0 1 3 3v1a1 1 0 0 1-1 1zM28 16a1 1 0 0 1-1-1v-4a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zM21 5h-4a1 1 0 0 1 0-2h4a1 1 0 0 1 0 2z"
          fill={props.fill || "#000000"}></path>
        <path
          d="M16 20h-6a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2z"
          fill={props.fill || "#000000"}></path>
        <path
          d="M13 23a1 1 0 0 1-1-1v-6a1 1 0 0 1 2 0v6a1 1 0 0 1-1 1z"
          fill={props.fill || "#000000"}></path>
      </g>
    </svg>
  )
}
