import React, { useEffect } from "react"
import styles from "./Meta.module.scss"
import MetaCard from "./metaCard/MetaCard"
import Add from "../../../../../icons/Add"
import { useDispatch, useSelector } from "react-redux"
import { addMeta, getMeta } from "../../../../../state/seoSlice"

export default function Meta() {
  const dispatch = useDispatch()
  const { meta } = useSelector((state) => state.seo)
  console.log(meta)
  useEffect(() => {
    dispatch(getMeta())
  }, [dispatch])

  const handleAddMeta = () => {
    dispatch(addMeta())
  }

  return (
    <main id={styles.main}>
      <div className={styles.cards_list}>
        {Object.entries(meta).map(([key, item]) => {
          return <MetaCard key={key} data={item} />
        })}
      </div>
      <div className={styles.add}>
        <Add onClick={() => handleAddMeta()} size={25} />
      </div>
    </main>
  )
}
