import {
  createAsyncThunk,
  createSlice,
  current,
} from "@reduxjs/toolkit"
import {
  createOfferRequest,
  deleteOfferRequest,
  fetchOfferByDateRequest,
  fetchOffersRequest,
  updateOfferRequest,
} from "../api/offer"

const initialState = {
  offers: [],
  activeOffers: [],
  offer: {
    title: "",
    startDate: "",
    endDate: "",
    image: "",
  },
  isLoading: true,
  errorMessage: "",
}

export const getOffers = createAsyncThunk(
  "offer/getOffers",
  async (payload, thunkApi) => {
    try {
      const offersData = await fetchOffersRequest()
      return offersData
    } catch (error) {
      console.log("getOffers" + error)
      throw error
    }
  }
)

export const getOfferByDate = createAsyncThunk(
  "offer/getOfferByDate",
  async (payload, thunkApi) => {
    try {
      const offersData = await fetchOfferByDateRequest(payload)
      const offersId = JSON.parse(localStorage.getItem("offersId"))
      let activeOffers = []
      console.log("hhe")

      offersData.forEach((offer) => {
        if (offersId) {
          if (offersId?.includes(offer._id)) {
            return
          }
        }

        activeOffers.push(offer)
      })

      return activeOffers
    } catch (error) {
      console.log("getOfferByDate" + error)
      throw error
    }
  }
)

export const updateOffer = createAsyncThunk(
  "offer/updateOffer",
  async (payload, thunkApi) => {
    try {
      const offerData = await updateOfferRequest(payload)
      return offerData
    } catch (error) {
      console.log("updateOffer" + error)
      throw error
    }
  }
)

export const addOffer = createAsyncThunk(
  "offer/addOffer",
  async (payload, thunkApi) => {
    try {
      const offersData = await createOfferRequest(payload)
      return offersData
    } catch (error) {
      console.log("addOffer" + error)
      throw error
    }
  }
)

export const deleteOffer = createAsyncThunk(
  "offer/deleteOffer",
  async (payload, thunkApi) => {
    try {
      const res = await deleteOfferRequest(payload)
      return {
        id: payload._id,
        result: res,
      }
    } catch (error) {
      console.log("deleteOffer" + error)
      throw error
    }
  }
)

export const offerSlice = createSlice({
  name: "offer",
  initialState,
  reducers: {
    resetActiveOffers: (state, action) => {
      state.activeOffers = []
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getOffers.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getOffers.fulfilled, (state, action) => {
      state.isLoading = false
      state.offers = action.payload
    })
    builder.addCase(getOffers.rejected, (state, action) => {
      state.isLoading = false

      state.errorMessage = action.payload
    })
    builder.addCase(getOfferByDate.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getOfferByDate.fulfilled, (state, action) => {
      state.isLoading = false
      state.activeOffers = action.payload
    })
    builder.addCase(getOfferByDate.rejected, (state, action) => {
      state.isLoading = false

      state.errorMessage = action.payload
    })
    builder.addCase(updateOffer.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(updateOffer.fulfilled, (state, action) => {
      state.isLoading = false
      state.offers = state.offers.map((offer) => {
        if (offer._id === action.payload._id) {
          return action.payload
        }
        return offer
      })
    })
    builder.addCase(updateOffer.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.payload
    })
    builder.addCase(addOffer.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(addOffer.fulfilled, (state, action) => {
      state.isLoading = false
      state.offers = [...current(state.offers), action.payload]
    })
    builder.addCase(addOffer.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.payload
    })
    builder.addCase(deleteOffer.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(deleteOffer.fulfilled, (state, action) => {
      state.isLoading = false

      if (action.payload.result.success) {
        state.offers = state.offers.filter(
          (offer) => offer._id !== action.payload.id
        )
      }
    })
  },
})

export const { resetActiveOffers } = offerSlice.actions

export default offerSlice.reducer
