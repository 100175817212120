import React, { useEffect, useState } from "react"
import styles from "./Categories.module.scss"
import Category from "./category/Category"

export default function Categories(props) {
  const [cat, setCat] = useState(
    props.value !== undefined ? props.value : ""
  )
  const list = props.list

  const isCat = props.isCat
  useEffect(() => {
    setCat(props.value)
  }, [props.value])

  return (
    <div className={styles.categoriesList}>
      {Object.entries(list).map(([key, value]) => {
        let item
        if (isCat) {
          item = value.name
        } else {
          console.log(value)
          item = value
        }
        return (
          <Category
            value={cat}
            onClick={() => {
              if (cat === item) {
                props.onChildData(undefined)
                setCat(undefined)
                return
              }
              props.onChildData(item)
              setCat(item)
            }}
            title={item}
            key={key}
          />
        )
      })}
    </div>
  )
}
