import React from 'react'
import styles from './Driver.module.scss'
import Edit from '../../../icons/Edit'
import Delete from '../../../icons/Delete'
import NotVisible from '../../../icons/NotVisible'
import Discount from '../../../icons/Discount'
import Windows from '../../../icons/Windows'
import Mac from '../../../icons/Mac'
import Linux from '../../../icons/Linux'
import { useDispatch } from 'react-redux'
import { deleteDriver, setDriver } from '../../../state/driversSlice'
import { handleVisible } from '../../../state/driversSlice'

export default function Driver(props) {
  const driver = props.driver
  const dispatch = useDispatch()

  return (
    <div className={styles.mainCard}>
      {/* <div>
        <img
          className={styles.image}
          alt="driver_image"
          src={process.env.REACT_APP_BASE_URL + driver.imageUrl}
        />
      </div> */}

      <div>
        <p>{driver.name}</p>
      </div>

      <div style={{ display: 'flex', gap: '1rem' }}>
        {driver.windows !== '' && (
          <div className={styles.non_flex}>
            <Windows width={'20px'} height={'20px'} />
          </div>
        )}
        {driver.mac !== '' && (
          <div className={styles.non_flex}>
            <Mac width={'20px'} height={'20px'} />
          </div>
        )}
        {driver.linux !== '' && (
          <div className={styles.non_flex}>
            <Linux width={'20px'} height={'20px'} />
          </div>
        )}
      </div>

      <div className={styles.action}>
        <div className={styles.non_flex} title="Edit">
          <Edit
            onClick={() => {
              props.setVisible()
              dispatch(setDriver(driver))
            }}
          />
        </div>
        <div className={styles.non_flex} title="Delete">
          <Delete onClick={() => dispatch(deleteDriver({ id: driver._id }))} />
        </div>

        <div
          className={styles.non_flex}
          title={driver.isVisible ? 'Hide' : 'Show'}
        >
          <NotVisible
            fill={driver.isVisible ? '#999' : '#e52627'}
            onClick={() => dispatch(handleVisible({ id: driver._id }))}
          />
        </div>
      </div>
    </div>
  )
}
