import React from 'react'
import Reviewed from '../../icons/Reviewed'
import styles from './PendingAccount.module.scss'

export default function PendingAccount() {
  return (
    <div className={styles.mainContainer}>
      <Reviewed size={200} />
      <h2>Pending Approval</h2>
      <p>
        Your account is currently being reviewed and we need some time to verify
        your info. During this review, you are free to navigate our store.
        Reviews generaly takes a few hours, so come back later on to check your
        status.
      </p>
    </div>
  )
}
