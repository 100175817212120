import React from "react"

export default function Globe({ size, fill, onClick }) {
  return (
    <svg
      onClick={onClick}
      width={size}
      height={size}
      style={{ cursor: "pointer" }}
      viewBox="0 0 31.416 31.416">
      <g>
        <path
          d="m28.755 6.968-.47.149-2.503.223-.707 1.129-.513-.163L22.57 6.51l-.289-.934-.387-.996-1.252-1.123-1.477-.289-.034.676 1.447 1.412.708.834-.796.416-.648-.191-.971-.405.033-.783-1.274-.524-.423 1.841-1.284.291.127 1.027 1.673.322.289-1.641 1.381.204.642.376h1.03l.705 1.412 1.869 1.896-.137.737-1.507-.192-2.604 1.315-1.875 2.249-.244.996h-.673l-1.254-.578-1.218.578.303 1.285.53-.611.932-.029-.065 1.154.772.226.771.866 1.259-.354 1.438.227 1.67.449.834.098 1.414 1.605 2.729 1.605-1.765 3.372-1.863.866-.707 1.927-2.696 1.8-.287 1.038c6.892-1.66 12.019-7.851 12.019-15.253a15.639 15.639 0 0 0-2.66-8.739z"
          fill={fill}
          opacity="1"></path>
        <path
          d="m17.515 23.917-1.144-2.121 1.05-2.188-1.05-.314-1.179-1.184-2.612-.586-.867-1.814v1.077h-.382L9.08 13.735v-2.507L7.43 8.545l-2.62.467H3.045l-.888-.582 1.133-.898-1.13.261a15.61 15.61 0 0 0-2.156 7.916c0 8.673 7.031 15.707 15.705 15.707.668 0 1.323-.059 1.971-.137l-.164-1.903s.721-2.826.721-2.922c-.001-.097-.722-2.537-.722-2.537zM5.84 5.065l2.79-.389 1.286-.705 1.447.417 2.312-.128.792-1.245 1.155.19 2.805-.263.773-.852 1.09-.728 1.542.232.562-.085A15.65 15.65 0 0 0 15.708 0 15.662 15.662 0 0 0 3.596 5.711h.008l2.236-.646zm10.532-3.503 1.604-.883 1.03.595-1.491 1.135-1.424.143-.641-.416.922-.574zm-4.751.129.708.295.927-.295.505.875-2.14.562-1.029-.602c-.001 0 1.006-.648 1.029-.835z"
          fill={fill}
          opacity="1"></path>
      </g>
    </svg>
  )
}
