import React from 'react'
import styles from './Card.module.scss'

export default function Card(props) {
  const item = props.item

  return (
    <div className={styles.store_location}>
      <div className={styles.store_image}>
        <img
          alt={item.city}
          src={process.env.REACT_APP_BASE_URL + item.image_url}
        />
      </div>
      <h3 className={styles.store_name}>{item.city}</h3>
      <div className={styles.store_info}>
        <div className={styles.store_address}>
          <div>
            <p>{item.address1}</p>
            <p>{item.address2}</p>
            <p className={styles.hours}>Hours:</p>
            <p>Monday-Saturday: {item.mondayFriday}</p>
            <p>Sunday: {item.saturday}</p>
          </div>
        </div>
        <div className={styles.store_contactInfo}>
          {item.contacts &&
            Object.entries(item.contacts).map(([key, value]) => (
              <div key={key} className={styles.contact_info_item}>
                <b>{value.prefix}:</b>
                <p>{value.example}</p>
              </div>
            ))}

          <a rel="noreferrer" target="_blank" href={item.googleMapsLocation}>
            <div className={styles.directions_button}>Get Directions</div>
          </a>
        </div>
      </div>
    </div>
  )
}
