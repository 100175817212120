import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchCatalogues } from "../../state/catalogueSlice"
import styles from "./CatalogueList.module.scss"
import Card from "./card/Card"
import CustomLoading from "../custom/loading/CustomLoading"

export default function CatalogueList({ brand }) {
  const dispatch = useDispatch()
  const { catalogues, isLoading } = useSelector(
    (state) => state.catalogues
  )

  useEffect(() => {
    dispatch(fetchCatalogues())
  }, [dispatch])
  let sortedCatalogues = catalogues
  if (brand !== "all" && brand != undefined) {
    sortedCatalogues = sortedCatalogues.filter(
      (p) => p.brand.toLowerCase() === brand.toLowerCase()
    )
  }
  if (isLoading) {
    return <CustomLoading />
  } else {
    return (
      <div className={styles.main}>
        <div className={styles.catalogues_list}>
          {Object.entries(sortedCatalogues).map(([key, value]) => {
            return <Card key={key} catalogue={value} />
          })}
        </div>
      </div>
    )
  }
}
