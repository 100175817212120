import React from 'react'

export default function Placeholder(props) {
  return (
    <svg
      onClick={props.onClick}
      style={{ cursor: 'pointer' }}
      width={props.size}
      height={props.size}
      viewBox="0 0 550.801 550.8"
    >
      <g>
        <path
          d="M515.828 61.201H34.972C15.659 61.201 0 76.859 0 96.172V454.63c0 19.312 15.659 34.97 34.972 34.97h480.856c19.314 0 34.973-15.658 34.973-34.971V96.172c0-19.313-15.658-34.971-34.973-34.971zm0 34.971V350.51l-68.92-62.66c-10.359-9.416-26.289-9.04-36.186.866l-69.752 69.741-137.532-164.278c-10.396-12.415-29.438-12.537-39.99-.271L34.972 343.219V96.172h480.856zm-148.627 91.8c0-26.561 21.523-48.086 48.084-48.086 26.562 0 48.086 21.525 48.086 48.086s-21.523 48.085-48.086 48.085c-26.56.001-48.084-21.524-48.084-48.085z"
          fill={props.fill}
        ></path>
      </g>
    </svg>
  )
}
