import React from "react"

export default function Payment({ color, size }) {
  return (
    <svg width={size} height={size} viewBox="0 0 512 512">
      <g>
        <path
          d="M305.409 253.32c69.84 0 126.65-56.82 126.65-126.66S375.249 0 305.409 0s-126.66 56.82-126.66 126.66 56.82 126.66 126.66 126.66zm-15-201.11v-3.44c0-8.29 6.71-15 15-15 8.28 0 15 6.71 15 15v3.46c17.85 3.83 31.28 19.73 31.28 38.71 0 8.28-6.72 15-15 15-8.29 0-15-6.72-15-15 0-5.29-4.31-9.6-9.6-9.6h-13.45c-5.29 0-9.6 4.31-9.6 9.6a9.61 9.61 0 0 0 3.89 7.71l12.48 9.24 30.32 22.44c9.96 7.37 15.96 19.16 16.04 31.56v.14c.07 10.58-3.98 20.56-11.42 28.09-5.52 5.59-12.42 9.34-19.94 10.92v3.51c0 8.29-6.72 15-15 15-8.29 0-15-6.71-15-15v-3.46c-7.32-1.56-14.05-5.16-19.49-10.54-7.53-7.43-11.71-17.35-11.77-27.93-.05-8.28 6.62-15.04 14.9-15.09h.1c8.24 0 14.95 6.66 15 14.91a9.58 9.58 0 0 0 9.65 9.54c8.288-.05 5.201-.032 13.43-.08 5.324-.042 9.581-4.363 9.54-9.67v-.14c-.02-3-1.47-5.86-3.89-7.65l-12.47-9.23-30.33-22.44c-10.04-7.44-16.04-19.34-16.04-31.83 0-19.02 13.47-34.93 31.37-38.73zM100.188 325.392c-3.845-6.666-12.386-8.985-19.093-5.116l-70.558 40.73c-6.681 3.867-8.973 12.412-5.116 19.102L77.535 505.01c3.86 6.679 12.4 8.973 19.093 5.116l70.558-40.74c6.691-3.858 8.974-12.403 5.116-19.093zM504.478 300.46c-6.79-9.43-19.93-11.56-29.35-4.77-28.58 20.59-83.46 60.13-87.82 63.28a53 53 0 0 1-6.32 4.63c-8.63 5.43-18.64 8.33-29.09 8.33h-71.85c-8.28 0-15-6.71-15-15 0-8.3 6.73-15 15-15h76.84c11.29 0 20.33-9.4 19.86-20.71-.44-10.73-9.6-19.05-20.34-19.05h-58.49c-3.96-4.19-8.3-8.03-12.95-11.46-15.99-11.79-35.75-18.76-57.14-18.76-38.03 0-75.87 23.96-91.56 55.94l61.36 106.27h115.1c22.6 0 44.86-5.78 64.45-17.05 6.79-3.9 14.06-8.59 21.94-14.25 33.02-23.72 100.53-73 100.58-73.03 9.44-6.78 11.58-19.94 4.78-29.37z"
          fill={color}
          opacity="1"></path>
      </g>
    </svg>
  )
}
