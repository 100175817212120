import React from "react"

export default function Options(props) {
  return (
    <svg
      {...props}
      cursor={"pointer"}
      width={props.size || "20"}
      height={props.size || "20"}
      viewBox="0 0 32 32">
      <g>
        <path
          d="M13 8c0-1.654 1.346-3 3-3s3 1.346 3 3-1.346 3-3 3-3-1.346-3-3zm3 5c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3zm0 8c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3z"
          fill={props.fill || "#000000"}></path>
      </g>
    </svg>
  )
}
