import React from "react"

export default function ({ onClick }) {
  return (
    <svg
      onClick={onClick}
      width="20"
      height="20"
      viewBox="0 0 480.966 480.966">
      <g>
        <path
          d="M353.652 7.313c-9.75-9.75-25.558-9.75-35.312 0-9.75 9.753-9.75 25.562 0 35.312l6.344 6.344-136 136h-74.75a32.207 32.207 0 0 0-22.625 9.375c-12.493 12.496-12.493 32.754 0 45.25l57.375 57.375L33.66 411.992A127.096 127.096 0 0 0 .238 471.035a8.001 8.001 0 0 0 2.106 7.59 8.006 8.006 0 0 0 7.59 2.105 127.11 127.11 0 0 0 59.039-33.425L183.996 332.28l57.379 57.375c12.492 12.492 32.75 12.492 45.246 0a32.188 32.188 0 0 0 9.375-22.62V292.28l136-136 6.344 6.344c9.754 9.75 25.562 9.75 35.312 0s9.75-25.559 0-35.313zm-296 428.68a111.024 111.024 0 0 1-37.254 24.577 111.061 111.061 0 0 1 24.575-37.265L159.996 308.28l12.688 12.688zm404.688-284.68a9.184 9.184 0 0 1-12.688 0l-12-12a8.004 8.004 0 0 0-11.312 0l-144 144a8.001 8.001 0 0 0-2.344 5.656v78.066a16.106 16.106 0 0 1-4.687 11.309c-6.336 6.031-16.29 6.031-22.625 0L102.62 228.28c-6.246-6.246-6.246-16.375 0-22.625a16.132 16.132 0 0 1 11.313-4.687h78.062a8.001 8.001 0 0 0 5.656-2.344l144-144a7.997 7.997 0 0 0 0-11.313l-12-12c-3.496-3.503-3.496-9.18 0-12.687a9.184 9.184 0 0 1 12.688 0l120 120c3.5 3.508 3.5 9.184 0 12.688zm0 0"
          fill="#000000"
          opacity="1"
          data-original="#000000"
          class=""></path>
      </g>
    </svg>
  )
}
