import React, { useState } from "react"

import styles from "./Auth.module.scss"
import { useSelector } from "react-redux"

import SignUpForm from "../../components/forms/SignUpForm"
import PendingAccount from "../../components/pending/PendingAccount"
import SignInForm from "../../components/forms/SignInForm"
import { useNavigate, useParams } from "react-router-dom"
import Header from "../../components/Header/Header"
import Footer from "../../components/footer/Footer"
import Logo from "../../assets/logo.png"
import Layout from "../../components/layout/Layout"

export default function Signup() {
  const navigate = useNavigate()
  const params = useParams()
  const [authType, setAuthType] = useState(params.state || "reg")

  const { token, verified } = useSelector((state) => state.auth)

  if (verified) {
    navigate("/")
  }

  return (
    <>
      <main className={styles.main}>
        <Layout />
        <a href="/">
          <img className={styles.logo} alt="Compuone-logo" src={Logo} />
        </a>
        <p className={styles.cta}>Sign in to Compuone</p>
        {token ? (
          verified ? (
            <h1>Verified</h1>
          ) : (
            <PendingAccount />
          )
        ) : authType === "reg" ? (
          <>
            <SignUpForm />
            <em>
              You already have an account?{" "}
              <em
                onClick={() => setAuthType("authenticate")}
                className={styles.clickable}
              >
                Sign in
              </em>{" "}
              instead.
            </em>
          </>
        ) : (
          <>
            <SignInForm />
            <em>
              You don't have an account?{" "}
              <em
                onClick={() => setAuthType("reg")}
                className={styles.clickable}
              >
                Create one
              </em>
              .
            </em>
          </>
        )}
      </main>
    </>
  )
}
