import React from "react"
import { useNavigate } from "react-router-dom"
import styles from "./HeaderListItem.module.scss"
import { formatUrl } from "../../helpers/Formats"

export default function HeaderListItem({ title, categories, onClick }) {
  const navigate = useNavigate()

  return (
    <li className={styles.listItem}>
      <div onClick={onClick} className={styles.textContainer}>
        <p className={styles.title}>{title}</p>
      </div>
      {/* {hover && ( */}
      <div className={styles.dropDown_menu}>
        <ul className={styles.dropDown_menu_list}>
          {Object.entries(categories).map(([key, cat]) => {
            let category = formatUrl(title)
            let subCat = formatUrl(cat)
            return (
              <li
                onClick={() => navigate(`/${category}/${subCat}/1`)}
                key={key}
                className={styles.dropDown_menu_listItem}
              >
                <p>{cat}</p>
              </li>
            )
          })}
        </ul>
      </div>
      {/* )} */}
    </li>
  )
}
