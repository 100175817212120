import axios from "axios"
import Cookies from "js-cookie"

export const loginRequest = async (payload) => {
  try {
    const res = await axios.post("users/authenticate", payload)
    _storeUserData(res.data)
    return res.data
  } catch (error) {
    throw error
  }
}

export const registerRequest = async (payload) => {
  try {
    const res = await axios.post("users/register", payload)
    _storeUserData(res.data)
    return res.data
  } catch (error) {
    throw error
  }
}

export const getUserDataRequest = async () => {
  try {
    const token = Cookies.get("user_data")
    let data = await JSON.parse(token)

    const res = await axios.get(`users/getUserData/${data.userId}`)
    data = res.data
    return data
  } catch (error) {
    throw error
  }
}

export const resetUserData = async () => {
  try {
    Cookies.set("user_data", "")
  } catch (error) {
    throw error
  }
}

export const requestChangePassword = async (userEmail) => {
  try {
    const res = await axios.post("users/requestChangePassword", {
      email: userEmail,
    })
    return res.data
  } catch (error) {
    throw error
  }
}

export const updateUserDataRequest = async (payload) => {
  try {
    const res = await axios.patch("users/updateUserData", payload)
    return res.data
  } catch (error) {
    throw error
  }
}

const _storeUserData = (userData) => {
  try {
    const cookies = {
      token: userData.token,
      userId: userData.user._id,
    }

    Cookies.set("user_data", JSON.stringify(cookies))
  } catch (error) {
    throw error
  }
}

export const contactUsRequest = async (payload) => {
  try {
    const res = await axios.post("mail/contactUs", payload)
    return res.data
  } catch (error) {
    throw error
  }
}
