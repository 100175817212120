import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  deleteOrderRequest,
  fetchOrderRequest,
  fetchOrdersRequest,
  getOrderByUserIdRequest,
  notifyUserRequest,
  updateOrderBillingRequest,
  updateOrderRequest,
  updateStatusRequest,
} from "../api/orders"
import { createOrderRequest } from "../api/orders"

const initialState = {
  orders: [],
  order: {
    products: [],
  },
  isLoading: true,
  errorMessage: "",
  confirmationMessage: "",
}

export const getOrders = createAsyncThunk(
  "orders/getOrders",
  async (payload, thunkApi) => {
    try {
      const token = thunkApi.getState().auth
      const res = await fetchOrdersRequest(payload, token)
      return res
    } catch (error) {
      console.log("fetchOrders" + error)
      throw error
    }
  }
)

export const getOrderById = createAsyncThunk(
  "orders/getOrderById",
  async (payload, thunkApi) => {
    try {
      const res = await fetchOrderRequest(payload)

      return res
    } catch (error) {
      console.log("fetchOrderById" + error)
      throw error
    }
  }
)

export const createOrder = createAsyncThunk(
  "orders/createOrder",
  async (payload, thunkApi) => {
    try {
      const res = await createOrderRequest(payload)
      return res
    } catch (error) {
      console.log("createOrder" + error)
      throw error
    }
  }
)

export const updateOrder = createAsyncThunk(
  "orders/updateOrder",
  async (payload, thunkApi) => {
    try {
      const res = await updateOrderRequest(payload)
      return res
    } catch (error) {
      console.log("updateOrder" + error)
      throw error
    }
  }
)

export const deleteOrder = createAsyncThunk(
  "orders/deleteOrder",
  async (payload, thunkApi) => {
    try {
      const res = await deleteOrderRequest(payload)
      return res
    } catch (error) {
      console.log("deleteOrder" + error)
      throw error
    }
  }
)

export const updateOrderStatus = createAsyncThunk(
  "orders/updateOrderStatus",
  async (payload, thunkApi) => {
    try {
      const res = await updateStatusRequest(payload)
      return res
    } catch (error) {
      console.log("updateOrderStatus" + error)
      throw error
    }
  }
)

export const updateOrderBilling = createAsyncThunk(
  "orders/updateOrderBilling",
  async (payload, thunkApi) => {
    try {
      const res = await updateOrderBillingRequest(payload)
      return res
    } catch (error) {
      console.log("updateOrderBilling" + error)
      throw error
    }
  }
)

export const getOrderByUserId = createAsyncThunk(
  "orders/getOrderByUserId",
  async (payload, thunkApi) => {
    try {
      const res = await getOrderByUserIdRequest(payload)
      return res
    } catch (error) {
      console.log("getOrderByUserId" + error)
      throw error
    }
  }
)

export const notifyUser = createAsyncThunk(
  "orders/notifyUser",
  async (payload, thunkApi) => {
    try {
      const res = await notifyUserRequest(payload)
      return res
    } catch (error) {
      console.log("notifyUser" + error)
      throw error
    }
  }
)

const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    resetLoading: (state, action) => {
      state.isLoading = action.payload
    },
    resetConfirmation: (state) => {
      state.confirmationMessage = ""
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrders.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getOrders.fulfilled, (state, action) => {
      state.isLoading = false
      state.orders = action.payload
    })
    builder.addCase(getOrders.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.error.message
    })
    builder.addCase(getOrderById.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getOrderById.fulfilled, (state, action) => {
      state.isLoading = false
      state.order = action.payload
    })
    builder.addCase(getOrderById.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.error.message
    })
    builder.addCase(createOrder.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(createOrder.fulfilled, (state, action) => {
      state.isLoading = false
      state.order = action.payload
    })
    builder.addCase(createOrder.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.error.message
    })
    builder.addCase(updateOrder.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(updateOrder.fulfilled, (state, action) => {
      state.isLoading = false
      state.order = action.payload
    })
    builder.addCase(updateOrder.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.error.message
    })
    builder.addCase(deleteOrder.pending, (state, action) => {
      state.isLoading = true
    })

    builder.addCase(deleteOrder.fulfilled, (state, action) => {
      state.isLoading = false
      state.order = {
        products: [],
      }
    })
    builder.addCase(deleteOrder.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.error.message
    })

    builder.addCase(updateOrderStatus.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(updateOrderStatus.fulfilled, (state, action) => {
      state.isLoading = false

      const index = state.orders.findIndex(
        (order) => order.orderId === action.payload.orderId
      )
      state.orders[index] = action.payload
    })
    builder.addCase(updateOrderStatus.rejected, (state, action) => {
      state.errorMessage = action.error.message
    })
    builder.addCase(updateOrderBilling.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(updateOrderBilling.fulfilled, (state, action) => {
      state.isLoading = false
      state.order = action.payload.order
    })
    builder.addCase(updateOrderBilling.rejected, (state, action) => {
      state.errorMessage = action.error.message
    })
    builder.addCase(getOrderByUserId.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getOrderByUserId.fulfilled, (state, action) => {
      state.isLoading = false

      if (action.payload) {
        state.order = action.payload
      }
    })
    builder.addCase(getOrderByUserId.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.error.message
    })
    builder.addCase(notifyUser.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(notifyUser.fulfilled, (state, action) => {
      state.isLoading = false
      state.confirmationMessage = action.payload.message
    })

    builder.addCase(notifyUser.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.error.message
    })
  },
})

export const { resetLoading, resetConfirmation } = orderSlice.actions
export default orderSlice.reducer
