import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchAdminProducts,
  fetchProducts,
  resetErrorMessage,
  searchProducts,
} from "../../../state/productSlice"
import styles from "./Products.module.scss"
import AddProduct from "../../../components/admin/addProduct/AddProduct"
import Title from "../../../components/admin/title/Title"
import { resetProduct, reactivateLoading } from "../../../state/productSlice"
import Alert from "../../../components/alert/Alert"
import BrandList from "../../../components/admin/addProduct/brands/BrandList"
import TransInput from "../../../components/custom/transInput/TransInput"
import { addBrands } from "../../../state/brandSlice"
import ProductsList from "./products_list/ProductsList"
import AddDriver from "../../../components/admin/addDriver/AddDriver"
import DriverList from "./drivers_list/DriverList"
import { fetchDrivers, resetDriver } from "../../../state/driversSlice"

import AddCatalogue from "../../../components/admin/addCatalogues/AddCatalogues"
import { fetchCatalogues } from "../../../state/catalogueSlice"

import CatalogueList from "./catalogue_list/CatalogueList"
import { Controller, useForm } from "react-hook-form"

export default function Products() {
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(0)
  const [visible, setVisible] = useState(false)
  const [update, setUpdate] = useState(false)
  const [alert, setAlert] = useState(false)
  const [brandCreator, setBrandCreator] = useState(false)

  const [brand, setBrand] = useState("")
  const [url, setUrl] = useState("")

  const [selector, setSelector] = useState("products")

  const { drivers } = useSelector((state) => state.drivers)
  const { catalogues } = useSelector((state) => state.catalogues)
  const { count } = useSelector((state) => state.product)

  const { control } = useForm({
    defaultValues: {
      search: "",
    },
  })

  const dispatch = useDispatch()

  function handlePrevious() {
    setPage((p) => {
      if (p === 1) {
        return p
      }
      return p - 1
    })
  }

  function handleNext() {
    setPage((p) => {
      if (pageCount === p) {
        return p
      }
      return p + 1
    })
  }

  useEffect(() => {
    setPageCount(count)
  })

  useEffect(() => {
    const payload = {
      page: page,
    }
    dispatch(fetchAdminProducts(payload))
    dispatch(fetchDrivers())
    dispatch(fetchCatalogues())
  }, [dispatch, page])

  function handleAdd(added) {
    setVisible(added)
  }

  function addBrand() {
    const payload = {
      name: brand,
      url: url,
    }
    dispatch(addBrands(payload)).then(() => {
      setBrand("")
      setBrandCreator(false)
    })
  }

  function showDashboard() {
    if (selector === "products") {
      return (
        <AddProduct update={update} setUpdate={setUpdate} added={handleAdd} />
      )
    } else if (selector === "drivers") {
      return (
        <AddDriver
          setUpdate={setUpdate}
          update={update}
          close={() => setVisible(false)}
        />
      )
    } else if (selector === "catalogue") {
      return (
        <AddCatalogue
          setUpdate={setUpdate}
          update={update}
          close={() => setVisible(false)}
        />
      )
    }
  }

  function showLists() {
    if (selector === "products") {
      return (
        <ProductsList
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          page={page}
          pageCount={pageCount}
          setVisible={setVisible}
          setUpdate={setUpdate}
        />
      )
    } else if (selector === "drivers") {
      return (
        <DriverList
          setVisible={setVisible}
          setUpdate={setUpdate}
          drivers={drivers}
          google
          showDashboard
        />
      )
    } else if (selector === "catalogue") {
      return (
        <CatalogueList
          setVisible={setVisible}
          setUpdate={setUpdate}
          catalogues={catalogues}
        />
      )
    }
  }

  return (
    <section id={"mainContainer"}>
      {alert && (
        <Alert
          cancelText={"Continue"}
          confirmText={"Cancel"}
          description={"By canceling you will lose any progress."}
          heading={"Cancel adding a product."}
          cancel={() => {
            setAlert(false)
          }}
          confirm={() => {
            setUpdate(false)
            setVisible(false)
            setAlert(false)
            dispatch(reactivateLoading())
            dispatch(resetProduct())
            dispatch(resetDriver())
          }}
        />
      )}
      {brandCreator && (
        <Alert
          heading={"Add a brand"}
          description={"Brand added can't be editeds."}
          confirmText={"Add"}
          cancelText="Cancel"
          cancel={() => setBrandCreator(false)}
          confirm={addBrand}
        >
          <div
            style={{
              marginTop: "1rem",
            }}
          >
            <TransInput
              value={brand}
              onChange={(e) => setBrand(e.target.value)}
              placeholder="Brand"
            />
            <div style={{ width: "100%", height: "1rem" }} />
            <TransInput
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="Url"
            />
          </div>
        </Alert>
      )}

      <Title
        buttonContent={visible ? "Cancel" : "Add"}
        isButton="true"
        onClick={() => {
          if (visible) {
            setAlert(!alert)
          } else {
            dispatch(reactivateLoading())
            setVisible(!visible)
            dispatch(resetErrorMessage())
          }
        }}
      >
        Products
      </Title>
      {!visible && (
        <>
          <h3>Brands</h3>
          <BrandList onChange={() => {}} isLarge="true" />
          <div className={styles.add_container}>
            <em
              onClick={() => setBrandCreator(true)}
              className={styles.add_text}
            >
              add
            </em>
          </div>
        </>
      )}

      {!update && (
        <div className={styles.selector}>
          <p
            onClick={() => setSelector("products")}
            className={
              selector === "products" ? styles.selected : styles.text_button
            }
          >
            Products
          </p>
          <p
            onClick={() => setSelector("drivers")}
            className={
              selector === "drivers" ? styles.selected : styles.text_button
            }
          >
            Drivers
          </p>
          <p
            onClick={() => setSelector("catalogue")}
            className={
              selector === "catalogue" ? styles.selected : styles.text_button
            }
          >
            Catalogue
          </p>
        </div>
      )}
      <div>
        <Controller
          control={control}
          name="search"
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => {
            return (
              <TransInput
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    dispatch(
                      searchProducts({
                        entry: e.target.value,
                        page: page,
                      })
                    )
                  }
                }}
                value={value}
                onChange={(e) => {
                  if (e.target.value === "") {
                    const payload = {
                      subCat: undefined,
                      cat: "all",
                      country: "Lebanon",
                      accessLevel: "admin",
                      page: page,
                    }
                    dispatch(fetchProducts(payload))
                  }
                  onChange(e)
                }}
                placeholder="Search ..."
              />
            )
          }}
        />
      </div>
      {visible ? showDashboard() : showLists()}
    </section>
  )
}
