import React, { useEffect, useState } from "react"
import Countries from "../../../helpers/Countries"
import styles from "./CountriesSelector.module.scss"

import TransInput from "../../custom/transInput/TransInput"
import { filter } from "../../../helpers/Filter"
import CountryCard from "./card/CountryCard"
export default function CountriesSelector({ value, onSave, cancel }) {
  const [input, setInput] = useState("")
  const [selected, setSelected] = useState(value || [])
  const [EU, setEU] = useState(false)
  const [MENA, setMENA] = useState(false)
  const [International, setInternational] = useState(false)

  let countries = filter(Countries, input)

  function onChange(country, checked) {
    if (checked) {
      setSelected([...selected, country])
    } else {
      setSelected(selected.filter((c) => c !== country))
    }
  }

  const handleButtonClick = () => {
    onSave(selected)
  }

  const toggleEU = () => {
    setEU(!EU)
    let countries = Countries.filter((c) => c.eu)
    countries = countries.map((c) => c.name)

    setSelected(!EU ? countries : [])
  }
  const toggleMENA = () => {
    setMENA(!MENA)
    let countries = Countries.filter((c) => c.mena)
    countries = countries.map((c) => c.name)

    setSelected(!MENA ? countries : [])
  }
  const toggleInternational = () => {
    // setInternational(!International)
    // countries = Countries.map((c) => c.name)
    //select countries that are not in EU or MENA
    setInternational(!International)
    let countries = Countries.filter((c) => !c.eu && !c.mena)
    countries = countries.map((c) => c.name)

    setSelected(!International ? countries : [])
  }

  useEffect(() => {
    setSelected(value)
  }, [value])

  return (
    <div className={styles.mainContainer}>
      <div onClick={cancel} className={styles.mainContainer}></div>
      <div className={styles.container}>
        <p className={styles.header}>Countries</p>
        <div className={styles.search}>
          <span>
            Select countries in which this product is available
          </span>
          <div>
            <TransInput
              value={input}
              onChange={(e) => {
                setInput(e.target.value)
              }}
              placeholder="Search for a country"
            />
          </div>
        </div>
        <div className={styles.countriesManySelector}>
          <div onClick={toggleEU} className={styles.selectorMass}>
            <p>EU</p>
          </div>
          <div onClick={toggleMENA} className={styles.selectorMass}>
            <p>MENA</p>
          </div>
          <div
            onClick={toggleInternational}
            className={styles.selectorMass}>
            <p>International</p>
          </div>
        </div>
        <div className={styles.countriesList}>
          {Object.entries(countries).map(([key, country]) => {
            const isSelected = selected.includes(country.name)

            return (
              <CountryCard
                isSelected={isSelected}
                onChange={onChange}
                key={country.code}
                country={country}
              />
            )
          })}
        </div>
        <div
          onClick={() => {
            handleButtonClick()
            cancel()
          }}
          className={styles.button}>
          <p>save</p>
        </div>
      </div>
    </div>
  )
}
