import axios from "axios"

export async function pushNotificationRequest(data) {
  try {
    const res = await axios.post("notifications/push", data)
    return res.data
  } catch (error) {
    console.log("pushNotification" + error)
    throw error
  }
}
