import React, { useEffect, useState } from "react"
import styles from "./Cart.module.scss"

import Remove from "../../../icons/Remove"
import { useDispatch, useSelector } from "react-redux"
import { deleteOrder, updateOrder } from "../../../state/orderSlice"
import { useNavigate } from "react-router-dom"
import { useMediaPredicate } from "react-media-hook"
import MobileCart from "./mobile/MobileCart"

export default function Cart(props) {
  const order = props.order
  const lessThan850 = useMediaPredicate("(max-width: 850px)")

  const navigate = useNavigate()
  const [orderIdStorage, setOrderIdStorage] = useState(
    localStorage.getItem("cart_key")
  )

  const dispatch = useDispatch()

  let orderPayload = {
    orderId: !order ? "" : order.orderId,
    products: !order ? "" : order.products,
    totalPrice: !order ? "" : order.totalPrice,
  }

  useEffect(() => {
    const handleStorageChange = () => {
      // Update the local storage value in the state
      setOrderIdStorage(localStorage.getItem("cart_key"))
    }

    // Listen for the 'storage' event and call the handler
    window.addEventListener("cart_key", handleStorageChange)

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("cart_key", handleStorageChange)
    }
  }, [])

  if (lessThan850) {
    return (
      <MobileCart
        isOpen={props.isOpen}
        toggleDrawer={props.toggleDrawer}
      />
    )
  } else
    return (
      <div className={styles.main}>
        {!order || order.products.length === 0 ? (
          <p className={styles.emptyMessage}>Cart Empty</p>
        ) : (
          <>
            {Object.entries(order.products).map(([key, product]) => {
              return (
                <div key={key}>
                  <div className={styles.list_products}>
                    <div className={styles.product_card}>
                      <div className={styles.image_container}>
                        <img
                          className={styles.image}
                          src={
                            process.env.REACT_APP_BASE_URL +
                            product.image
                          }
                        />
                      </div>
                      <div className={styles.product_content}>
                        <p className={styles.product_name}>
                          {product.title}
                        </p>
                        <div
                          className={styles.product_price_container}>
                          <p className={styles.product_price}>
                            {product.quantity}
                          </p>
                          <p className={styles.product_price_icon}>
                            x
                          </p>
                          <p className={styles.product_price}>
                            ${product.price}
                          </p>
                        </div>
                      </div>
                      <div className={styles.remove_container}>
                        <Remove
                          onClick={() => {
                            let temp = [...order.products]
                            const productsPrice =
                              product.price * product.quantity
                            orderPayload.totalPrice -= productsPrice
                            temp.splice(key, 1)
                            orderPayload.products = temp
                            if (orderPayload.products.length === 0) {
                              localStorage.removeItem("cart_key")
                              const storageEvent = new Event(
                                "cart_key"
                              )
                              window.dispatchEvent(storageEvent)
                              dispatch(deleteOrder(order.orderId))
                            } else {
                              dispatch(updateOrder(orderPayload))
                            }
                          }}
                          size={"10px"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
            <div style={{ height: 20 }} />
            <hr className={styles.divider} />
            <div className={styles.subTotal}>
              <p className={styles.text}>SubTotal:</p>
              <p className={styles.totalPrice}>${order.totalPrice}</p>
            </div>
            <hr className={styles.divider} />
            <div className={styles.cart_footer}>
              <div
                onClick={() => {
                  if (order.orderStatus === "Pending") {
                    navigate(`/cart/${order.orderId}/payment`)
                  } else {
                    navigate(`/cart/${order.orderId}/cart`)
                  }
                }}
                className={styles.view_cart}>
                <p>View Cart</p>
              </div>
            </div>
          </>
        )}
      </div>
    )
}
