import axios from "axios"

// get all catalogue
export const getCatalogues = async () => {
  try {
    const res = await axios.get(`catalogue/getCatalogues`)
    return res.data
  } catch (error) {
    throw error
  }
}

// add driver
export const createCatalogueRequest = async (
  token,
  payload,
  update
) => {
  try {
    // const data = payload
    // let formData = new FormData()
    // formData.append("name", data.name)
    // formData.append("imgCollection", data.image)
    // formData.append("description", data.description)
    // formData.append("brand", data.brand)
    // formData.append("url", data.url)
    // formData.append("token", JSON.stringify(token))

    var res
    if (update) {
      // formData.append("_id", data.id)
      res = await axios.patch(`catalogue/updateCatalogue`, {token,payload})
    } else {
      res = await axios.post(`catalogue/addCatalogue`, {token,payload})
    }
    return res.data
  } catch (error) {
    throw error
  }
}

//delete driver
export const deleteCatalogueRequest = async (id, token) => {
  try {
    const res = await axios.delete(`catalogue/deleteCatalogue/`, {
      data: { id, token },
    })
    return res.data
  } catch (error) {
    throw error
  }
}

// handle visibile in catalogue
export const handleVisibleRequest = async (id, token) => {
  try {
    const res = await axios.patch(`catalogue/toggleVisible/`, {
      id,
      token,
    })
    return res.data
  } catch (error) {
    throw error
  }
}
