import React, { useReducer } from "react"
import SidePanel from "../../components/admin/sidePanel/SidePanel"

import { PageContext, initialState, reducer } from "./context"
import styles from "./MainAdmin.module.scss"

import Component from "./Component"
import AdminAuth from "./admin_auth/AdminAuth"
import { useSelector } from "react-redux"

export default function MainAdmin() {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { accessLevel, isLoading } = useSelector((state) => state.auth)

  return (
    <PageContext.Provider value={{ state, dispatch }}>
      {isLoading ? (
        <div></div>
      ) : accessLevel === "admin" || accessLevel === "super-admin" ? (
        <main id={styles.mainPage}>
          <SidePanel />
          <div style={{ width: 60 }} />
          <Component />
        </main>
      ) : (
        <AdminAuth />
      )}
    </PageContext.Provider>
  )
}
