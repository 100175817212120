import React from 'react'
import styles from './Mails.module.scss'
import Card from './Card/Card'

export default function Mails() {
  return (
    <div className={styles.mails_list}>
      <Card />
      <hr className={styles.divider} />
      <Card />
      <hr className={styles.divider} />
      <Card />
      <hr className={styles.divider} />
      <Card />
      <hr className={styles.divider} />
      <Card />
      <hr className={styles.divider} />
    </div>
  )
}
