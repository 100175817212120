import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { pushNotificationRequest } from "../api/notifications"

const initialState = {
  isLoading: true,
  errorMessage: "",
  confirmationMessage: "",
}

export const pushNotification = createAsyncThunk(
  "notification/pushNotification",
  async (payload, thunkApi) => {
    try {
      const res = await pushNotificationRequest(payload)
      return res
    } catch (error) {
      console.log("pushNotification" + error)
      throw error
    }
  }
)

export const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    clearMessages: (state) => {
      state.confirmationMessage = ""
      state.errorMessage = ""
    },
  },
  extraReducers: (builder) => {
    builder.addCase(pushNotification.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(pushNotification.fulfilled, (state, action) => {
      state.isLoading = false
      if (action.payload.success) {
        state.confirmationMessage = "Notification sent successfully"
      }
    })
    builder.addCase(pushNotification.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.error.message
    })
  },
})

export const { clearMessages } = notificationSlice.actions

export default notificationSlice.reducer
