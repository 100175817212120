import React from "react"

export default function Delivery({ color, size }) {
  return (
    <svg width={size} height={size} viewBox="0 0 512 512">
      <g>
        <path
          d="M365 3.016c44.39 10.3 61.371 36.257 82.66 71.984H365zM105.578 78c8.692 0 15 4.195 15 14 0 8.27-6.691 14.977-14.957 15H15c-8.285 0-15 6.719-15 15 0 8.285 6.715 15 15 15h135c8.363 0 15.059 6.71 15.059 15 0 8.285-6.715 15-15 15H15c-8.285 0-15 6.715-15 15s6.715 15 15 15h33v45c0 8.285 6.715 15 15 15h30.152c5.375 26.477 28.77 46 56.348 46s50.973-19.523 56.348-46h152.304c5.375 26.477 28.77 46 56.348 46s50.973-19.523 56.348-46H497c8.285 0 15-6.715 15-15v-90c0-44.012-46.422-46.934-46.465-47H350c-8.285 0-15-6.715-15-15V0H63c-8.285 0-15 6.715-15 15v33H30c-8.285 0-15 6.715-15 15s6.715 15 15 15zm328.367 148.055c10.739 10.738 10.739 28.156 0 38.894C416.672 282.223 387 269.934 387 245.5c0-24.43 29.672-36.719 46.945-19.445zm-265 0c10.739 10.738 10.739 28.156 0 38.894C151.672 282.223 122 269.934 122 245.5c0-24.43 29.672-36.719 46.945-19.445zm0 0"
          fill={color}
          opacity="1"></path>
      </g>
    </svg>
  )
}
