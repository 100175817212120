import axios from "axios"

export const fetchOffersRequest = async (payload) => {
  try {
    const res = await axios.get("offer/getAll")
    return res.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const fetchOfferByDateRequest = async (payload) => {
  try {
    const res = await axios.get(`offer/getActive/${payload}`)
    return res.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const createOfferRequest = async (payload) => {
  try {
    const formData = new FormData()
    formData.append("title", payload.title)
    formData.append("startDate", JSON.stringify(payload.startDate))
    formData.append("endDate", JSON.stringify(payload.endDate))
    formData.append("image", payload.image)
    formData.append("countries", JSON.stringify(payload.countries))
    const res = await axios.post("offer/add", formData)
    return res.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const updateOfferRequest = async (payload) => {
  try {
    const formData = new FormData()

    formData.append("title", payload.title)
    formData.append("startDate", JSON.stringify(payload.startDate))
    formData.append("endDate", JSON.stringify(payload.endDate))
    formData.append("image", payload.image)
    formData.append("id", payload.id)
    formData.append("countries", JSON.stringify(payload.countries))
    const res = await axios.patch("offer/update", formData)
    return res.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const deleteOfferRequest = async (payload) => {
  try {
    const res = await axios.delete(`offer/delete/${payload._id}`)
    return res.data
  } catch (error) {
    console.log(error)
    throw error
  }
}
