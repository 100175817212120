import axios from "axios"

export const fetchOrdersRequest = async (payload, token) => {
  try {
    const res = await axios.get("orders/get", {
      params: {
        ...token,
      },
    })
    return res.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const fetchOrderRequest = async (payload) => {
  try {
    const res = await axios.get(`orders/get/${payload}`)
    return res.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const createOrderRequest = async (payload) => {
  try {
    const res = await axios.post("orders/add", payload)
    return res.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const updateOrderRequest = async (payload) => {
  try {
    const res = await axios.patch(
      `orders/update/${payload.orderId}`,
      payload
    )
    return res.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const deleteOrderRequest = async (payload) => {
  try {
    const res = await axios.delete(`orders/delete/${payload}`)
    return res.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const updateStatusRequest = async (payload) => {
  try {
    const res = await axios.patch(
      `orders/statusUpdate/${payload.orderId}`,
      payload
    )
    return res.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const updateOrderBillingRequest = async (payload) => {
  try {
    const res = await axios.patch(
      `orders/placeOrder/${payload.orderId}`,
      payload
    )
    return res.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const getOrderByUserIdRequest = async (payload) => {
  try {
    const res = await axios.get(`orders/getByUserId/${payload}`)
    return res.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const notifyUserRequest = async (payload) => {
  try {
    const res = await axios.post("mail/notifyUser", payload)
    return res.data
  } catch (error) {
    console.log(error)
    throw error
  }
}
