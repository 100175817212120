import React, { useRef, useState, useEffect } from "react"
import Add from "../../../../icons/Add"
import Minus from "../../../../icons/Minus"
import { useDispatch, useSelector } from "react-redux"
import {
  uploadImage,
  getImages,
  disableImage,
} from "../../../../state/imagesSlice"

import styles from "./CarouselManagement.module.scss"
import { ShimmerThumbnail } from "react-shimmer-effects"

export default function CarouselManagement({ isMobile = false }) {
  const dispatch = useDispatch()
  const { images, isLoading } = useSelector((state) => state.images)
  const filePickerRef = useRef()
  const [imageFiles, setImageFiles] = useState([])

  const [imageUrls, setImageUrls] = useState([])

  const imageTypeRegex = /image\/(png|jpg|jpeg)/gm
  const pickedHandler = (e) => {
    const { files } = e.target

    const validImageFiles = []
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      if (file.type.match(imageTypeRegex)) {
        validImageFiles.push(file)
      }
    }

    if (validImageFiles.length) {
      setImageFiles(imageFiles.concat(validImageFiles))
      filePickerRef.current.value = ""
      const payload = {
        image: validImageFiles[0],
        usage: "carousel",
        isMobile: isMobile,
      }
      dispatch(uploadImage(payload))

      return
    }
    alert("Selected images are not of valid type!")
    filePickerRef.current.value = ""
  }

  const pickImageHandler = () => {
    filePickerRef.current.click()
  }

  const handleRemove = (id) => {
    dispatch(disableImage({ imageId: id }))
  }

  useEffect(() => {
    let urls = [...images]
    urls = urls.filter((image) => image.isMobile === isMobile)

    setImageUrls([...urls])
  }, [images, isMobile, dispatch])

  useEffect(() => {
    dispatch(getImages({ usage: "carousel" }))
  }, [dispatch])
  return (
    <div className={styles.car_main}>
      <h4>Carousel{isMobile && " - Mobile"}</h4>
      {images ? (
        <ul className={styles.hs}>
          {Object.entries(imageUrls).map(([key, image]) => {
            return (
              <li key={key} className={styles.hs}>
                <img
                  src={
                    process.env.REACT_APP_BASE_URL + image.image_url
                  }
                  alt={"c-I-" + key}
                />
                <div className={styles.remove}>
                  <Minus onClick={() => handleRemove(image._id)} />
                </div>
              </li>
            )
          })}

          {isLoading && <ShimmerThumbnail />}
        </ul>
      ) : (
        <div />
      )}
      <div className={styles.addImage_container}>
        <input
          type="file"
          className={styles.file_input}
          ref={filePickerRef}
          onChange={pickedHandler}
        />
        <Add onClick={pickImageHandler} size={15} fill={"#007bff"} />
        <p onClick={pickImageHandler}>Add image</p>
      </div>
    </div>
  )
}
