import React from "react"
import { Button } from "react-bootstrap"
import { useForm, Controller } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"

import styles from "./SignInForm.module.scss"

import { login } from "../../state/authSlice"
import Input from "../custom/input/Input"
import { getOrderByUserId } from "../../state/orderSlice"

export default function SignInForm() {
  const dispatch = useDispatch()
  const { errorMessage } = useSelector((state) => state.auth)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  })
  // localStorage.setItem("cart_key", e.payload.orderId)
  // const storageEvent = new Event("cart_key")
  // const modify_cart = new Event("modify_cart")
  // window.dispatchEvent(storageEvent)
  // window.dispatchEvent(modify_cart)

  const onSubmit = (data) => {
    const payload = {
      email: data.email,
      password: data.password,
    }
    dispatch(login(payload)).then((e) =>
      dispatch(getOrderByUserId(e.payload.user._id)).then((e) => {
        if (e.payload !== "") {
          localStorage.setItem("cart_key", e.payload.orderId)
          const storageEvent = new Event("cart_key")
          const modify_cart = new Event("modify_cart")
          window.dispatchEvent(storageEvent)
          window.dispatchEvent(modify_cart)
        }
      })
    )
  }
  return (
    <form id={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="email"
        control={control}
        rules={{ required: "Email required" }}
        render={({ field: { onChange, onBlur, value } }) => {
          return (
            <Input
              errors={errors?.email?.message}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              type="email"
              placeholder="Email"
            />
          )
        }}
      />
      <Controller
        name="password"
        control={control}
        rules={{ required: "Password required" }}
        render={({ field: { onChange, onBlur, value } }) => {
          return (
            <Input
              errors={errors?.password?.message}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              type="password"
              placeholder="Password"
            />
          )
        }}
      />
      {errorMessage && <p className={styles.error}>{errorMessage}</p>}
      <Button type="submit" className={styles.btn}>
        Sign in
      </Button>
    </form>
  )
}
