import React, { useState, useRef, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Header from "../../components/Header/Header"
import Footer from "../../components/footer/Footer"
import { Helmet, HelmetProvider } from "react-helmet-async"
import styles from "./CartPage.module.scss"
import Cart from "../../components/orders/cart/Cart"
import { useSelector } from "react-redux"

import Billing from "../../components/orders/cart/billing/Billing"

export default function CartPage() {
  const childRef = useRef(null)
  const params = useParams()
  const navigate = useNavigate()
  const [paymentOption, setPaymentOption] = useState("")
  const { order } = useSelector((state) => state.orders)
  const { currentCountry } = useSelector((state) => state.auth)

  // useEffect(() => {
  //   if (isLoading) {
  //     return
  //   }
  //   const t = contact.shipment[0].city

  //   const tempCity = contact.shipment.find((item) => item.city === t)
  //   setPrice(tempCity.price)
  // }, [isLoading])

  function render() {
    if (params.step === "cart") {
      return <Cart order={order} />
    } else if (params.step === "billing-address") {
      return (
        <Billing
          order={order}
          ref={childRef}
          paymentOption={paymentOption}
          placeOrder={placeOrder}
        />
      )
    } else {
      return
    }
  }

  function placeOrder() {
    if (childRef.current && paymentOption !== "") {
      childRef.current.onSubmit()
    }
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Compuone | Cart</title>
        </Helmet>
      </HelmetProvider>
      <Header />
      <main className={styles.main}>
        <div className={styles.steps}>
          <h4 className={params.step === "cart" ? styles.selected : ""}>
            Cart
          </h4>
          <h4>{">"}</h4>
          <h4
            className={params.step === "billing-address" ? styles.selected : ""}
          >
            Billing Address
          </h4>
          <h4 className={params.step === "payment" ? styles.selected : ""}>
            {" "}
            {">"}
          </h4>
          <h4>Payment</h4>
        </div>
        <div className={styles.render}>
          <div className={styles.mainContainer}>
            {render()}
            <div className={styles.prices}>
              <div className={styles.container}>
                <div className={styles.prices_header}>
                  <h6>Cart Summary</h6>
                </div>
                <div className={styles.prices_details}>
                  <p>Subtotal</p>
                  {Object.getOwnPropertyNames(order).length !== 0 && (
                    <p>$ {order.totalPrice}</p>
                  )}
                </div>
                <div className={styles.prices_details}>
                  <p>
                    Shipping fees will be specified in the confirmation email
                  </p>
                </div>
                <div className={styles.prices_details}>
                  <p>Total</p>
                  <p>$ {order.totalPrice}</p>
                </div>
                {params.step === "billing-address" && (
                  <form>
                    <div className={styles.selector}>
                      <input
                        onChange={(e) => {
                          setPaymentOption(e.target.value)
                        }}
                        checked={paymentOption === "cod"}
                        value={"cod"}
                        type="checkbox"
                      />
                      <p>Cash on Delivery</p>
                    </div>
                    {currentCountry !== "Lebanon" &&
                      currentCountry !== "United Arab Emirates" && (
                        <div className={styles.selector}>
                          <input
                            onChange={(e) => {
                              setPaymentOption(e.target.value)
                            }}
                            checked={paymentOption === "po"}
                            value={"po"}
                            type="checkbox"
                          />
                          <p>Pay Online</p>
                        </div>
                      )}
                  </form>
                )}
                {params.step === "payment" || (
                  <div
                    onClick={() => {
                      if (params.step === "cart") {
                        navigate(`/cart/${order.orderId}/billing-address`)
                      } else if (params.step === "billing-address") {
                        placeOrder()
                      }
                    }}
                    className={styles.prices_btn}
                  >
                    {params.step === "cart" && <p>Checkout</p>}
                    {params.step === "billing-address" && <p>Place Order</p>}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  )
}
