import React from 'react'
import styles from './Card.module.scss'
import Windows from '../../../icons/Windows'
import Linux from '../../../icons/Linux'
import Mac from '../../../icons/Mac'

export default function Card(props) {
  const driver = props.driver

  return (
    <div className={styles.main_card}>
      {/* <div className={styles.main_image}>
        <img
          className={styles.image}
          src={process.env.REACT_APP_BASE_URL + driver.imageUrl}
        />
      </div> */}
      <div className={styles.content}>
        <div>
          <h1 className={styles.driver_name}>{driver.name}</h1>
          <p className={styles.driver_description}>{driver.description}</p>
        </div>
        <div className={styles.driver_lower}>
          <hr className={styles.separator} />
          <div className={styles.driver_os}>
            <div>
              <em className={styles.brand}>{driver.brand}</em>

              {driver.version !== undefined && (
                <p className={styles.version}>V. {driver.version}</p>
              )}
            </div>
            <div className={styles.os_icons}>
              {driver.windows !== '' && (
                <a className={styles.os} href={driver.windows} target="_blank">
                  <Windows
                    style={{ cursor: 'pointer' }}
                    height="20px"
                    width="20px"
                  />
                </a>
              )}
              {driver.linux !== '' && (
                <a className={styles.os} href={driver.linux} target="_blank">
                  <Linux style={{ cursor: 'pointer' }} height="20px" />
                </a>
              )}
              {driver.mac !== '' && (
                <a className={styles.os} href={driver.mac} target="_blank">
                  <Mac style={{ cursor: 'pointer' }} height="20px" />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
