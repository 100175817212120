import React, { useState } from "react"
import Drawer from "react-modern-drawer"
import "react-modern-drawer/dist/index.css"
import styles from "./DrawerBody.module.scss"
import { useNavigate } from "react-router-dom"

import Close from "../../../icons/Close"
import { useSelector } from "react-redux"
import { formatUrl } from "../../../helpers/Formats"

export default function DrawerBody(props) {
  let navigate = useNavigate()

  const toggleDrawer = () => {
    props.toggleDrawer(false)
  }

  const { categories } = useSelector((state) => state.categories)
  return (
    <Drawer
      size={"100%"}
      open={props.isOpen}
      onClose={toggleDrawer}
      direction="top">
      <div className={styles.main}>
        <div className={styles.close_container}>
          <Close
            onClick={toggleDrawer}
            height={"20px"}
            width={"20px"}
            fill={"white"}
          />
        </div>
        <ul className={styles.list}>
          {Object.entries(categories).map(([key, value]) => {
            if (value.name === "Support") {
              return (
                <details>
                  <summary className={styles.list_item}>
                    {value.name}
                  </summary>
                  <p
                    onClick={() => {
                      toggleDrawer()
                      navigate("/products/support/drivers/1")
                    }}
                    className={styles.list_item_s}>
                    Drivers
                  </p>
                  <p
                    onClick={() => {
                      toggleDrawer()
                      navigate("/products/support/manual/1")
                    }}
                    className={styles.list_item_s}>
                    Manual
                  </p>
                </details>
              )
            } else {
              return (
                <li
                  key={key}
                  onClick={() => {
                    toggleDrawer()
                    let category = formatUrl(value.name)
                    navigate(`/${category}/1`)
                  }}
                  className={styles.list_item}>
                  {value.name}
                </li>
              )
            }
          })}
        </ul>
      </div>
    </Drawer>
  )
}
