import React, { useState, useRef, useEffect } from "react"
import styles from "./AddCatalogues.module.scss"
import TransInput from "../../custom/transInput/TransInput"

import { Button } from "react-bootstrap"
import BrandList from "../addProduct/brands/BrandList"
import { useDispatch, useSelector } from "react-redux"
import { Controller, useForm } from "react-hook-form"
import {
  addCatalogue,
  resetCatalogue,
  updateCatalogue,
  resetErrorMessage,
} from "../../../state/catalogueSlice"

export default function AddCatalogue(props) {
  const dispatch = useDispatch()
  const { catalogue, errorMessage } = useSelector(
    (state) => state.catalogues
  )
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      name: catalogue === undefined ? "" : catalogue.name,
      description:
        catalogue === undefined ? "" : catalogue.description,
      url: catalogue === undefined ? "" : catalogue.url,
      brand: catalogue === undefined ? "" : catalogue.brand,
    },
  })
  const [counter, setCounter] = useState(0)
  // const filePickerRef = useRef()
  // const [imageFile, setImageFile] = useState(undefined)
  // const [image, setImage] = useState(
  //   catalogue === undefined ? "" : catalogue.imageUrl
  // )
  // const imagetypeRegex = /image\/(png|jpg|jpeg)/gm
  // const pickedHandler = (e) => {
  //   const { files } = e.target

  //   const validImageFiles = []

  //   for (let i = 0; i < files.length; i++) {
  //     const file = files[i]

  //     if (file.type.match(imagetypeRegex)) {
  //       validImageFiles.push(file)
  //     }
  //   }

  //   if (validImageFiles.length) {
  //     setImageFile(validImageFiles[0])

  //     filePickerRef.current.value = ""

  //     return
  //   }
  //   alert("Selected images are not of valid type!")
  //   filePickerRef.current.value = ""
  // }

  // const pickImageHandler = () => {
  //   filePickerRef.current.click()
  // }

  // useEffect(() => {
  //   if (imageFile) {
  //     const reader = new FileReader()
  //     reader.onload = (e) => {
  //       const { result } = e.target
  //       if (result) {
  //         setImage(result)
  //       }
  //     }
  //     reader.readAsDataURL(imageFile)
  //   }
  // }, [imageFile])

  function submit(data) {
    const payload = {
      name: data.name,
      description: data.description,
      brand: data.brand,
      // image: imageFile,
      url: data.url,
      id: catalogue === undefined ? "" : catalogue._id,
    }
    if (props.update) {
      dispatch(updateCatalogue(payload)).then((res) => {
        props.setUpdate(false)
        props.close()
      })
      dispatch(resetCatalogue())
    } else {
      dispatch(addCatalogue(payload)).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          props.close()
        }
      })
      dispatch(resetCatalogue())
    }
  }

  const validation = isValid

  useEffect(() => {
    if (errorMessage !== "") {
      alert("An error occured")
    }
    setTimeout(() => {
      dispatch(resetErrorMessage())
    }, 1000)
  }, [errorMessage])

  return (
    <div className={styles.main}>
      <div className={styles.ct_1}>
        {/* half half */}
        {/* <div className={styles.ct_2 + " " + styles.image_ct}>
          <div
            onClick={pickImageHandler}
            className={styles.image_main}>
            <input
              ref={filePickerRef}
              type="file"
              className={styles.input_file}
              onChange={pickedHandler}
            />
            {!image && <p>Click to add an image</p>}
            {image && (
              <img
                className={styles.image}
                src={
                  image.startsWith("uploads/images")
                    ? process.env.REACT_APP_BASE_URL + image
                    : image
                }
              />
            )}
          </div>
        </div> */}
        <div className={styles.ct_2}>
          <div style={{ width: "100%" }}>
            <Controller
              control={control}
              name="name"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => {
                return (
                  <TransInput
                    value={value}
                    onChange={onChange}
                    styles={{ textAlign: "center" }}
                    placeholder="Name"
                    wide="true"
                  />
                )
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              height: "100%",
              alignItems: "center",
              gap: "1rem",
            }}>
            <div className={styles.description}>
              <Controller
                control={control}
                name="description"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <textarea
                      value={value}
                      onChange={(e) => {
                        var text = e.target.value
                        var trimmedText = text.trim()

                        // Split the text into an array of words using white space as the delimiter
                        var words = trimmedText.split(/\s+/)

                        // Return the number of words in the array
                        if (words.length > 30) return
                        setCounter(words.length)
                        onChange(e)
                      }}
                      className={styles.input}
                      placeholder="Description"
                    />
                  )
                }}
              />
              <p className={styles.counter}>{counter}/30</p>
            </div>
          </div>
        </div>
      </div>
      <Controller
        control={control}
        name="brand"
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => {
          return <BrandList value={value} onChange={onChange} />
        }}
      />

      <div style={{ height: "40px", width: "100%" }} />
      {/* list style */}
      <div style={{ width: "100%" }}>
        <Controller
          control={control}
          name="url"
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => {
            return (
              <TransInput
                value={value}
                onChange={onChange}
                placeholder="Url"
              />
            )
          }}
        />
      </div>

      <div className={styles.button_container}>
        <Button
          disabled={!validation}
          onClick={handleSubmit(submit)}
          style={{ width: "fit-content" }}>
          {props.update ? "Save Changes" : "Add Catalogue"}
        </Button>
      </div>
    </div>
  )
}
