import React from "react"

import "./global/index.scss"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { Provider } from "react-redux"
import { store } from "./state/store"
import ReactGA from "react-ga4"
import { hydrate, render } from "react-dom"

const root = document.getElementById("root")

ReactGA.initialize(process.env.REACT_APP_GA4_ID)
if (root.hasChildNodes()) {
  hydrate(
    <Provider store={store}>
      <App />
    </Provider>,
    root
  )
} else {
  render(
    <Provider store={store}>
      <App />
    </Provider>,
    root
  )
}

const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  })
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics)
