import React, { useEffect, useState } from "react"
import styles from "./BrandList.module.scss"
import Card from "./card/Card"
import { useDispatch, useSelector } from "react-redux"
import { getBrands } from "../../../../state/brandSlice"

export default function BrandList({ onChange, isLarge, value }) {
  const [selected, setSelected] = useState(value)
  const { brands } = useSelector((state) => state.brands)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getBrands())
  }, [dispatch])

  useEffect(() => {
    setSelected(value)
  }, [value])

  function select(value) {
    if (selected === value) {
      setSelected("")
      onChange("")
    } else {
      setSelected(value)
      onChange(value)
    }
  }
  return (
    <div
      className={isLarge ? styles.larger : styles.brands_container}>
      {Object.entries(brands).map(([key, brand]) => {
        return (
          <Card
            key={key}
            onClick={() => select(brand.brand)}
            selected={selected}
            brand={brand}
          />
        )
      })}
    </div>
  )
}
