import React from "react"

export default function Call({ size, href }) {
  return (
    <a href={href} target="_blank">
      <svg width={size} height={size} viewBox="0 0 512 512">
        <g>
          <circle cx="256" cy="256" r="256" fill="#2196f3"></circle>
          <path
            d="M384 308.928c-27.616 0-53.952-6.016-78.24-17.888a16.267 16.267 0 0 0-12.256-.736 16.138 16.138 0 0 0-9.184 8.16l-11.52 23.84c-34.56-19.84-63.232-48.544-83.104-83.104l23.872-11.52c3.84-1.856 6.752-5.152 8.16-9.184 1.376-4.032 1.12-8.448-.736-12.256-11.904-24.256-17.92-50.592-17.92-78.24 0-8.832-7.168-16-16-16H128c-8.832 0-16 7.168-16 16 0 149.984 122.016 272 272 272 8.832 0 16-7.168 16-16v-59.072c0-8.832-7.168-16-16-16z"
            fill="#fafafa"></path>
        </g>
      </svg>
    </a>
  )
}
