import {
  createAsyncThunk,
  createSlice,
  current,
} from "@reduxjs/toolkit"
import {
  createBrandRequest,
  deleteBrandRequest,
  getBrandsRequest,
  updateBrandRequest,
} from "../api/brand"

const initialState = {
  brands: [],
  isLoading: false,
  error: null,
}

export const addBrands = createAsyncThunk(
  "db-admin/addCategory",
  async (payload, thunkApi) => {
    try {
      const token = thunkApi.getState().auth
      const res = await createBrandRequest(token, payload)
      return res
    } catch (error) {
      console.log("addCategory" + error)
      throw error
    }
  }
)

export const getBrands = createAsyncThunk(
  "db-admin/getBrands",
  async (paylod, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.token
      const res = await getBrandsRequest(token)
      return res
    } catch (error) {
      console.log("getBrands" + error)
      throw error
    }
  }
)

export const deleteBrand = createAsyncThunk(
  "db-admin/deleteBrand",
  async (payload, thunkApi) => {
    try {
      const token = thunkApi.getState().auth
      const res = await deleteBrandRequest(token, payload)
      return res
    } catch (error) {
      console.log("deleteBrands" + error)
      throw error
    }
  }
)

export const updateBrand = createAsyncThunk(
  "db-admin/updateBrand",
  async (payload, thunkApi) => {
    try {
      const token = thunkApi.getState().auth
      const res = await updateBrandRequest(token, payload)
      return res
    } catch (error) {
      console.log("updateBrand" + error)
      throw error
    }
  }
)

export const brandsSlice = createSlice({
  name: "brands",
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addCase(addBrands.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(addBrands.fulfilled, (state, action) => {
      state.isLoading = false
      state.brands = [...current(state.brands), action.payload.brand]
    })
    builder.addCase(addBrands.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.error
    })
    builder.addCase(getBrands.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getBrands.fulfilled, (state, action) => {
      state.isLoading = false
      state.brands = action.payload.brands
    })
    builder.addCase(getBrands.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.error
    })
    builder.addCase(deleteBrand.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(deleteBrand.fulfilled, (state, action) => {
      state.isLoading = false
      state.brands = state.brands.filter(
        (brand) => brand._id !== action.payload.id
      )
    })
    builder.addCase(deleteBrand.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.error
    })
    builder.addCase(updateBrand.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(updateBrand.fulfilled, (state, action) => {
      state.isLoading = false
      state.brands = state.brands.map((brand) => {
        if (brand._id === action.payload.brand._id) {
          return action.payload.brand
        }
        return brand
      })
    })
    builder.addCase(updateBrand.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.error
    })
  },
})

export default brandsSlice.reducer
