import React, { useState } from 'react'
import styles from './Card.module.scss'
import Alert from '../../../../alert/Alert'
import TransInput from '../../../../custom/transInput/TransInput'
import { useDispatch } from 'react-redux'
import { deleteBrand, updateBrand } from '../../../../../state/brandSlice'
import Delete from '../../../../../icons/Delete'

export default function Card({ onClick, selected, brand, doubleClick }) {
  const isSelected = selected === brand.brand
  const [update, setUpdate] = useState(false)
  const [brandName, setBrand] = useState(brand.brand)
  const [brandUrl, setUrl] = useState(brand.url)
  const dispatch = useDispatch()

  const updateB = () => {
    const payload = {
      brand: brandName,
      url: brandUrl,
      id: brand._id,
    }
    dispatch(updateBrand(payload))
    setUpdate(false)
  }

  const deleteB = () => {
    dispatch(deleteBrand(brand._id))
  }

  return (
    <>
      {update && (
        <Alert
          heading={'Update a brand'}
          description={'Brands can be edited.'}
          confirmText={'Save'}
          cancelText="Cancel"
          cancel={() => setUpdate(false)}
          confirm={updateB}
        >
          <div
            style={{
              marginTop: '1rem',
            }}
          >
            <TransInput
              value={brandName}
              onChange={(e) => setBrand(e.target.value)}
              placeholder="Brand"
            />
            <div style={{ width: '100%', height: '1rem' }} />
            <TransInput
              value={brandUrl}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="Url"
            />
          </div>
        </Alert>
      )}

      <div
        onDoubleClick={() => setUpdate(true)}
        onClick={onClick}
        className={isSelected ? styles.selected : styles.brand_card}
      >
        <div className={styles.deleteButton}>
          <Delete onClick={deleteB} />
        </div>
        <p>{brand.brand}</p>
      </div>
    </>
  )
}
