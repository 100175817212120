import React, { useContext, useState } from "react"
import Analytics from "../../../icons/Analytics"
import Dashboard from "../../../icons/Dashboard"
import Products from "../../../icons/Products"
import SEO from "../../../icons/SEO"
import User from "../../../icons/User"
import { PageContext } from "../../../routes/admin/context"
import styles from "./SidePanel.module.scss"
import Tab from "./Tab"
import HamburgerMenu from "../../../icons/hamburgerMenu/HamburgerMenu"
import World from "../../../icons/World"
import Order from "../../../icons/Order"
import Categories from "../../../icons/Categories"
import Offers from "../../../icons/Offers"
import Notification from "../../../icons/Notification"

export default function SidePanel(props) {
  const { state } = useContext(PageContext)
  const [open, setOpen] = useState(false)

  return (
    <section
      id={styles.sideBar}
      className={open ? styles.open : styles.closed}>
      <div>
        {/* <HamburgerMenu change={() => setOpen(!open)} /> */}
        <h3 className={styles.header}>CompuOne</h3>
      </div>
      <ul className={styles.tabList}>
        {/* <Tab
          isOpen={open}
          page={props.page}
          onClick={props.onClick}
          icon={
            <Dashboard
              white={isDashboard ? 'transparent' : 'white'}
              primary={isDashboard ? 'white' : '#2196F3'}
              secondary={isDashboard ? 'white' : '#1976D2'}
            />
          }
        >
          Dashboard
        </Tab>
        <Tab isOpen={open} page={props.page} icon={<Analytics />}>
          Analytics
        </Tab> */}
        <Tab isOpen={open} page={props.page} icon={<SEO />}>
          SEO
        </Tab>
        <Tab isOpen={open} page={props.page} icon={<Products />}>
          Products
        </Tab>
        <Tab isOpen={open} page={props.page} icon={<User />}>
          Users
        </Tab>
        <Tab isOpen={open} page={props.page} icon={<Order />}>
          Orders
        </Tab>
        <Tab isOpen={open} page={props.page} icon={<Categories />}>
          Categories
        </Tab>
        <Tab isOpen={open} page={props.page} icon={<Offers />}>
          Offers
        </Tab>
        <Tab
          isOpen={open}
          page={props.page}
          icon={<Notification size={"20"} fill="gold" />}>
          Notifications
        </Tab>
      </ul>
    </section>
  )
}
