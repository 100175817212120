import React from "react"

export default function Categories() {
  return (
    <svg width="20" height="20" viewBox="0 0 512 512">
      <g>
        <path
          fill="#ffc107"
          d="M437.011 166.509a64.721 64.721 0 1 1 0-91.53 64.307 64.307 0 0 1 0 91.53zm10.261 24.768A64.724 64.724 0 1 0 511.991 256a64.79 64.79 0 0 0-64.719-64.719zm-56.028 135.255a64.725 64.725 0 1 0 45.767 18.96 64.261 64.261 0 0 0-45.767-18.96zM255.994 512a64.726 64.726 0 1 0-64.724-64.733A64.805 64.805 0 0 0 255.994 512zM74.977 345.492a64.713 64.713 0 1 0 45.767-18.96 64.795 64.795 0 0 0-45.767 18.96zm-10.256-24.768A64.724 64.724 0 1 0 0 256a64.793 64.793 0 0 0 64.724 64.728zM166.507 74.979a64.735 64.735 0 1 0 18.951 45.767 64.793 64.793 0 0 0-18.951-45.767zm43.719 226.779A64.717 64.717 0 1 0 191.27 256a64.567 64.567 0 0 0 18.956 45.762zm45.768-172.312a64.724 64.724 0 1 0-64.724-64.724 64.792 64.792 0 0 0 64.724 64.724z"
          opacity="1"></path>
      </g>
    </svg>
  )
}
