import React, { useEffect, useState } from "react"
import Arrow from "../../../../icons/Arrow"
import styles from "./Warranty.module.scss"

export default function Warranty(props) {
  const [count, setCount] = useState(parseInt(props.value[0]))
  const [period, setPeriod] = useState(props.value[1])

  const handleChange = (count, period) => {
    const warranty = [count, period]
    props.onChange(warranty)
  }

  const handlePeriod = () => {
    if (period === "years") {
      setPeriod("months")
      handleChange(count, "months")
    } else if (period === "months") {
      setPeriod("weeks")
      handleChange(count, "weeks")
    } else {
      setPeriod("years")
      handleChange(count, "years")
    }
  }

  useEffect(() => {
    setCount(parseInt(props.value[0]))
    setPeriod(props.value[1])
  }, [props.value])
  return (
    <div className={styles.warranty}>
      <div className={styles.arrows}>
        <Arrow
          onClick={() => {
            setCount(count + 1)
            handleChange(count + 1, period)
          }}
          style={{ transform: "rotate(180deg)" }}
        />
        <Arrow
          onClick={() => {
            if (count === 0) {
              handleChange(0, period)

              return
            }
            setCount(count - 1)
            handleChange(count - 1, period)
          }}
        />
      </div>

      <div className={styles.warrantyText}>
        <p>{count + " "}</p>
        <p onClick={handlePeriod} className={styles.period}>
          {" " + period + " "}
        </p>
        <p>waranty</p>
      </div>
    </div>
  )
}
