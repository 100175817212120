import React from 'react'

export default function Cyprus({ size, fill, onClick }) {
  return (
    <svg
      onClick={onClick}
      cursor={'pointer'}
      width={size || '30'}
      height={size || '30'}
      viewBox="0 0 512 512"
    >
      <g>
        <circle cx="256" cy="256" r="256" fill="#fcfcfc"></circle>
        <path
          fill={fill || '#6da544'}
          d="M400.696 222.609h-33.391c0 61.472-49.834 111.304-111.304 111.304-61.473 0-111.304-49.832-111.304-111.304h-33.391c0 66.215 44.476 122.042 105.186 139.235-6.656 12.644-5.642 28.563 3.866 40.428 12.488-10.009 25.417-20.37 36.385-29.158l36.385 29.158c9.602-11.983 10.547-28.104 3.67-40.809 60.045-17.615 103.898-73.11 103.898-138.854z"
        ></path>
        <path
          fill={fill || '#ffda44'}
          d="M166.957 211.478s0 55.652 55.652 55.652l11.13 11.13H256s11.13-33.391 33.391-33.391c0 0 0-22.261 22.261-22.261h33.391s-11.13-44.522 44.522-77.913l-22.261-11.13s-77.913 55.652-133.565 44.522v22.261h-22.261l-11.13-11.13z"
        ></path>
      </g>
    </svg>
  )
}
