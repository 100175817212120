import React from 'react'
import styles from './ProductsList.module.scss'

export default function ProductsList({ products }) {
  return (
    <div className={styles.sales_list}>
      {products.map((product) => (
        <div>
          <div className={styles.sales_item}>
            <img alt={product.title} className={styles.image} />
            <p>{product.title}</p>
            <div>
              <p>Lebanon</p>
            </div>
            <div>
              <p>Quantity 5</p>
            </div>
          </div>
          <hr className={styles.divider} />
        </div>
      ))}
    </div>
  )
}
