import React, { useState } from 'react'
import styles from './Pages.module.scss'

export default function Pages({ onClick }) {
  const [page, setPage] = useState('home')

  return (
    <div className={styles.pages}>
      <h3
        onClick={() => {
          setPage('home')

          onClick('home')
        }}
        className={page === 'home' ? styles.selected : null}
      >
        Home
      </h3>
      {/* <h3
        onClick={() => {
          setPage('about')

          onClick('about')
        }}
        className={page === 'about' ? styles.selected : null}
      >
        About Us
      </h3> */}
      <h3
        onClick={() => {
          setPage('contact')

          onClick('contact')
        }}
        className={page === 'contact' ? styles.selected : null}
      >
        Contact Us
      </h3>

      <h3
        onClick={() => {
          setPage('meta')

          onClick('meta')
        }}
        className={page === 'meta' ? styles.selected : null}
      >
        Meta
      </h3>
    </div>
  )
}
