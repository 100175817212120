import {
  createSlice,
  createAsyncThunk,
  current,
} from "@reduxjs/toolkit"

import {
  createProductRequest,
  getProducts,
  deleteProductRequest,
  handleVisibleRequest,
  handleDiscountRequest,
  toggleSoldOutRequest,
  incrementClicksRequest,
  updateRatingRequest,
  getSortedRequest,
  getProductsByEntry,
  getAdminProducts,
  getProductsUrlForSeoRequest,
  getProduct,
  handlePinnedRequest,
} from "../api/products"
import { requestChangePassword } from "../api/users"

const initialState = {
  products: [],
  isLoading: true,
  errorMessage: "",
  product: undefined,
  cartProducts: [],
  maxClicks: 0,
  showPrice: false,
  count: 0,
  pageCount: 0,
  topProducts: [],
  latestProducts: [],
  productsUrls: [],
}
export const fetchProducts = createAsyncThunk(
  "products/getProducts",
  async (payload, thunkApi) => {
    try {
      const productsData = await getProducts(payload)
      // let newList = productsData.productList.map((item) => {
      //   return {
      //     ...item,
      //   }
      // })
      // //sort newlist items by if isPinned is true or false
      // newList.sort((a, b) => {
      //   return b.isPinned - a.isPinned
      // })
      // console.log(newList)
      return productsData
    } catch (error) {
      console.log("fetchProducts" + error)
      throw error
    }
  }
)

export const fetchAdminProducts = createAsyncThunk(
  "products/getAdminProducts",
  async (payload, thunkApi) => {
    try {
      const productsData = await getAdminProducts(payload)
      return productsData
    } catch (error) {
      console.log("admin Products", error)
      throw error
    }
  }
)

export const fetchProduct = createAsyncThunk(
  "products/getProductById",
  async (payload, thunkApi) => {
    try {
      const productData = await getProduct(payload)

      return productData
    } catch (error) {
      console.log("fetchProduct" + error)
      throw error
    }
  }
)

export const searchProducts = createAsyncThunk(
  "db-admin/searchProducts",
  async (payload, thunkApi) => {
    try {
      const token = thunkApi.getState().auth

      const res = await getProductsByEntry(token, payload)

      return res
    } catch (error) {
      console.log("search Entry: " + error)
      throw error
    }
  }
)

// Add Product
export const addProduct = createAsyncThunk(
  "db-admin/addProduct",
  async (payload, thunkApi) => {
    try {
      const token = thunkApi.getState().auth

      const res = await createProductRequest(token, payload, false)

      return res
    } catch (error) {
      console.log("addProduct" + error)
      throw error
    }
  }
)

export const updateProduct = createAsyncThunk(
  "db-admin/updateProduct",
  async (payload, thunkApi) => {
    try {
      const token = thunkApi.getState().auth
      const res = await createProductRequest(token, payload, true)
      return res
    } catch (error) {
      console.log("updateProduct" + error)
      throw error
    }
  }
)

//delete product
export const deleteProduct = createAsyncThunk(
  "db-admin/deleteProduct",
  async (payload, thunkApi) => {
    try {
      const token = thunkApi.getState().auth
      return await deleteProductRequest(token, payload)
    } catch (error) {
      console.log("deleteProduct" + error)
      throw error
    }
  }
)

//handle visbililty
export const handleVisibility = createAsyncThunk(
  "db-admin/handleVisibility",
  async (payload, thunkApi) => {
    try {
      const token = thunkApi.getState().auth
      return await handleVisibleRequest(token, payload)
    } catch (error) {
      console.log("handleVisibilityProduct" + error)
      throw error
    }
  }
)

//handle pinned
export const handlePinned = createAsyncThunk(
  "db-admin/handlePinned",
  async (payload, thunkApi) => {
    try {
      const token = thunkApi.getState().auth
      return await handlePinnedRequest(token, payload)
    } catch (error) {
      console.log("handlePinnedProduct" + error)
      throw error
    }
  }
)

//handle discount
export const handleDiscount = createAsyncThunk(
  "db-admin/handleDiscount",
  async (payload, thunkApi) => {
    try {
      const token = thunkApi.getState().auth
      const res = await handleDiscountRequest(
        token,
        payload.id,
        payload.discount
      )
      return res
    } catch (error) {
      console.log("handleDiscountProduct" + error)
      throw error
    }
  }
)

export const handleToggleSoldOut = createAsyncThunk(
  "db-admin/toggleSoldOut",
  async (payload, thunkApi) => {
    try {
      const token = thunkApi.getState().auth

      const res = await toggleSoldOutRequest(
        token,
        payload.id,
        payload.countries
      )
      return res
    } catch (error) {
      console.log("handleToggleSoldOut" + error)
      throw error
    }
  }
)

export const incrementClicked = createAsyncThunk(
  "db-admin/incrementClicked",
  async (payload, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.token
      const res = await incrementClicksRequest(token, payload)
      return res
    } catch (error) {
      console.log("incrementClicked" + error)
      throw error
    }
  }
)

export const updateRating = createAsyncThunk(
  "db-admin/updateRating",
  async (payload, thunkApi) => {
    try {
      const res = await updateRatingRequest(
        payload.id,
        payload.rating
      )
      return res
    } catch (error) {
      console.log("updateRating" + error)
      throw error
    }
  }
)

export const getSortedProducts = createAsyncThunk(
  "products/getSortedProducts",
  async (payload, thunkApi) => {
    try {
      const productsData = await getSortedRequest(payload)
      return productsData
    } catch (error) {
      console.log("fetchProducts" + error)
      throw error
    }
  }
)

export const getProductsUrlForSeo = createAsyncThunk(
  "products/getProductsUrlForSeo",
  async (payload, thunkApi) => {
    try {
      const productsData = await getProductsUrlForSeoRequest()
      return productsData
    } catch (error) {
      console.log("fetchProducts" + error)
      throw error
    }
  }
)

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProduct: (state, action) => {
      state.product = action.payload
    },
    resetProduct: (state, action) => {
      state.product = undefined
    },
    resetLoading: (state, action) => {
      state.isLoading = true
    },
    reactivateLoading: (state, action) => {
      state.isLoading = false
    },
    resetErrorMessage: (state) => {
      state.errorMessage = ""
    },
    setMaxClicks: (state, action) => {
      state.maxClicks = action.payload
    },
    setShowPrice: (state, action) => {
      state.showPrice = action.payload
    },
  },

  extraReducers: (builder) => {
    //All
    builder.addCase(fetchProducts.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.isLoading = false

      state.products = action.payload.productList
      state.count = action.payload.pagination.count
      state.pageCount = action.payload.pagination.pageCount
    })
    builder.addCase(fetchProducts.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.payload
    })
    // Admin
    builder.addCase(fetchAdminProducts.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchAdminProducts.fulfilled, (state, action) => {
      state.products = action.payload.products
      state.count = action.payload.pagination.pageCount
      state.isLoading = false
    })
    builder.addCase(fetchAdminProducts.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.payload
    })

    //By Id
    builder.addCase(fetchProduct.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchProduct.fulfilled, (state, action) => {
      state.isLoading = false
      state.product = action.payload
    })
    builder.addCase(fetchProduct.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.payload
    })
    //search by entry
    builder.addCase(searchProducts.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(searchProducts.fulfilled, (state, action) => {
      state.isLoading = false
      state.products = action.payload.products
      state.count = action.payload.pagination.pageCount
    })
    builder.addCase(searchProducts.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.payload
    })
    //Add
    builder.addCase(addProduct.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(addProduct.fulfilled, (state, action) => {
      let newArray = [...current(state.products), action.payload]
      state.products = newArray
    })
    builder.addCase(addProduct.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.error.message
    })
    //Update
    builder.addCase(updateProduct.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(updateProduct.fulfilled, (state, action) => {
      const array = current(state.products)
      const newArray = array.map((item) => {
        if (item._id === action.payload.product._id) {
          item = action.payload.product
        }

        return item
      })
      state.products = newArray
    })
    builder.addCase(updateProduct.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.payload
    })
    //Delete
    builder.addCase(deleteProduct.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(deleteProduct.fulfilled, (state, action) => {
      const array = current(state.products)
      const newArray = array.filter(
        (item) => item._id !== action.payload._id
      )
      state.products = newArray
    })
    builder.addCase(deleteProduct.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.payload
    })
    //Visibility
    builder.addCase(handleVisibility.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(handleVisibility.fulfilled, (state, action) => {
      const array = current(state.products)
      const newArray = array.map((item) => {
        if (item._id === action.payload._id) {
          return action.payload
        }
        return item
      })
      state.isLoading = false
      state.products = newArray
    })
    builder.addCase(handleVisibility.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.payload
    })
    //Pinned
    builder.addCase(handlePinned.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(handlePinned.fulfilled, (state, action) => {
      const array = current(state.products)
      const newArray = array.map((item) => {
        if (item._id === action.payload._id) {
          return action.payload
        }
        return item
      })
      state.isLoading = false
      state.products = newArray
    })
    builder.addCase(handlePinned.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.payload
    })
    builder.addCase(handleDiscount.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(handleDiscount.fulfilled, (state, action) => {
      const array = current(state.products)
      const newArray = array.map((item) => {
        if (item._id === action.payload._id) {
          return action.payload
        }
        return item
      })
      state.isLoading = false
      state.products = newArray
    })
    builder.addCase(handleDiscount.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.payload
    })
    builder.addCase(handleToggleSoldOut.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(
      handleToggleSoldOut.fulfilled,
      (state, action) => {
        const array = current(state.products)
        const newArray = array.map((item) => {
          if (item._id === action.payload._id) {
            return action.payload
          }
          return item
        })
        state.isLoading = false
        state.products = newArray
      }
    )
    builder.addCase(handleToggleSoldOut.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.payload
    })
    builder.addCase(incrementClicked.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(incrementClicked.fulfilled, (state, action) => {
      const array = current(state.products)
      const newArray = array.map((item) => {
        if (item._id === action.payload._id) {
          return action.payload
        }
        return item
      })
      state.isLoading = false
      state.products = newArray
    })
    builder.addCase(incrementClicked.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.payload
    })
    builder.addCase(getSortedProducts.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getSortedProducts.fulfilled, (state, action) => {
      state.isLoading = false

      state.topProducts = action.payload.topProducts
      state.latestProducts = action.payload.latestProducts
    })
    builder.addCase(getSortedProducts.rejected, (state, action) => {
      state.isLoading = false
      state.errorMessage = action.payload.error
    })
    builder.addCase(getProductsUrlForSeo.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(
      getProductsUrlForSeo.fulfilled,
      (state, action) => {
        state.isLoading = false
        state.productsUrls = action.payload
      }
    )
    builder.addCase(
      getProductsUrlForSeo.rejected,
      (state, action) => {
        state.isLoading = false
        state.errorMessage = action.payload
      }
    )
  },
})

export const {
  resetProduct,
  setProduct,
  resetLoading,
  reactivateLoading,
  resetErrorMessage,
  setMaxClicks,
  setShowPrice,
} = productSlice.actions

export default productSlice.reducer
