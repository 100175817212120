import React, { useState, useRef, useEffect } from "react"
import styles from "./AddDriver.module.scss"
import TransInput from "../../custom/transInput/TransInput"
import Windows from "../../../icons/Windows"
import Linux from "../../../icons/Linux"
import Mac from "../../../icons/Mac"
import { Button } from "react-bootstrap"
import BrandList from "../addProduct/brands/BrandList"
import { useDispatch, useSelector } from "react-redux"
import { Controller, useForm } from "react-hook-form"
import {
  addDriver,
  resetDriver,
  updateDriver,
} from "../../../state/driversSlice"

export default function AddDriver(props) {
  const dispatch = useDispatch()
  const { driver } = useSelector((state) => state.drivers)
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      name: driver === undefined ? "" : driver.name,
      description: driver === undefined ? "" : driver.description,
      version: driver === undefined ? "" : driver.version,
      windows: driver === undefined ? "" : driver.windows,
      linux: driver === undefined ? "" : driver.linux,
      mac: driver === undefined ? "" : driver.mac,
      brand: driver === undefined ? "" : driver.brand,
    },
  })
  const [counter, setCounter] = useState(0)
  const filePickerRef = useRef()
  // const [imageFile, setImageFile] = useState(undefined)
  // const [image, setImage] = useState(
  //   driver === undefined ? '' : driver.imageUrl,
  // )
  // const imagetypeRegex = /image\/(png|jpg|jpeg)/gm
  // const pickedHandler = (e) => {
  //   const { files } = e.target

  //   const validImageFiles = []

  //   for (let i = 0; i < files.length; i++) {
  //     const file = files[i]

  //     if (file.type.match(imagetypeRegex)) {
  //       validImageFiles.push(file)
  //     }
  //   }

  //   if (validImageFiles.length) {
  //     setImageFile(validImageFiles[0])

  //     filePickerRef.current.value = ''

  //     return
  //   }
  //   alert('Selected images are not of valid type!')
  //   filePickerRef.current.value = ''
  // }

  // const pickImageHandler = () => {
  //   filePickerRef.current.click()
  // }

  // useEffect(() => {
  //   if (imageFile) {
  //     const reader = new FileReader()
  //     reader.onload = (e) => {
  //       const { result } = e.target
  //       if (result) {
  //         setImage(result)
  //       }
  //     }
  //     reader.readAsDataURL(imageFile)
  //   }
  // }, [imageFile])

  function submit(data) {
    const payload = {
      name: data.name,
      description: data.description,
      version: data.version,
      windows: data.windows,
      linux: data.linux,
      mac: data.mac,
      brand: data.brand,
      // image: imageFile,
      version: data.version,
      id: driver === undefined ? "" : driver._id,
    }
    if (props.update) {
      dispatch(updateDriver(payload)).then((res) => {
        props.setUpdate(false)
        props.close()
      })
      dispatch(resetDriver())
    } else {
      dispatch(addDriver(payload)).then((res) => {
        props.close()
      })
      dispatch(resetDriver())
    }
  }

  const validation = isValid

  return (
    <div className={styles.main}>
      <div className={styles.ct_1}>
        {/* half half */}
        {/* <div className={styles.ct_2 + ' ' + styles.image_ct}>
          <div onClick={pickImageHandler} className={styles.image_main}>
            <input
              ref={filePickerRef}
              type="file"
              className={styles.input_file}
              onChange={pickedHandler}
            />
            {!image && <p>Click to add an image</p>}
            {image && (
              <img
                className={styles.image}
                src={
                  image.startsWith('uploads/images')
                    ? process.env.REACT_APP_BASE_URL + image
                    : image
                }
              />
            )}
          </div>
        </div> */}
        <div className={styles.ct_2}>
          <div style={{ width: "100%" }}>
            <Controller
              control={control}
              name="name"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => {
                return (
                  <TransInput
                    value={value}
                    onChange={onChange}
                    styles={{ textAlign: "center" }}
                    placeholder="Name"
                    wide="true"
                  />
                )
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              height: "100%",
              alignItems: "center",
              gap: "1rem",
            }}>
            <div className={styles.description}>
              <Controller
                control={control}
                name="description"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <textarea
                      value={value}
                      onChange={(e) => {
                        var text = e.target.value
                        var trimmedText = text.trim()

                        // Split the text into an array of words using white space as the delimiter
                        var words = trimmedText.split(/\s+/)

                        // Return the number of words in the array
                        if (words.length > 30) return
                        setCounter(words.length)
                        onChange(e)
                      }}
                      className={styles.input}
                      placeholder="Description"
                    />
                  )
                }}
              />
              <p className={styles.counter}>{counter}/30</p>
            </div>
            <div className={styles.version}>
              <Controller
                control={control}
                name="version"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <TransInput
                      value={value}
                      onChange={onChange}
                      placeholder="Version"
                    />
                  )
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Controller
        control={control}
        name="brand"
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => {
          return <BrandList value={value} onChange={onChange} />
        }}
      />

      <div style={{ height: "40px", width: "100%" }} />
      {/* list style */}
      <div className={styles.ct_1 + " " + styles.list}>
        <div className={styles.url_card}>
          <Windows width="25px" height="25px" />
          <Controller
            control={control}
            name="windows"
            render={({ field: { onChange, value } }) => {
              return (
                <TransInput
                  value={value}
                  onChange={onChange}
                  placeholder="Url for Windows"
                />
              )
            }}
          />
        </div>
        <div className={styles.url_card}>
          <Linux width="25px" height="25px" />
          <Controller
            control={control}
            name="linux"
            render={({ field: { onChange, value } }) => {
              return (
                <TransInput
                  value={value}
                  onChange={onChange}
                  placeholder="Url for Linux"
                />
              )
            }}
          />
        </div>
        <div className={styles.url_card}>
          <Mac width="25px" height="25px" />
          <Controller
            control={control}
            name="mac"
            render={({ field: { onChange, value } }) => {
              return (
                <TransInput
                  value={value}
                  onChange={onChange}
                  placeholder="Url for Mac"
                />
              )
            }}
          />
        </div>
      </div>
      <div className={styles.button_container}>
        <Button
          disabled={!validation}
          onClick={handleSubmit(submit)}
          style={{ width: "fit-content" }}>
          {props.update ? "Save Changes" : "Add Driver"}
        </Button>
      </div>
    </div>
  )
}
