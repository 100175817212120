import React from 'react'

export default function Mac(props) {
  return (
    <svg {...props} viewBox="0 0 48 48">
      <g>
        <path
          d="M12.955 34.214a2 2 0 0 1 1.9-3.521 19.314 19.314 0 0 0 7.448 2.219 34.8 34.8 0 0 1 .904-6.288h-5.163v-2c0-6.043 4.117-16.491 5.887-20.706H4c-2.2 0-4 1.8-4 4V40.08c0 2.2 1.8 4 4 4h19.895a26.845 26.845 0 0 1-1.543-7.148 23.275 23.275 0 0 1-9.397-2.718zm-.768-19.452a1.72 1.72 0 0 1 3.438 0v2.5a1.719 1.719 0 1 1-3.438 0z"
          fill="#2b2b2b"
        ></path>
        <path
          d="M44.002 3.918H28.285c-.672 1.534-5.213 12.064-6.093 18.706h6.563l-1.012 2.701c-.02.052-1.15 3.2-1.412 7.523a19.262 19.262 0 0 0 7.188-2.36 2 2 0 1 1 1.978 3.477 23.237 23.237 0 0 1-9.127 2.899c.175 2.045.604 4.199 1.445 6.327.116.293.149.596.127.89H44c2.2 0 4-1.8 4-4V7.918c.002-2.2-1.798-4-3.998-4zm-7.773 13.375a1.72 1.72 0 0 1-3.439 0v-2.5a1.719 1.719 0 1 1 3.439 0z"
          fill="#2b2b2b"
        ></path>
      </g>
    </svg>
  )
}
