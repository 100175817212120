import React, { useRef } from 'react'

import EmailEditor from 'react-email-editor'
import styles from './Analytics.module.scss'
// import { useAnalyticsApi } from 'react-use-analytics-api'

export default function Analytics() {
  const emailEditorRef = useRef(null)
  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data) => {})
  }

  const onReady = () => {
    // editor is ready
    // you can load your template here;
    // const templateJson = {};
    // emailEditorRef.current.editor.loadDesign(templateJson);
  }
  return (
    <div className={styles.main}>
      <div>
        <button onClick={exportHtml}>Export HTML</button>
      </div>

      <div className={styles.editor}>
        <EmailEditor
          style={{ width: '100%' }}
          ref={emailEditorRef}
          onReady={onReady}
        />
      </div>
    </div>
  )
}
