import React from "react"
import NotFound from "../../../../icons/NotFound"
import styles from "./CatalogueList.module.scss"
import Header from "../../../../components/admin/header/Header"
import Catalogue from "../../../../components/admin/catalogue/Catalogue"

export default function CatalogueList({ setUpdate, setVisible, catalogues }) {
  return catalogues.length === 0 ? (
    <div className={styles.notFound}>
      <NotFound />
      <h5>No Catalogues Found</h5>
    </div>
  ) : (
    <>
      <div>
        <Header categories={["Name", "Brands", "actions"]} />
        <div className={styles.cardList}>
          {Object.entries(catalogues).map(([key, catalogue]) => {
            return (
              <Catalogue
                setVisible={() => {
                  setUpdate(true)
                  setVisible(true)
                }}
                key={key}
                catalogue={catalogue}
              />
            )
          })}
        </div>
      </div>
    </>
  )
}
