import React from 'react'

export default function Minus(props) {
  return (
    <svg
      {...props}
      style={{ cursor: 'pointer' }}
      width="15"
      height="15"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M256 0C114.836 0 0 114.836 0 256s114.836 256 256 256 256-114.836 256-256S397.164 0 256 0zm112 277.332H144c-11.777 0-21.332-9.555-21.332-21.332s9.555-21.332 21.332-21.332h224c11.777 0 21.332 9.555 21.332 21.332s-9.555 21.332-21.332 21.332zm0 0"
          fill="#000000"
        ></path>
      </g>
    </svg>
  )
}
