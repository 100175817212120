import React, { useState } from 'react'
import styles from './Contacts.module.scss'
import Remove from '../../../../../../../icons/Remove'
import TransInput from '../../../../../../custom/transInput/TransInput'

export default function Contacts({ onChange, contacts }) {
  const contactsScheme = {
    prefix: '',
    example: '',
  }

  const [contact, setContact] = useState(contacts)

  const handleChangeContact = (e, i, isPrefix) => {
    const newContactList = [...contact]
    if (isPrefix) {
      newContactList[i].prefix = e.target.value
    } else {
      newContactList[i].example = e.target.value
    }
    setContact(newContactList)
    onChange(newContactList)
  }

  const handleRemoveContact = (i) => {
    const newContactList = [...contact]
    newContactList.splice(i, 1)
    setContact(newContactList)
    onChange(newContactList)
  }
  return (
    <>
      <p className={styles.hint_text}>Contacts:</p>
      <div className={styles.contact_input_list}>
        {contact.map((item, index) => (
          <div key={index} className={styles.contact_input}>
            <Remove onClick={() => handleRemoveContact(index)} size={20} />
            <TransInput
              value={item.prefix}
              class={styles.input_m}
              placeholder="Prefix"
              onChange={(e) => handleChangeContact(e, index, true)}
            />
            <p>:</p>
            <TransInput
              value={item.example}
              class={styles.input_m}
              placeholder="Example"
              onChange={(e) => handleChangeContact(e, index, false)}
            />
          </div>
        ))}
      </div>
      <div className={styles.add_button}>
        <em onClick={() => setContact([...contact, contactsScheme])}>Add</em>
      </div>
    </>
  )
}
