import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react"
import styles from "./Billing.module.scss"
import Input from "../../../custom/input/Input"
import { useDispatch, useSelector } from "react-redux"
import { getCities } from "../../../../helpers/Orders"
import { Controller, useForm } from "react-hook-form"
import { updateOrderBilling } from "../../../../state/orderSlice"
import { useNavigate } from "react-router-dom"

const Billing = forwardRef(({ order, paymentOption }, ref) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { currentCountry, user_id } = useSelector(
    (state) => state.auth
  )
  const [isError, setIsError] = useState(false)

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      emailAddress: "",
      country: currentCountry,
      address: "",
      city: "",
      description: "",
    },
  })
  let cities = getCities(currentCountry)

  useEffect(() => {
    setValue("country", currentCountry)
    if (cities.length !== 0) setValue("city", cities[0].name)
  }, [currentCountry])

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        setIsError(false)
      }, 5000)
    }
  }, [isError])

  function onSubmit(data) {
    if (!paymentOption) {
      return
    }
    const payload = {
      billingAddress: data,
      orderId: order.orderId,
      paymentOption: paymentOption,
      userId: user_id,
      description: data.description,
    }

    dispatch(updateOrderBilling(payload)).then(() => {
      localStorage.removeItem("cart_key")
      navigate(`/cart/${order.orderId}/payment`)
    })
  }

  useImperativeHandle(ref, () => ({
    onSubmit: () => {
      //check if object errors is empty

      if (Object.keys(errors).length !== 0) {
        setIsError(true)
        return
      }
      handleSubmit(onSubmit)()
    },
  }))

  return (
    <div className={styles.formContainer}>
      <h3>Billing & Shipping</h3>
      <form className={styles.billingForm}>
        <div className={styles.double}>
          <Controller
            name="firstName"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value, onBlur } }) => (
              <div>
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="First Name"
                />
              </div>
            )}
          />

          <Controller
            name="lastName"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value, onBlur } }) => (
              <div>
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="Last Name"
                />
              </div>
            )}
          />
        </div>
        <div className={styles.double}>
          <Controller
            name="phoneNumber"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value, onBlur } }) => (
              <div>
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="Phone Number"
                />
              </div>
            )}
          />
          <Controller
            name="emailAddress"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value, onBlur } }) => (
              <div>
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="Email Address"
                />
              </div>
            )}
          />
        </div>

        <div>
          <h6>Country</h6>

          <Controller
            control={control}
            name="country"
            render={({ field: { onChange, value, onBlur } }) => (
              <p>{value}</p>
            )}
          />
          <Controller
            control={control}
            name="city"
            rules={{ required: true }}
            render={({ field: { onChange, value, onBlur } }) => (
              <select
                value={value}
                onChange={(e) => {
                  onChange(e)
                }}
                className={styles.select}>
                {currentCountry !== "" &&
                  Object.entries(cities).map(([key, value]) => {
                    return (
                      <option className={styles.option} key={key}>
                        {value.name}
                      </option>
                    )
                  })}
              </select>
            )}
          />
        </div>
        <Controller
          control={control}
          name="address"
          rules={{ required: true }}
          render={({ field: { onChange, value, onBlur } }) => (
            <textarea
              onChange={onChange}
              value={value}
              placeholder="Address"
              className={styles.textarea}
            />
          )}
        />
        <Controller
          control={control}
          name="description"
          rules={{ required: false }}
          render={({ field: { onChange, value, onBlur } }) => (
            <textarea
              onChange={onChange}
              value={value}
              placeholder="Description (Optional)"
              className={styles.textarea}
            />
          )}
        />
      </form>

      {isError && (
        <span className={styles.errorMessage}>
          Please fill all required fields
        </span>
      )}
    </div>
  )
})

export default Billing
