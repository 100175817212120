import { createContext } from "react"
export const PageContext = createContext()
const page = window.localStorage.getItem("page")

export const initialState = {
  page: page || "seo",
}

export function reducer(state, action) {
  switch (action.type) {
    case "users":
      return { page: "users" }
    case "seo":
      return { page: "seo" }

    case "products":
      return { page: "products" }
    case "orders":
      return { page: "orders" }
    case "categories":
      return { page: "categories" }
    case "offers":
      return { page: "offers" }
    case "notifications":
      return { page: "notifications" }
    default:
      return {
        page: page ? page : "products",
      }
  }
}
