import React, { useRef, useState, useEffect, useMemo } from "react"
import styles from "./Image.module.scss"

import Computer from "../../../../icons/Computer"
import ImageZoomer from "../../../imageZoomer/ImageZoomer"

export default function Image(props) {
  let url_images = useMemo(() => [...props.images], [props.images])

  const filePickerRef = useRef()
  const [imageFiles, setImageFiles] = useState([])
  const [allImages, setImages] = useState(
    props.value === null ? [] : props.value
  )

  const imageTypeRegex = /image\/(jpeg|png|gif|bmp|webp)/gm

  const pickedHandler = (e) => {
    const { files } = e.target

    const validImageFiles = []

    for (let i = 0; i < files.length; i++) {
      const file = files[i]

      if (file.type.match(imageTypeRegex)) {
        validImageFiles.push(file)
      }
    }

    if (validImageFiles.length) {
      setImageFiles(imageFiles.concat(validImageFiles))

      filePickerRef.current.value = ""

      props.onChangeFile(imageFiles.concat(validImageFiles))

      return
    }
    alert("Selected images are not of valid type!")
    filePickerRef.current.value = ""
  }

  const pickImageHandler = () => {
    filePickerRef.current.click()
  }

  const handleRemove = (key) => {
    const pos = key - props.size

    const fileList = imageFiles
    const images = allImages
    fileList.splice(pos, 1)

    if (key < url_images.length) {
      url_images.splice(key, 1)
    }

    images.splice(key, 1)
    setImages(images)
    setImageFiles(fileList)
    props.onChangeFile(fileList)
    props.onChangeLink(images)
  }

  useEffect(() => {
    const images = [...url_images],
      fileReaders = []

    let isCancel = false

    if (imageFiles.length) {
      imageFiles.forEach((file) => {
        const fileReader = new FileReader()
        fileReaders.push(fileReader)
        fileReader.onload = (e) => {
          const { result } = e.target
          if (result) {
            images.push(result)
          }
          if (!isCancel) {
            setImages(images)
          }
        }
        fileReader.readAsDataURL(file)
      })
    } else {
      setImages(images)
    }

    return () => {
      isCancel = true
      fileReaders.forEach((fileReader) => {
        if (fileReader.readyState === 1) {
          fileReader.abort()
        }
      })
    }
  }, [imageFiles, url_images])

  return (
    <div className={styles.mainContainer}>
      <div className={styles.mainImage}>
        <input
          multiple
          ref={filePickerRef}
          id="image"
          className={styles.imageInput}
          type="file"
          accept=".jpg,.jpeg,.png"
          onChange={pickedHandler}
        />
        {allImages.length === 0 ? (
          <div className={styles.noImage}>
            <div onClick={pickImageHandler} className={styles.button}>
              <Computer />
              <p className={styles.buttonText}>
                Import from computer
              </p>
            </div>
          </div>
        ) : (
          <ImageZoomer
            isAdmin="true"
            handleRemove={handleRemove}
            magnifierWidth={450}
            images={allImages}
            refClick={pickImageHandler}
          />
        )}
      </div>
    </div>
  )
}
