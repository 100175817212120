import axios from "axios"

export const getCountriesRequest = async () => {
  try {
    const res = await axios.get("countries/get")
    return res.data
  } catch (error) {
    throw error
  }
}

export const updateCountryRequest = async (payload) => {
  try {
    const res = await axios.patch("countries/update", payload)
    return res.data
  } catch (error) {
    throw error
  }
}

export const addCountryRequest = async (payload) => {
  try {
    const res = await axios.post("countries/add", payload)
    return res.data
  } catch (error) {
    throw error
  }
}

export const getCountryRequest = async (payload) => {
  try {
    const res = await axios.get(`countries/get/${payload.country}`)
    return res.data
  } catch (error) {
    throw error
  }
}
