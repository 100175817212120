import React from 'react'

export default function Order(props) {
  return (
    <svg
      width={props.size || '20'}
      height={props.size || '20'}
      viewBox="0 0 512 512"
    >
      <g>
        <path
          fill="#4abbef"
          d="m469.31 68.36-29.26 292.57H117.954L88.694 68.36z"
        ></path>
        <path
          fill="#009be5"
          d="m469.31 68.36-29.26 292.57H273.86V68.36z"
        ></path>
        <path
          fill="#83e470"
          d="M512 109.72c0 60.59-49.12 109.71-109.71 109.71-60.6 0-109.72-49.12-109.72-109.71C292.57 49.12 341.69 0 402.29 0 462.88 0 512 49.12 512 109.72z"
        ></path>
        <path
          fill="#01b763"
          d="M512 109.72c0 60.59-49.12 109.71-109.71 109.71V0C462.88 0 512 49.12 512 109.72z"
        ></path>
        <path
          fill="#f3fdff"
          d="m459.7 91.95-57.41 57.41-9.95 9.95-47.47-47.47 21.22-21.21 26.25 26.25 9.95-9.95 36.19-36.19z"
        ></path>
        <path
          fill="#d7f3f7"
          d="m459.7 91.95-57.41 57.41v-42.43l36.19-36.19z"
        ></path>
        <path fill="#224370" d="M327.923 275.429h30v30h-30z"></path>
        <path fill="#39689e" d="M258.86 275.43h30v30h-30z"></path>
        <path fill="#224370" d="M273.86 275.43h15v30h-15z"></path>
        <path fill="#39689e" d="M189.798 275.429h30v30h-30z"></path>
        <path
          fill="#39689e"
          d="M98.87 393c-.4 11.04 8.47 20.14 19.42 20.14h323.38c.03 0 .04.02.04.04v29.92c0 .02-.01.04-.04.04H119.15c-27.34 0-50.15-21.83-50.29-49.17-.11-21.65 13.77-40.11 33.1-46.9.02-.01.03-.02.03-.04L70.29 30.04c-.01-.02-.02-.04-.04-.04H.04c-.02 0-.04-.02-.04-.04V.04C0 .02.02 0 .04 0H97.4c.02 0 .03.02.04.04l34.42 344.21c0 .02.02.04.04.04h309.77c.03 0 .04.01.04.04v29.92c0 .02-.01.04-.04.04H118.8c-10.54 0-19.55 8.18-19.93 18.71z"
        ></path>
        <path
          fill="#224370"
          d="M441.71 344.33v29.92c0 .02-.01.04-.04.04H273.86v-30h167.81c.03 0 .04.01.04.04zM441.67 413.14c.03 0 .04.02.04.04v29.92c0 .02-.01.04-.04.04H273.86v-30z"
        ></path>
        <circle cx="181.568" cy="475.429" r="36.571" fill="#4abbef"></circle>
        <circle cx="364.861" cy="475.429" r="36.571" fill="#009be5"></circle>
      </g>
    </svg>
  )
}
