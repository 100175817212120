import React, { useState } from "react"
import Title from "../../../components/admin/title/Title"
import Home from "../../../components/admin/seo/pages/home/Home"
import Pages from "../../../components/admin/seo/pages/Pages"
import Meta from "../../../components/admin/seo/pages/meta/Meta"
import Contact from "../../../components/admin/seo/pages/contact/Contact"

export default function Seo() {
  const [page, setPage] = useState("home")
  const [button, setButton] = useState("")
  const handleButton = (add) => {
    setButton(add)
  }
  return (
    <section id={"mainContainer"}>
      <Title buttonContent={button} isButton={button}>
        SEO
      </Title>
      <Pages onClick={setPage} />
      {page === "home" && <Home />}
      {page === "meta" && <Meta handleButton={handleButton} />}
      {/* {page === 'about' && <About />} */}
      {page === "contact" && <Contact />}
    </section>
  )
}
