import React from 'react'
import Loading from 'react-loading'
import styles from './CustomLoading.module.scss'

export default function CustomLoading() {
  return (
    <div className={styles.main_loading}>
      <div className={styles.loading_header}>
        <h1>Loading</h1>
        <Loading type="balls" height={25} width={25} color="black" />
      </div>
      <Loading type="spin" height={'20px'} width={'20px'} color="grey" />
    </div>
  )
}
