import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import Add from "../../../../../icons/Add"
import { addContact, getContact } from "../../../../../state/seoSlice"
import Card from "./card/Card"
import styles from "./Contact.module.scss"

export default function Contact() {
  const dispatch = useDispatch()
  const { contacts } = useSelector((state) => state.seo)
  useEffect(() => {
    dispatch(getContact())
  }, [dispatch])

  function render() {
    if (contacts) {
      return Object.entries(contacts).map(([key, value]) => (
        <Card key={key} id={key} cardData={value} />
      ))
    } else {
      ;<></>
    }
  }

  return (
    <main id={styles.main}>
      <div className={styles.contact_card_list}>
        {render()}

        <div className={styles.add_button}>
          <Add onClick={() => dispatch(addContact())} size={20} />
        </div>
      </div>
    </main>
  )
}
