import axios from "axios"

///Cards
export const getCardsRequest = async () => {
  try {
    const res = await axios.get("seo/getCards")
    return res.data
  } catch (error) {
    throw error
  }
}

export const updateCardsRequest = async (payload) => {
  try {
    const res = await axios.patch("seo/updateCards", { payload })
    return res.data
  } catch (error) {
    throw error
  }
}

///Meta
export const getMetaRequest = async () => {
  try {
    const res = await axios.get("seo/getMeta")
    return res.data
  } catch (error) {
    throw error
  }
}

export const addMetaRequest = async (token) => {
  try {
    const res = await axios.post("seo/addMeta", token)
    return res.data
  } catch (error) {
    throw error
  }
}

export const updateMetaRequest = async (payload, token) => {
  try {
    const res = await axios.patch("seo/updateMeta", {
      payload,
      token,
    })
    return res.data
  } catch (error) {
    throw error
  }
}

//Contact
export const getContactRequest = async () => {
  try {
    const res = await axios.get("seo/getContacts")
    return res.data
  } catch (error) {
    throw error
  }
}

export const addContactRequest = async (token) => {
  try {
    const res = await axios.post("seo/addContact", token)
    return res.data
  } catch (error) {
    throw error
  }
}

export const updateContactRequest = async (payload, token) => {
  try {
    const data = payload
    const formData = new FormData()
    formData.append("image", data.image)
    formData.append("city", data.city)
    formData.append("adressLine1", data.adressLine1)
    formData.append("adressLine2", data.adressLine2)
    formData.append("googleMapsLocation", data.googleMapsLocation)
    formData.append("mondayFriday", data.mondayFriday)
    formData.append("saturday", data.saturday)
    formData.append("contacts", JSON.stringify(data.contacts))
    formData.append("id", data.id)
    formData.append("countries", JSON.stringify(data.countries))
    formData.append("token", JSON.stringify(token))
    formData.append("shipment", JSON.stringify(data.shipment))
    formData.append("tiktok", data.tiktok)
    formData.append("instagram", data.instagram)
    formData.append("facebook", data.facebook)
    formData.append("whatsapp", data.whatsapp)
    console.log(data.skype)
    formData.append("skype", data.skype)
    formData.append("youtube", data.youtube)
    formData.append("phoneNumber", data.phoneNumber)

    const res = await axios.patch("seo/updateContact", formData)

    return res.data
  } catch (error) {
    throw error
  }
}

export const deleteContactRequest = async (payload, token) => {
  try {
    const res = await axios.delete(`seo/deleteContact/${payload}`, {
      params: {
        ...token,
      },
    })
    return res.data
  } catch (error) {
    throw error
  }
}

export const getContactByCountryRequest = async (payload) => {
  try {
    const res = await axios.get(`seo/getContactByCountry/${payload}`)
    return res.data
  } catch (error) {
    throw error
  }
}
