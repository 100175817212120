import React from "react"
import styles from "./Catalogue.module.scss"
import Edit from "../../../icons/Edit"
import Delete from "../../../icons/Delete"
import NotVisible from "../../../icons/NotVisible"

import { useDispatch } from "react-redux"
import {
  deleteCatalogue,
  setCatalogue,
} from "../../../state/catalogueSlice"
import { handleVisible } from "../../../state/driversSlice"

export default function Catalogue(props) {
  const catalogue = props.catalogue
  console.log(catalogue)
  const dispatch = useDispatch()

  return (
    <div className={styles.mainCard}>
      {/* <div>
        <img
          className={styles.image}
          alt="catalogue_image"
          src={process.env.REACT_APP_BASE_URL + catalogue.imageUrl}
        />
      </div> */}
      <div>
        <p>{catalogue.name}</p>
      </div>
      <div>
        <p>{catalogue.brand}</p>
      </div>

      <div className={styles.action}>
        <div className={styles.non_flex} title="Edit">
          <Edit
            onClick={() => {
              props.setVisible()
              dispatch(setCatalogue(catalogue))
            }}
          />
        </div>
        <div className={styles.non_flex} title="Delete">
          <Delete
            onClick={() =>
              dispatch(deleteCatalogue({ id: catalogue._id }))
            }
          />
        </div>

        <div
          className={styles.non_flex}
          title={catalogue.isVisible ? "Hide" : "Show"}>
          <NotVisible
            fill={catalogue.isVisible ? "#999" : "#e52627"}
            onClick={() =>
              dispatch(handleVisible({ id: catalogue._id }))
            }
          />
        </div>
      </div>
    </div>
  )
}
