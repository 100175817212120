import React from 'react'

export default function CashOnDelivery(props) {
  return (
    <svg
      width={props.size || '512'}
      height={props.size || '512'}
      viewBox="0 0 32 32"
    >
      <g>
        <path
          d="m2.369 7.135 2.176-2.572a4.856 4.856 0 0 1 3.707-1.72H23.57c1.365 0 2.666.574 3.586 1.582l2.474 2.71z"
          fill={props.fill || '#000000'}
        ></path>
        <path
          d="M25.846 28.356h-.476a1 1 0 0 1 0-2h.476a3.153 3.153 0 0 0 3.153-3.153V9.074a3.15 3.15 0 0 0-.923-2.228l-1.567-1.567a3.154 3.154 0 0 0-2.23-.924H7.72c-.836 0-1.638.332-2.23.924L3.922 6.847a3.152 3.152 0 0 0-.923 2.229v14.126a3.153 3.153 0 0 0 3.153 3.153h13.477v2H6.153A5.153 5.153 0 0 1 1 23.202V9.076a5.15 5.15 0 0 1 1.51-3.644l1.567-1.567a5.158 5.158 0 0 1 3.645-1.509h16.559a5.15 5.15 0 0 1 3.642 1.509l1.567 1.568a5.154 5.154 0 0 1 1.509 3.644v14.126a5.153 5.153 0 0 1-5.153 5.153z"
          fill={props.fill || '#000000'}
        ></path>
        <path
          d="M1.999 6.356h28v2h-28zM18.052 29.707a1 1 0 0 1 0-1.414l.928-.928-.928-.928a1 1 0 0 1 1.414-1.414l1.815 1.815a.745.745 0 0 1 0 1.054l-1.815 1.815a.999.999 0 0 1-1.414 0zM8.081 22.165c-1.459-.259-2.452-1.64-2.452-3.122v-3.258c0-1.884.995-3.265 2.456-3.523a3.004 3.004 0 0 1 3.544 2.952v.437a1 1 0 0 1-2 0v-.437a1.001 1.001 0 0 0-2 0v4a1.001 1.001 0 0 0 2 0v-.258a1 1 0 0 1 2 0v.258a3.004 3.004 0 0 1-3.548 2.951zM15.999 22.213c-1.654 0-3-1.346-3-3v-4c0-1.654 1.346-3 3-3s3 1.346 3 3v4c0 1.654-1.346 3-3 3zm0-8c-.552 0-1 .449-1 1v4a1.001 1.001 0 0 0 2 0v-4c0-.551-.448-1-1-1zM22.906 22.213h-.27a2.265 2.265 0 0 1-2.265-2.265v-5.471a2.264 2.264 0 0 1 2.264-2.264h.272a3.464 3.464 0 0 1 3.464 3.464v3.071a3.465 3.465 0 0 1-3.465 3.465zm.071-2c.77 0 1.394-.624 1.394-1.394v-3.213c0-.77-.624-1.394-1.394-1.394a.606.606 0 0 0-.606.606v4.787c0 .335.272.606.606.606z"
          fill={props.fill || '#000000'}
        ></path>
      </g>
    </svg>
  )
}
