import React, { useEffect } from "react"
import styles from "./ContactPage.module.scss"
import Input from "../custom/input/Input"
import { Button } from "react-bootstrap"
import { Controller, useForm } from "react-hook-form"
import InstagramC from "../../icons/InstagramC"
import FacebookC from "../../icons/FacebookC"
import TikTokC from "../../icons/TikTokC"
import { useDispatch, useSelector } from "react-redux"

import Card from "./card/Card"
import Loading from "react-loading"
import { contactUs } from "../../state/authSlice"

export default function ContactPage() {
  const { contact } = useSelector((state) => state.seo)
  const dispatch = useDispatch()

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fullName: "",
      email: "",
      subject: "",
      message: "",
    },
  })
  const onSubmit = (data) => {
    dispatch(contactUs(data))
  }

  return (
    <main id={styles.main}>
      <h3>Our Stores</h3>
      <div className={styles.main_container}>
        {contact ? (
          <Card item={contact} />
        ) : (
          <>
            <Loading />
          </>
        )}
      </div>
      <div className={styles.social_container}>
        <div title="CompuOne | TikTok" className={styles.social_link}>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.instagram.com/compuone/">
            <TikTokC size={25} />
          </a>
        </div>
        <div
          title="CompuOne | Instagram"
          className={styles.social_link}>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.instagram.com/compuone/">
            <InstagramC size={25} />
          </a>
        </div>
        <div
          title="CompuOne | Facebook"
          className={styles.social_link}>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.facebook.com/compuonelb/">
            <FacebookC size={25} fill={"white"} />
          </a>
        </div>
      </div>
      <hr />
      <div className={styles.contact}>
        <h3>Want to talk?</h3>

        <p className={styles.contact_n}>
          For all your inquiries about product specifications,
          returns, exchanges, or any tech-related questions, don't
          <br />
          hesitate to reach out to our knowledgeable customer support
          team. You can drop us an email at
          <br />
          <b>email@email.com</b>
          <br />
          We will get back to you as soon as possible
        </p>

        <form onSubmit={handleSubmit(onSubmit)} id={styles.form}>
          <Controller
            name="fullName"
            control={control}
            rules={{ required: "name required" }}
            render={({ field: { onChange, value, onBlur } }) => {
              return (
                <Input
                  errors={errors?.fullName?.message}
                  onChange={onChange}
                  value={value}
                  placeholder="Full Name"
                />
              )
            }}
          />
          <Controller
            name="email"
            control={control}
            rules={{ required: "email required" }}
            render={({ field: { onChange, value, onBlur } }) => {
              return (
                <Input
                  errors={errors?.email?.message}
                  onChange={onChange}
                  value={value}
                  placeholder="Email"
                />
              )
            }}
          />
          <Controller
            name="subject"
            control={control}
            rules={{ required: "subject required" }}
            render={({ field: { onChange, value, onBlur } }) => {
              return (
                <Input
                  errors={errors?.subject?.message}
                  onChange={onChange}
                  value={value}
                  placeholder="Subject"
                />
              )
            }}
          />
          <Controller
            name="message"
            control={control}
            rules={{ required: "message required" }}
            render={({ field: { onChange, value, onBlur } }) => {
              return (
                <textarea
                  className={
                    errors?.message?.message
                      ? styles.textareaError
                      : styles.textarea
                  }
                  onChange={onChange}
                  value={value}
                  placeholder="Message"
                />
              )
            }}
          />
          <Button className={styles.button} type="submit">
            Submit
          </Button>
        </form>
      </div>
    </main>
  )
}
