import axios from "axios"

export const getCategoriesRequest = async (token) => {
  try {
    const res = await axios.get("categories/getCategories")
    return res.data
  } catch (error) {
    throw error
  }
}

export const createCategoryRequest = async (token, payload) => {
  try {
    const res = await axios.post("categories/addCategory")
    return res.data
  } catch (error) {
    throw error
  }
}

export const toggleVisbilityRequest = async (token, id) => {
  try {
    const res = await axios.patch(`categories/toggleVisibility/${id}`)
    return res.data
  } catch (error) {
    throw error
  }
}

export const updateCategoriesRequest = async (token, payload) => {
  try {
    const id = payload.id
    const data = {
      name: payload.name,
      subCategories: payload.subCats,
    }
    const res = await axios.patch(
      `categories/updateCategory/${id}`,
      data
    )
    return res.data
  } catch (error) {
    throw error
  }
}

export const getCategoriesUrlForSeoRequest = async () => {
  try {
    const res = await axios.get("categories/seoUrl")
    return res.data
  } catch (error) {
    throw error
  }
}

export const getMetaInformationRequest = async (category) => {
  try {
    console.log(category)
    const res = await axios.get(`categories/metaInfo/${category}`)
    return res.data
  } catch (error) {
    throw error
  }
}
