export function formatNumber(event, setNumber, fixedNumber) {
  if (fixedNumber) {
    const value = fixedNumber.replace(/,/g, "")
    let formattedValue = ""
    if (value !== "") {
      const parsedValue = parseInt(value, 10)
      if (!isNaN(parsedValue)) {
        formattedValue = parsedValue.toLocaleString()
      }
    }
    setNumber(formattedValue)
  } else {
    event = event + ""
    const value = event.replace(/,/g, "")

    let formattedValue = ""
    if (value !== "") {
      const parsedValue = parseInt(value, 10)
      if (!isNaN(parsedValue)) {
        formattedValue = parsedValue.toLocaleString()
      }
    }

    setNumber(formattedValue)
  }
}
export function formatUrl(name) {
  // Step 1: Replace & sign with "and"
  let cleaned = name.replace(/&/g, "and")

  // Step 2: Remove all special characters except for the word "and" by replacing them with spaces
  cleaned = cleaned.replace(/[^a-zA-Z0-9 ]/g, " ")

  // Step 3: Replace multiple spaces with a single space
  cleaned = cleaned.replace(/\s+/g, " ")

  // Step 4: Trim leading and trailing spaces (if any)
  cleaned = cleaned.trim()

  // Step 5: Replace spaces with hyphens
  cleaned = cleaned.replace(/ /g, "-")

  return cleaned.toLowerCase()
}

export function deFormatUrl(name) {
  // Step 1: Replace hyphens with spaces
  let restored = name.replace(/-/g, " ")

  // Step 2: Change " and " to "&"
  restored = restored
    .replace(/\bAnd\b/g, "&")
    .replace(/\band\b/g, "&")

  // Step 3: Capitalize each word
  restored = restored.replace(/\b\w/g, (char) => char.toUpperCase())

  return restored
}
