import React, { useContext } from "react"
import { PageContext } from "../../../routes/admin/context"
import styles from "./Tab.module.scss"

export default function Tab(props) {
  const { state, dispatch } = useContext(PageContext)

  const handleClick = () => {
    const page = props.children.toLowerCase()
    console.log(page)
    window.localStorage.setItem("page", page)
    dispatch({ type: page })
  }

  const selected = props.children.toLowerCase() === state.page

  return (
    <li
      onClick={handleClick}
      className={
        selected
          ? styles.selected_tab +
            " " +
            (props.isOpen ? styles.openTab : styles.closedTab)
          : styles.tab +
            " " +
            (props.isOpen ? styles.openTab : styles.closedTab)
      }>
      <div className={styles.icon}>{props.icon}</div>
      <span
        className={
          props.isOpen ? styles.tabName : styles.tabNameHidden
        }>
        {props.children}
      </span>
    </li>
  )
}
