import React from 'react'

export default function Menu(props) {
  return (
    <svg
      onClick={props.onClick}
      style={{ cursor: 'pointer' }}
      width={props.size || '18'}
      height={props.size || '18'}
      viewBox="0 0 24 24"
    >
      <g>
        <g fill="#000">
          <path
            d="M3 8a1 1 0 0 1 1-1h16a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1zM3 16a1 1 0 0 1 1-1h16a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1z"
            fill="#000000"
          ></path>
        </g>
      </g>
    </svg>
  )
}
